import axios from 'axios'
import { AppStore } from 'Core/Store/App'
import { UserStore } from 'Core/Store/User'
import { initialize } from '@bonitour/orb-core'
import { identity } from '@bonitour/common-functions'

export const PayService = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  responseType: 'json'
})

PayService.interceptors.request.use(
  config => {
    if (UserStore.authKey && !config.headers.Authorization) {
      config.headers.Authorization = UserStore.authKey
    }

    return config
  },
  error => Promise.reject(error)
)

PayService.interceptors.response.use(identity, error => {
  if (error.response && error.response.status === 401) {
    AppStore.clean()
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }
  return Promise.reject(error)
})

export const extractData = ({ data }) => data

const interceptorFunction = (interceptor) => {
  interceptor.request.use(
    config => {
      if (UserStore.authKey && !config.headers.Authorization) {
        config.headers.Authorization = UserStore.authKey
      }
      return config
    },
    error => Promise.reject(error)
  )
  interceptor.response.use(identity, error => {
    if (error && error.status === 401) {
      AppStore.clean()
      setTimeout(() => {
        window.location.reload()
      }, 500)
    }
    return Promise.reject(error)
  })
}

export const payCore = initialize(
  process.env.REACT_APP_API_HOST,
  interceptorFunction
)
