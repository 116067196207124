import { useState, useEffect, useCallback } from 'react'
import { useToast } from '@bonitour/components'
import { DigitalAccountsService } from 'Core/Service/DigitalAccounts'
import { useCompany } from 'Shared/contexts/Company'

export const useCreateDigitalAccount = () => {
  const PAY_URL = window.location.origin
  const { add: addToast } = useToast()
  const { company: { id } } = useCompany()
  const [linkData, setLinkData] = useState()

  const fetchDigitalAccountCreate = useCallback(() => {
    return DigitalAccountsService.create({
      type: 'SIGNUP',
      returnUrl: `${PAY_URL}/digital-account/create/status`,
      refreshUrl: `${PAY_URL}/digital-account/create/status`,
      referenceId: id
    }).then((data) => {
      setLinkData(data)
    }).catch(() => {
      addToast('Erro ao criar a conta')
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchDigitalAccountCreate()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDigitalAccountCreate])

  return {
    linkData
  }
}
