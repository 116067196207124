/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */

import { useState } from 'react'
import { jsx } from '@emotion/core'
import { Button, Card, flexCenteredSpaceBetweenRow, GhostButton, H1, H3, Row, Column, WarningIcon, Select } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { select, icon, firstRow, secondRow, marginTop40, marginBottom0, marginBottom20 } from './CompanyConfig.style'
import { useCreateCompanyConfig } from './Hooks/useCreateCompanyConfig'
import { useGetCompanyConfig } from './Hooks/useGetCompanyConfig'
import { useCompany } from 'Shared/contexts/Company'
import { useEditCompanyConfig } from './Hooks/useEditCompanyConfig'

export const CompanyConfig = () => {
  const [selectInstallments, setSelectInstallments] = useState()
  const { company } = useCompany()
  const { push } = useHistory()

  const backToDashboard = () => push('/dashboard')

  const {
    onCreateCompanyConfig
  } = useCreateCompanyConfig()

  const {
    currentCompanyConfig
  } = useGetCompanyConfig(company.id)

  const {
    onEditCompanyConfig
  } = useEditCompanyConfig(company.id)

  const {
    maxInstallments
  } = currentCompanyConfig ?? {}

  const installmentsOptions = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' }
  ]

  const onClick = () => currentCompanyConfig ? onEditCompanyConfig({ maxInstallments: selectInstallments }) : onCreateCompanyConfig({ maxInstallments: selectInstallments })

  return (
    <>
      <div css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <H1 css={marginBottom0}>Configurações</H1>
        <GhostButton onClick={backToDashboard}>Voltar</GhostButton>
      </div>
      <Card>
        <Row>
          <Column>
            <H3>
              Limite de parcelas
            </H3>
          </Column>
          <Column>
            <p css={firstRow}>Permitir a venda de atividades online com limite de
              <Select
                value={selectInstallments}
                placeholder={maxInstallments ? `${maxInstallments}` : '-'}
                options={installmentsOptions}
                onChange={setSelectInstallments}
                css={select}
              />
              parcelas.
            </p>
          </Column>
          <Column>
            <div css={secondRow}>
              <WarningIcon css={icon} />
              <span>A configuração do limite de parcelas só será aplicada para as vendas online, das atividades que a sua empresa vende (no seu e-commerce ou via app).</span>
            </div>
          </Column>
        </Row>
      </Card>
      <Row css={marginTop40}>
        <Button onClick={onClick}>
          Salvar
        </Button>
      </Row>
    </>
  )
}
