import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { useToast } from '@bonitour/components'
import { RefundService } from 'Core/Service/Refund'
import { useParamsFilter } from 'Shared/hooks/useParamsFilter'

export const useListRefund = () => {
  const { add: addToast } = useToast()
  const [filters, setFilters] = useParamsFilter()
  const [loading, setLoading] = useState(false)
  const [refundData, setRefundData] = useState({ refunds: undefined, meta: undefined })
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10
  })

  const handleFiltersChange = useCallback((filters) => {
    const filterData = {}
    Object.keys(filters).forEach(filterKey => {
      if (filterKey === 'refundInitialDate' || filterKey === 'refundEndDate' || filterKey === 'paymentInitialDate' || filterKey === 'paymentEndDate') {
        filterData[filterKey] = filters[filterKey] && moment(filters[filterKey]).format('YYYY-MM-DD')
      } else {
        filterData[filterKey] = filters[filterKey]
      }
    })
    setFilters(filterData)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePagination = (currentPage, perPage) => {
    if (currentPage === pagination.currentPage && perPage === pagination.perPage) {
      return
    }

    setPagination({
      currentPage,
      perPage
    })
    // eslint-disable-next-line
  }

  const fetchRefund = useCallback(({
    filters,
    pagination
  }) => {
    setLoading(true)
    return RefundService.list({
      filters,
      pagination
    }).then((data) => {
      setRefundData(data)
    }).catch(() => {
      addToast('Erro inesperado')
    }).finally(() => {
      setLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!filters.refundInitialDate && !filters.refundEndDate && !filters.reservation) return

    fetchRefund({
      pagination,
      filters: { ...filters }
    })
  }, [pagination, filters, fetchRefund])

  return {
    filters,
    setFilters: handleFiltersChange,
    handlePagination,
    refunds: refundData?.refunds,
    meta: refundData?.meta,
    loading
  }
}
