/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import moment from 'moment'
import { jsx, css } from '@emotion/core'
import { TableHeader, H4, TableHeaderRow, TableHeaderCell, TableBody, TableBodyRow, TableBodyCell } from '@bonitour/components'
import { formatDate } from '@bonitour/common-functions'

const marginTop50 = css`
  margin-top: 50px;
`

const width100 = css`
  width: 100%;
`

const titleColumnTransactionHeaderTable = ['Data', 'Hora', 'Ação']

const TransactionHistoryListingTableHeader = ({ columns }) => (
  <TableHeader>
    <TableHeaderRow>
      {columns.map((item) => <TableHeaderCell css={item === 'Ação' && width100} key={item}>{item}</TableHeaderCell>)}
    </TableHeaderRow>
  </TableHeader>
)

const getAction = {
  refund: 'Reembolso',
  pay: 'Pagamento'
}

const TransactionHistoryListing = ({ data }) => {
  return (
    <TableBody>
      {data.map(({ command, createdAt }) => (
        <TableBodyRow key={`${command}+${createdAt}`}>
          <TableBodyCell>{createdAt ? formatDate(createdAt) : '-'}</TableBodyCell>
          <TableBodyCell>{moment.utc(createdAt).format('HH:mm')}</TableBodyCell>
          <TableBodyCell>{getAction[command] || '-'}</TableBodyCell>
        </TableBodyRow>
      ))}
    </TableBody>
  )
}

export const TransactionHistory = ({ history = [] }) => {
  return (
    <>
      <H4 css={marginTop50}>Histórico</H4>
      <TransactionHistoryListingTableHeader columns={titleColumnTransactionHeaderTable} />
      <TransactionHistoryListing data={history} />
    </>
  )
}
