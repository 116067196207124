/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useEffect, useState } from 'react'
import moment from 'moment'
import { jsx } from '@emotion/core'
import { useHistory } from 'react-router'
import { head, identity, tail } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { Button, Input, InputFormGroup, Card, DatePicker, useToast, Row, Column } from '@bonitour/components'
import { useQuery } from 'Shared/hooks/useQuery'
import { RefundFilterSchema } from './RefundFilter.schema'
import { marginTop, stylishCardSearch, button, width100 } from './RefundFilter.styles'

const marginTop25 = marginTop(25)
const marginTop36 = marginTop(36)

export const RefundFilter = ({ loading, onFilterSubmit: emitFilterSubmit = identity }) => {
  const { add: addToast } = useToast()
  const history = useHistory()
  const params = useQuery()

  const {
    refundInitialDate: initialRefundInitialDate,
    refundEndDate: initialRefundEndDate,
    paymentInitialDate: initialPaymentInitialDate,
    paymentEndDate: initialPaymentEndDate,
    reservation: reservationParam,
    aut: autParam
  } = params

  const [initialFilter] = useState({
    refundInitialDate: initialRefundInitialDate && moment(initialRefundInitialDate, 'YYYY-MM-DD'),
    refundEndDate: initialRefundEndDate && moment(initialRefundEndDate, 'YYYY-MM-DD'),
    paymentInitialDate: initialPaymentInitialDate && moment(initialPaymentInitialDate, 'YYYY-MM-DD'),
    paymentEndDate: initialPaymentEndDate && moment(initialPaymentEndDate, 'YYYY-MM-DD'),
    reservation: reservationParam && reservationParam,
    aut: autParam && autParam
  })

  const { form, errors, onSubmit, utils: { onInputChange, onInputBlur } } = useForm(initialFilter, RefundFilterSchema)

  const pushQueryParams = () => {
    const queryParams = Object.entries(form).reduce((acc, item) => {
      const key = head(item)
      const value = tail(item)

      if (!value) {
        return acc
      }

      if (key === 'refundInitialDate' || key === 'refundEndDate' || key === 'paymentInitialDate' || key === 'paymentEndDate') {
        return acc.concat(`${key}=${moment(value).format('YYYY-MM-DD')}&`)
      }

      return acc.concat(`${key}=${tail(item)}&`)
    }, '')

    history.push({ search: `?${queryParams.slice(0, -1)}` })
  }

  const onError = () => addToast('Preencha o formulário corretamente')

  const emitSubmit = () => {
    if (!reservation && (!refundInitialDate || !refundEndDate)) {
      return addToast('Informe uma data inicial e final ou um código de reserva')
    }
    pushQueryParams()
    onSubmit(emitFilterSubmit(form), onError)
  }

  const onClick = onSubmit(emitSubmit, onError)

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      onSubmit(emitFilterSubmit(initialFilter), onError)
    }
    // eslint-disable-next-line
  }, [])

  const { refundInitialDate, refundEndDate, paymentInitialDate, paymentEndDate, reservation, aut } = form

  return (
    <Card css={stylishCardSearch}>
      <Row>
        <Column phone={11} desktop={3}>
          <InputFormGroup label='Estorno em' errorMessage={errors.initialDate}>
            <DatePicker
              id='refundInitialDate'
              value={refundInitialDate}
              disabled={loading}
              placeholder='Data inicial'
              css={width100}
              onChange={onInputChange('refundInitialDate')}
            />
          </InputFormGroup>
        </Column>
        <small css={marginTop36}>até</small>
        <Column phone={12} desktop={3}>
          <InputFormGroup css={marginTop25} label='' errorMessage={errors.initialDate}>
            <DatePicker
              id='refundEndDate'
              value={refundEndDate}
              disabled={loading}
              placeholder='Data final'
              css={width100}
              onChange={onInputChange('refundEndDate')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={2}>
          <InputFormGroup label='Cod. de autorização'>
            <Input
              id='aut'
              value={aut}
              disabled={loading}
              onChange={onInputChange('aut')}
              onBlur={onInputBlur('aut')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Reserva'>
            <Input
              id='reservation'
              value={reservation}
              disabled={loading}
              onChange={onInputChange('reservation')}
              onBlur={onInputBlur('reservation')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={11} desktop={3}>
          <InputFormGroup label='Compra em' errorMessage={errors.initialDate}>
            <DatePicker
              id='paymentInitialDate'
              value={paymentInitialDate}
              disabled={loading}
              placeholder='Data inicial'
              css={width100}
              onChange={onInputChange('paymentInitialDate')}
            />
          </InputFormGroup>
        </Column>
        <small css={marginTop36}>até</small>
        <Column phone={12} desktop={3}>
          <InputFormGroup css={marginTop25} errorMessage={errors.initialDate}>
            <DatePicker
              id='paymentEndDate'
              value={paymentEndDate}
              disabled={loading}
              placeholder='Data final'
              css={width100}
              onChange={onInputChange('paymentEndDate')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={2} />
        <Column phone={12} desktop={3}>
          <Button css={button} onClick={onClick}>
            Pesquisar
          </Button>
        </Column>
      </Row>
    </Card>
  )
}
