/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState } from 'react'
import { Content, Footer, Sidebar } from '@bonitour/components'

import { AuthorizationProvider } from 'Shared/contexts/Authorization'
import { CompanyProvider } from 'Shared/contexts/Company'
import { UserProvider } from 'Shared/contexts/User'

import { Menu } from './Menu'
import { Header } from './Header'

import { Feature } from 'Shared/contexts/Feature'

const LayoutContainer = ({ children }) => {
  const [isVisible, setVisibility] = useState(false)
  const closeSidebar = () => setVisibility(false)
  const openSidebar = () => setVisibility(true)

  return (
    <>
      <Header onOpenSideBar={openSidebar} />
      <Content>
        {children}
      </Content>
      <Footer />
      <Sidebar isOpen={isVisible} onSidebarClose={closeSidebar}>
        <Menu onNavigation={closeSidebar} />
      </Sidebar>
    </>
  )
}

export const Layout = (props) => {
  return (
    <UserProvider>
      <CompanyProvider>
        <AuthorizationProvider>
          <Feature>
            <LayoutContainer {...props} />
          </Feature>
        </AuthorizationProvider>
      </CompanyProvider>
    </UserProvider>
  )
}
