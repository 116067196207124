/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { HelpIcon, Popover, Reference, Manager, colors } from '@bonitour/components'
import { useClickOutside } from '@bonitour/app-functions'
import { useCallback, useRef } from 'react'

const { REACT_APP_HELP_URL, REACT_APP_ENVIRONMENT } = process.env

const helpContainer = css`
  margin-right: 25px;
`

const popover = css`
  display: flex;
  width: auto;
  flex-direction: column;
  text-align: left;
  max-height: 120px;
  justify-content: space-around;
  padding: 10px;
`

const helpIcon = css`
  cursor: pointer;
  font-size: 23px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
`

const helpTitle = css`
  font-size: 14px;
  color: ${colors.gray4};
  margin: 0 0 15px 0;
`

const helpItem = css`
  margin: 5px 0;
  font-size: 16px;
  color: ${colors.gray4};
  text-decoration: none;
  cursor: pointer;

  margin-right: 20px;

  &:hover {
    color: ${colors.gray1};
  }

  >*{
      transform: translateY(-0.7em) scale(0.4) translateX(3em);
      color: transparent !important;
      background-color: ${colors.primary} !important;
      animation: none !important;
    }
`

export const HelpSection = () => {
  const reference = useRef()
  const [isVisible, setVisibility] = useClickOutside(reference)

  const toggleVisibility = useCallback(() => {
    setVisibility(visibility => {
      return !visibility
    })
  }, [setVisibility])

  return (
    <div ref={reference} css={helpContainer}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div css={helpIcon}>
              {REACT_APP_ENVIRONMENT === 'production' &&
                <span css={helpItem} className='beamerTrigger'>Novidades</span>}
              <span ref={ref}>
                <HelpIcon onClick={toggleVisibility} />
              </span>
            </div>
          )}
        </Reference>
        {
          isVisible && (
            <Popover position='bottom'>
              <div css={popover}>
                <div css={helpTitle}>
                  Ajuda
                </div>
                <a target='_blank' href={REACT_APP_HELP_URL} rel='noopener noreferrer' css={helpItem}>
                  Central de ajuda
                </a>
              </div>
            </Popover>
          )
        }
      </Manager>
    </div>
  )
}
