import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const containerSelectKey = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 14px;
  cursor: pointer;
  width: fit-content;
`

export const labelKey = css`
  font-size: 15px;
  color: ${colors.gray2};
  font-weight: 500;
`

export const arrowIcon = css`
  font-size: 11px;
  color: ${colors.gray3};
  margin-left: 6px;
`

export const containerDialog = css`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
`

export const marginTop = css`
  margin-top: 13px;
`

export const marginRight = css`
  margin: 25px 20px 0 0;
`

export const inputStyle = css`
  margin-top: 3px;
  width: 90%;
`
