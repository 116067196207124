import { BillingCore } from 'Core/Service/Billing'
import { DigitalAccountMovementsParser } from '../Parser/DigitalAccountMovements'

const bankAccountMovementsResource = '/v1/bank_account_movements'

export const BankAccountMovementsApi = {
  list: ({ filters, pagination }) => {
    return BillingCore.get(`${bankAccountMovementsResource}`, { params: { ...filters, page: pagination.currentPage, per_page: pagination.perPage } }).then(DigitalAccountMovementsParser.list)
  }
}
