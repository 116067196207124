import { date, ref, string } from 'yup'

export const RefundFilterSchema = {
  refundInitialDate: date()
    .max(ref('refundEndDate'), 'A data inicial deve ser menor ou igual a data final'),
  refundEndDate: date()
    .min(ref('refundInitialDate'), 'A data final deve ser maior ou igual a data inicial'),
  paymentInitialDate: date()
    .max(ref('paymentEndDate'), 'A data inicial deve ser menor ou igual a data final'),
  paymentEndDate: date()
    .min(ref('paymentInitialDate'), 'A data final deve ser maior ou igual a data inicial'),
  aut: string(),
  reservation: string()
}
