/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { unformatMoney } from '@bonitour/common-functions'
import { Button, flexColumn, InputMoneyMask } from '@bonitour/components'
import { css, jsx } from '@emotion/core'

const inputStyle = css`
  margin: 1em 0 2em 0;
  width: 92%;
`

export const PixValue = ({ nextStep, value, setValue }) => {
  const isNextButtonDisabled = unformatMoney(value) <= 0

  return (
    <div css={flexColumn}>
      <InputMoneyMask value={value} onChange={setValue} css={inputStyle} />
      <div>
        <Button onClick={nextStep} disabled={isNextButtonDisabled}>Avançar</Button>
      </div>
    </div>
  )
}
