import React, { createContext, useContext, useState, useMemo, useCallback } from 'react'
import { UserStore } from 'Core/Store/User'

export const UserContext = createContext({})

export const useUser = () => useContext(UserContext)

export const UserProvider = (props) => {
  const [user] = useState(() => UserStore.user)
  const [authKey, setAuthKey] = useState(() => UserStore.authKey)
  const hasAffiliateProfile = useMemo(() => Boolean(user?.affiliates_info?.length), [user])

  const updateAuthKey = useCallback((newAuthKey) => {
    UserStore.authKey = newAuthKey
    setAuthKey(newAuthKey)
  }, [])

  const providerData = useMemo(() => ({
    user,
    authKey,
    updateAuthKey,
    hasAffiliateProfile
  }), [user, authKey, updateAuthKey, hasAffiliateProfile])

  return (
    <UserContext.Provider value={providerData} {...props} />
  )
}
