import React, { createContext, useContext, useState, useMemo, useEffect } from 'react'
import { CompanyStore } from 'Core/Store/Company'
import { CompanyService } from 'Core/Service/Company'

export const CompanyContext = createContext({})

export const useCompany = () => useContext(CompanyContext)

export const CompanyProvider = (props) => {
  const [company] = useState(() => CompanyStore.company || {})
  const [userCompanies, setUserCompanies] = useState([])

  useEffect(() => {
    if (company.id) {
      CompanyService.listUserCompanies(true)
        .then(setUserCompanies)
    }
  }, [company])

  const providerData = useMemo(() => ({
    get id () {
      return company.id
    },
    company,
    userCompanies
  }), [company, userCompanies])

  return (
    <CompanyContext.Provider value={providerData} {...props} />
  )
}
