import { payCore } from './Pay'
import { RefundModel } from 'Core/Model/Refund'
import { RefundParser } from 'Core/Parser/Refund'

const { refundDomain } = payCore

export const RefundService = {
  list ({ filters, pagination }) {
    return refundDomain.list(RefundModel.list({ filters, pagination })).then(RefundParser.list)
  }
}
