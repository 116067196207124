/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCompany } from 'Shared/contexts/Company'
import { TitleMenus } from './TitleMenus/TitleMenus'

export const Dashboard = () => {
  const { id } = useCompany()

  return <TitleMenus companyId={id} />
}
