// This object was assembled from the tables in this link https://developercielo.github.io/tutorial/abecs-e-outros-codigos

export const paymentReturnCode = {
  elo: {
    4: {
      msg: 'Refazer a transação (emissor solicita retentiva) | consultar credenciador',
      type: 'reversível',
      msg_to_client: 'Refazer a transação | lojista, contate o adquirente'
    },
    6: {
      msg: 'Consultar credenciador',
      type: 'reversível',
      msg_to_client: 'Lojista, contate o adquirente'
    },
    5: {
      msg: 'Genérica',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    12: {
      msg: 'Erro no cartão',
      type: 'irreversível',
      msg_to_client: 'Verifique os dados do cartão'
    },
    13: {
      msg: 'Valor da transação inválida',
      type: 'irreversível',
      msg_to_client: 'Valor da (transação) não permitido — não tente novamente'
    },
    14: {
      msg: 'Número cartão não pertence ao emissor | número do cartão inválido',
      type: 'irreversível',
      msg_to_client: 'Verifique os dados do cartão'
    },
    19: {
      msg: 'Problema no adquirente',
      type: 'irreversível',
      msg_to_client: 'Erro no cartão — não tente novamente'
    },
    23: {
      msg: 'Valor da parcela inválida',
      type: 'irreversível',
      msg_to_client: 'Parcelamento inválido — não tente novamente'
    },
    30: {
      msg: 'Erro de formato (mensageira)',
      type: 'irreversível',
      msg_to_client: 'Erro no cartão — não tente novamente'
    },
    38: {
      msg: 'Excedidas tentativas de senha | compras',
      type: 'reversível',
      msg_to_client: 'Excedidas tentativas de senha — contate a central do seu cartão'
    },
    41: {
      msg: 'Cartão perdido',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida — não tente novamente'
    },
    43: {
      msg: 'Cartão roubado',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida — não tente novamente'
    },
    51: {
      msg: 'Saldo/limite insuficiente',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    54: {
      msg: 'Cartão vencido / data expiração inválida',
      type: 'irreversível',
      msg_to_client: 'Verifique os dados do cartão'
    },
    55: {
      msg: 'Senha inválida',
      type: 'reversível',
      msg_to_client: 'Senha inválida'
    },
    56: {
      msg: 'Número cartão não pertence ao emissor | número do cartão inválido',
      type: 'irreversível',
      msg_to_client: 'Verifique os dados do cartão'
    },
    57: {
      msg: 'Transação não permitida para o cartão | transação não permitida / capacidade do terminal | fraude confirmada | transação negada por infração de lei',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida para o cartão — não tente novamente'
    },
    58: {
      msg: 'Comerciante inválido',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida — não tente novamente'
    },
    59: {
      msg: 'Suspeita de fraude',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    61: {
      msg: 'Valor excesso / saque',
      type: 'reversível',
      msg_to_client: 'Valor excedido — contate a central do seu cartão'
    },
    62: {
      msg: 'Cartão domestico — transação internacional',
      type: 'irreversível',
      msg_to_client: 'Cartão não permite transação internacional'
    },
    63: {
      msg: 'Violação de segurança',
      type: 'irreversível',
      msg_to_client: 'Verifique os dados do cartão'
    },
    64: {
      msg: 'Valor mínimo da transação inválido',
      type: 'irreversível',
      msg_to_client: 'Valor da (transação) não permitido — não tente novamente'
    },
    65: {
      msg: 'Quantidade de saques excedido',
      type: 'reversível',
      msg_to_client: 'Quantidade de saques excedida — contate a central do seu cartão'
    },
    74: {
      msg: 'Senha vencida / erro de criptografia de senha',
      type: 'irreversível',
      msg_to_client: 'Senha inválida - não tente novamente'
    },
    75: {
      msg: 'Excedidas tentativas de senha | saque',
      type: 'reversível',
      msg_to_client: 'Excedidas tentativas de senha — contate a central do seu cartão'
    },
    78: {
      msg: 'Cartão novo sem desbloqueio',
      type: 'reversível',
      msg_to_client: 'Desbloqueie o cartão'
    },
    82: {
      msg: 'Cartão inválido (criptograma)',
      type: 'irreversível',
      msg_to_client: 'Erro no cartão — não tente novamente'
    },
    91: {
      msg: 'Emissor fora do ar',
      type: 'reversível',
      msg_to_client: 'Falha de comunicação — tente mais tarde'
    },
    96: {
      msg: 'Emissor fora do ar',
      type: 'reversível',
      msg_to_client: 'Falha de comunicação — tente mais tarde'
    },
    99: {
      msg: 'Diferença pré-autorização',
      type: 'irreversível',
      msg_to_client: 'Valor diferente da pre autorização — não tente novamente'
    },
    AB: {
      msg: 'Função incorreta (débito)',
      type: 'irreversível',
      msg_to_client: 'Utilize função crédito'
    },
    AC: {
      msg: 'Função incorreta (crédito)',
      type: 'irreversível',
      msg_to_client: 'Utilize função débito'
    },
    P5: {
      msg: 'Troca de senha / desbloqueio',
      type: 'irreversível',
      msg_to_client: 'Senha inválida - não tente novamente'
    },
    P6: {
      msg: 'Troca de senha / desbloqueio',
      type: 'reversível',
      msg_to_client: 'Senha inválida, utiliza a nova senha'
    }
  },
  visa: {
    3: {
      msg: 'Comerciante inválido',
      type: 'reversível',
      msg_to_client: 'Transação não permitida'
    },
    4: {
      msg: 'Recolher cartão (não há fraude)',
      type: 'irreversível',
      msg_to_client: 'Contate a central do seu cartão - não tente novamente'
    },
    5: {
      msg: 'Genérica',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    6: {
      msg: 'Número do cartão não pertence ao emissor | número do cartão inválido | violação de segurança | Erro no cartão | Cartão vencido / data expiração inválida | Erro por mudança de chave dinâmica',
      type: 'irreversível',
      msg_to_client: 'Verifique os dados do cartão'
    },
    7: {
      msg: 'Fraude confirmada',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida para o cartão — não tente novamente'
    },
    12: {
      msg: 'Erro de formato (mensageira)',
      type: 'irreversível',
      msg_to_client: 'Erro no cartão — não tente novamente'
    },
    13: {
      msg: 'Valor da transação inválida',
      type: 'irreversível',
      msg_to_client: 'Valor da (transação) não permitido — não tente novamente'
    },
    15: {
      msg: 'Emissor não localizado — bin incorreto',
      type: 'irreversível',
      msg_to_client: 'Dados do cartão inválido — não tente novamente'
    },
    19: {
      msg: 'Problema no adquirente',
      type: 'irreversível',
      msg_to_client: 'Erro no cartão — não tente novamente'
    },
    39: {
      msg: 'Função incorreta (crédito)',
      type: 'irreversível',
      msg_to_client: 'Utilize função débito'
    },
    41: {
      msg: 'Cartão perdido',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida — não tente novamente'
    },
    43: {
      msg: 'Cartão roubado',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida — não tente novamente'
    },
    51: {
      msg: 'Saldo/limite insuficiente',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    52: {
      msg: 'Função incorreta (débito)',
      type: 'irreversível',
      msg_to_client: 'Utilize função crédito'
    },
    53: {
      msg: 'Função incorreta (débito)',
      type: 'irreversível',
      msg_to_client: 'Utilize função crédito'
    },
    55: {
      msg: 'Senha inválida',
      type: 'reversível',
      msg_to_client: 'Senha inválida'
    },
    57: {
      msg: 'Transação não permitida para o cartão',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida para o cartão — não tente novamente'
    },
    58: {
      msg: 'Transação não permitida | capacidade do terminal',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida para o cartão — não tente novamente'
    },
    59: {
      msg: 'Suspeita de fraude',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    61: {
      msg: 'Valo excesso | saque',
      type: 'reversível',
      msg_to_client: 'Valor excedido — contate a central do seu cartão'
    },
    62: {
      msg: 'Cartão domestico — transação internacional',
      type: 'reversível',
      msg_to_client: 'Cartão não permite transação internacional'
    },
    64: {
      msg: '(Negativa do adquirente) não cumprimento pelas leis de anti lavagem de dinheiro',
      type: 'irreversível',
      msg_to_client: 'Contate a central do seu cartão - não tente novamente'
    },
    65: {
      msg: 'Quantidade de saques excedido',
      type: 'reversível',
      msg_to_client: 'Quantidade de saques excedida — contate a central do seu cartão'
    },
    74: {
      msg: 'Senha vencida / erro de criptografia de senha',
      type: 'irreversível',
      msg_to_client: 'Senha inválida - não tente novamente'
    },
    75: {
      msg: 'Excedidas tentativas de senha / compras | excedidas tentativas de senha / saque',
      type: 'reversível',
      msg_to_client: 'Excedidas tentativas de senha — contate a central do seu cartão'
    },
    76: {
      msg: 'Conta destino inválida ou inexistente | Reversão inválida',
      type: 'irreversível',
      msg_to_client: 'Conta destino inváldia| Contate a central do seu cartão - não tente novamente'
    },
    78: {
      msg: 'Cartão novo sem desbloqueio',
      type: 'reversível',
      msg_to_client: 'Desbloqueie o cartão'
    },
    81: {
      msg: 'Senha vencida / erro de criptografia de senha',
      type: 'irreversível',
      msg_to_client: 'Senha inválida - não tente novamente'
    },
    82: {
      msg: 'Cartão inválido (criptograma)',
      type: 'irreversível',
      msg_to_client: 'Erro no cartão — não tente novamente'
    },
    86: {
      msg: 'Senha inválida',
      type: 'reversível',
      msg_to_client: 'Senha inválida'
    },
    91: {
      msg: 'Emissor fora do ar',
      type: 'reversível',
      msg_to_client: 'Falha de comunicação — tente mais tarde'
    },
    92: {
      msg: 'Não localizado pelo roteador',
      type: 'irreversível',
      msg_to_client: 'Contate a central do seu cartão - não tente novamente'
    },
    93: {
      msg: 'Transação negada por infração de lei',
      type: 'reversível',
      msg_to_client: 'Transação não permitida para o cartão'
    },
    94: {
      msg: 'Valor do tracing data duplicado',
      type: 'Ireversível',
      msg_to_client: 'Contate a central do seu cartão - não tente novamente'
    },
    96: {
      msg: 'Emissor fora do ar',
      type: 'reversível',
      msg_to_client: 'Falha de comunicação — tente mais tarde'
    },
    B1: {
      msg: 'Surcharge não suportado',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    B2: {
      msg: 'Surcharge não suportado pela rede de débito',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    N0: {
      msg: 'Forçar stip',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    N3: {
      msg: 'Saque não disponível',
      type: 'irreversível',
      msg_to_client: 'Saque não disponível - não tente novamente'
    },
    N4: {
      msg: 'Valo excesso | saque',
      type: 'reversível',
      msg_to_client: 'Valor excedido — contate a central do seu cartão'
    },
    N8: {
      msg: 'Diferença pré-autorização',
      type: 'irreversível',
      msg_to_client: 'Valor diferente da pre autorização — não tente novamente'
    },
    R0: {
      msg: 'Suspensão de pagamento recorrente para um serviço',
      type: 'irreversível',
      msg_to_client: 'Suspensão de pagamento recorrente para um serviço - não tente novamente'
    },
    R1: {
      msg: 'Suspensão de pagamento recorrente para todos os serviços',
      type: 'irreversível',
      msg_to_client: 'Suspensão de pagamento recorrente para um serviço - não tente novamente'
    },
    R2: {
      msg: 'Transação não qualificada para a visa pin',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida para o cartão — não tente novamente'
    },
    R3: {
      msg: 'Suspensão de todas as ordens de autorização',
      type: 'irreversível',
      msg_to_client: 'Suspensão de pagamento recorrente para um serviço - não tente novamente'
    }
  },
  master: {
    1: {
      msg: 'Número cartão não pertence ao emissor | número do cartão inválido',
      type: 'irreversível',
      msg_to_client: 'Verifique os dados do cartão'
    },
    3: {
      msg: 'Comerciante inválido',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida — não tente novamente'
    },
    4: {
      msg: 'Fraude confirmada',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida para o cartão — não tente novamente'
    },
    5: {
      msg: 'Genérica',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    12: {
      msg: 'Valor da parcela inválida',
      type: 'irreversível',
      msg_to_client: 'Parcelamento inválido — não tente novamente'
    },
    13: {
      msg: 'Valor da transação inválida',
      type: 'irreversível',
      msg_to_client: 'Valor da (transação) não permitido — não tente novamente'
    },
    14: {
      msg: 'Número do cartão não pertence ao emissor | número do cartão inválido | violação de segurança',
      type: 'irreversível',
      msg_to_client: 'Verifique os dados do cartão'
    },
    15: {
      msg: 'Emissor não localizado — bin incorreto',
      type: 'irreversível',
      msg_to_client: 'Dados do cartão inválido — não tente novamente'
    },
    25: {
      msg: 'Não é possivel localizar o registro no arquivo',
      type: 'irreversível',
      msg_to_client: 'Contate a central do seu cartão - não tente novamente'
    },
    28: {
      msg: 'Arquivo não disponível para atualização',
      type: 'irreversível',
      msg_to_client: 'Contate a central do seu cartão - não tente novamente'
    },
    30: {
      msg: 'Problema no adquirente | Erro de formato (mensageira)',
      type: 'irreversível',
      msg_to_client: 'Erro no cartão — não tente novamente'
    },
    41: {
      msg: 'Cartão perdido',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida — não tente novamente'
    },
    43: {
      msg: 'Catão roubado',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida — não tente novamente'
    },
    51: {
      msg: 'Saldo/limite insuficiente',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    54: {
      msg: 'Cartão vencido / data expiração inválida',
      type: 'irreversível',
      msg_to_client: 'Verifique os dados do cartão'
    },
    55: {
      msg: 'Senha inválida',
      type: 'reversível',
      msg_to_client: 'Senha inválida'
    },
    57: {
      msg: 'Transação não permitida para o cartão | Transação negada por infração de lei',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida para o cartão — não tente novamente'
    },
    58: {
      msg: 'Transação não permitida | capacidade do terminal',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida para o cartão — não tente novamente'
    },
    61: {
      msg: 'Valo excesso | saque',
      type: 'reversível',
      msg_to_client: 'Valor excedido — contate a central do seu cartão'
    },
    62: {
      msg: 'Cartão domestico — transação internacional',
      type: 'irreversível',
      msg_to_client: 'Cartão não permite transação internacional'
    },
    63: {
      msg: 'Suspeita de fraude',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    65: {
      msg: 'Quantidade de saques excedido',
      type: 'reversível',
      msg_to_client: 'Quantidade de saques excedida — contate a central do seu cartão'
    },
    75: {
      msg: 'Excedidas tentativas de senha / compras | excedidas tentativas de senha / saque',
      type: 'reversível',
      msg_to_client: 'Excedidas tentativas de senha — contate a central do seu cartão'
    },
    77: {
      msg: 'Conta destino inválida ou inexistente',
      type: 'irreversível',
      msg_to_client: 'Conta destino inválida — não tente novamente'
    },
    88: {
      msg: 'Senha vencida / erro de criptografia de senha | cartão inválido (criptograma)',
      type: 'irreversível',
      msg_to_client: 'Senha inválida | erro no cartão — não tente novamente'
    },
    91: {
      msg: 'Emissor fora do ar',
      type: 'reversível',
      msg_to_client: 'Falha de comunicação — tente mais tarde'
    },
    92: {
      msg: 'Não localizado pelo roteador',
      type: 'irreversível',
      msg_to_client: 'Contate a central do seu cartão - não tente novamente'
    },
    94: {
      msg: 'Valor do tracing data duplicado',
      type: 'Ireversível',
      msg_to_client: 'Contate a central do seu cartão - não tente novamente'
    },
    96: {
      msg: 'Emissor fora do ar',
      type: 'reversível',
      msg_to_client: 'Falha de comunicação — tente mais tarde'
    }
  },
  amex: {
    8: {
      msg: 'Número do cartão não pertence ao emissor | número do cartão inválido | violação de segurança',
      type: 'irreversível',
      msg_to_client: 'Verifique os dados do cartão'
    },
    100: {
      msg: 'Genérica | Suspeita de fraude',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    101: {
      msg: 'Cartão vencido / data expiração inválida',
      type: 'irreversível',
      msg_to_client: 'Verifique os dados do cartão'
    },
    106: {
      essage: 'Excedidas tentativas de senha / compras | excedidas tentativas de senha / saque',
      type: 'reversível',
      msg_to_client: 'Excedidas tentativas de senha — contate a central do seu cartão'
    },
    109: {
      msg: 'Comerciante inválido',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida — não tente novamente'
    },
    110: {
      msg: 'Valor da transação inválida',
      type: 'irreversível',
      msg_to_client: 'Valor da (transação) não permitido — não tente novamente'
    },
    115: {
      msg: 'Erro no cartão | Valor da parcela inválida',
      type: 'irreversível',
      msg_to_client: 'Verifique os dados do cartão | Parcelamento inválido — não tente novamente'
    },
    116: {
      msg: 'Saldo/limite insuficiente | Transação não permitida / capacidade do terminal',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão | Transação não permitida — não tente novamente'
    },
    117: {
      msg: 'Senha inválida',
      type: 'reversível',
      msg_to_client: 'Senha inválida'
    },
    121: {
      msg: 'Saldo/limite insuficiente',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    122: {
      msg: 'Número do cartão não pertence ao emissor | número do cartão inválido | violação de segurança',
      type: 'irreversível',
      msg_to_client: 'Verifique os dados do cartão'
    },
    180: {
      msg: 'Senha vencida / erro de criptografia de senha | cartão inválido (criptograma)',
      type: 'irreversível',
      msg_to_client: 'Senha inválida | erro no cartão — não tente novamente'
    },
    181: {
      msg: 'Erro de formato (mensageira)',
      type: 'irreversível',
      msg_to_client: 'Erro no cartão — não tente novamente'
    },
    200: {
      msg: 'Transação não permitida para o cartão | Cartão perdido | Cartão roubado | Fraude confirmada',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida para o cartão — não tente novamente'
    },
    911: {
      msg: 'Emissor fora do ar',
      type: 'reversível',
      msg_to_client: 'Falha de comunicação — tente mais tarde'
    },
    912: {
      msg: 'Emissor fora do ar',
      type: 'reversível',
      msg_to_client: 'Falha de comunicação — tente mais tarde'
    },
    A1: {
      msg: 'Emissor fora do ar',
      type: 'reversível',
      msg_to_client: 'Falha de comunicação — tente mais tarde'
    },
    A2: {
      msg: 'Erro no cartão | Valor da parcela inválida',
      type: 'irreversível',
      msg_to_client: 'Verifique os dados do cartão | Parcelamento inválido — não tente novamente'
    },
    A3: {
      msg: 'Erro de formato (mensageira)',
      type: 'irreversível',
      msg_to_client: 'Erro no cartão — não tente novamente'
    },
    A4: {
      msg: 'Excedidas tentativas de senha / compras | excedidas tentativas de senha / saque',
      type: 'reversível',
      msg_to_client: 'Excedidas tentativas de senha — contate a central do seu cartão'
    },
    A5: {
      msg: 'Saldo/limite insuficiente | Transação não permitida / capacidade do terminal',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    A6: {
      msg: 'Senha inválida',
      type: 'reversível',
      msg_to_client: 'Senha inválida'
    },
    A7: {
      msg: 'Senha vencida / erro de criptografia de senha | cartão inválido (criptograma)',
      type: 'irreversível',
      msg_to_client: 'Senha inválida | erro no cartão — não tente novamente'
    },
    AE: {
      msg: 'Emissor fora do ar',
      type: 'reversível',
      msg_to_client: 'Falha de comunicação — tente mais tarde'
    },
    BV: {
      msg: 'Cartão vencido / data expiração inválida',
      type: 'irreversível',
      msg_to_client: 'Verifique os dados do cartão'
    },
    DA: {
      msg: 'Comerciante inválido',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida — não tente novamente'
    },
    FA: {
      msg: 'Genérica | Suspeita de fraude',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    FD: {
      msg: 'Transação não permitida para o cartão | Cartão roubado',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida para o cartão — não tente novamente'
    },
    JB: {
      msg: 'Valor da transação inválida',
      type: 'irreversível',
      msg_to_client: 'Valor da (transação) não permitido — não tente novamente'
    },
    SE: {
      msg: 'Refazer a transação (emissor solicita retentativa) | Consultar credenciador',
      type: 'reversível',
      msg_to_client: 'Refazer a transação | lojista, contate o adquirente'
    }
  },
  hiper: {
    1: {
      msg: 'Número cartão não pertence ao emissor | número do cartão inválido',
      type: 'irreversível',
      msg_to_client: 'Verifique os dados do cartão'
    },
    3: {
      msg: 'Comerciante inválido',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida — não tente novamente'
    },
    4: {
      msg: 'Fraude confirmada',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida para o cartão — não tente novamente'
    },
    5: {
      msg: 'Genérica',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    12: {
      msg: 'Valor da parcela inválida',
      type: 'irreversível',
      msg_to_client: 'Parcelamento inválido — não tente novamente'
    },
    13: {
      msg: 'Valor da transação inválida',
      type: 'irreversível',
      msg_to_client: 'Valor da (transação) não permitido — não tente novamente'
    },
    14: {
      msg: 'Número do cartão não pertence ao emissor | número do cartão inválido | violação de segurança',
      type: 'irreversível',
      msg_to_client: 'Verifique os dados do cartão'
    },
    15: {
      msg: 'Emissor não localizado — bin incorreto',
      type: 'irreversível',
      msg_to_client: 'Dados do cartão inválido — não tente novamente'
    },
    25: {
      msg: 'Não é possivel localizar o registro no arquivo',
      type: 'irreversível',
      msg_to_client: 'Contate a central do seu cartão - não tente novamente'
    },
    28: {
      msg: 'Arquivo não disponível para atualização',
      type: 'irreversível',
      msg_to_client: 'Contate a central do seu cartão - não tente novamente'
    },
    30: {
      msg: 'Problema no adquirente | Erro de formato (mensageira)',
      type: 'irreversível',
      msg_to_client: 'Erro no cartão — não tente novamente'
    },
    41: {
      msg: 'Cartão perdido',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida — não tente novamente'
    },
    43: {
      msg: 'Cartão roubado',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida — não tente novamente'
    },
    51: {
      msg: 'Saldo/limite insuficiente',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    54: {
      msg: 'Cartão vencido / data expiração inválida',
      type: 'irreversível',
      msg_to_client: 'Verifique os dados do cartão'
    },
    55: {
      msg: 'Senha inválida',
      type: 'reversível',
      msg_to_client: 'Senha inválida'
    },
    57: {
      msg: 'Transação não permitida para o cartão | Transação negada por infração de lei',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida para o cartão — não tente novamente'
    },
    58: {
      msg: 'Transação não permitida | capacidade do terminal',
      type: 'irreversível',
      msg_to_client: 'Transação não permitida para o cartão — não tente novamente'
    },
    61: {
      msg: 'Valo excesso | saque',
      type: 'reversível',
      msg_to_client: 'Valor excedido — contate a central do seu cartão'
    },
    62: {
      msg: 'Cartão domestico — transação internacional',
      type: 'irreversível',
      msg_to_client: 'Cartão não permite transação internacional'
    },
    63: {
      msg: 'Suspeita de fraude',
      type: 'reversível',
      msg_to_client: 'Contate a central do seu cartão'
    },
    65: {
      msg: 'Quantidade de saques excedido',
      type: 'reversível',
      msg_to_client: 'Quantidade de saques excedida — contate a central do seu cartão'
    },
    75: {
      msg: 'Excedidas tentativas de senha / compras | excedidas tentativas de senha / saque',
      type: 'reversível',
      msg_to_client: 'Excedidas tentativas de senha — contate a central do seu cartão'
    },
    77: {
      msg: 'Conta destino inválida ou inexistente',
      type: 'irreversível',
      msg_to_client: 'Conta destino inválida — não tente novamente'
    },
    88: {
      msg: 'Senha vencida / erro de criptografia de senha | cartão inválido (criptograma)',
      type: 'irreversível',
      msg_to_client: 'Senha inválida | erro no cartão — não tente novamente'
    },
    91: {
      msg: 'Emissor fora do ar',
      type: 'reversível',
      msg_to_client: 'Falha de comunicação — tente mais tarde'
    },
    92: {
      msg: 'Não localizado pelo roteador',
      type: 'irreversível',
      msg_to_client: 'Contate a central do seu cartão - não tente novamente'
    },
    94: {
      msg: 'Valor do tracing data duplicado',
      type: 'Ireversível',
      msg_to_client: 'Contate a central do seu cartão - não tente novamente'
    },
    96: {
      msg: 'Emissor fora do ar',
      type: 'reversível',
      msg_to_client: 'Falha de comunicação — tente mais tarde'
    }
  }
}

export const paymentReturnCodeOthersCodes = {
  0: {
    msg: 'Transação autorizada com sucesso.',
    msg_to_client: 'Transação autorizada com sucesso.'
  },
  2: {
    msg: 'Transação não autorizada. Referida (suspeita de fraude) pelo banco emissor.',
    msg_to_client: 'Transação não autorizada. Entre em contato com seu banco emissor.'
  },
  9: {
    msg: 'Transação cancelada parcialmente com sucesso',
    msg_to_client: 'Transação cancelada parcialmente com sucesso'
  },
  11: {
    msg: 'Transação autorizada com sucesso',
    msg_to_client: 'Transação autorizada com sucesso'
  },
  21: {
    msg: 'Não foi possível processar o cancelamento. Se o erro persistir, entre em contato com a Cielo',
    msg_to_client: 'Não foi possível processar o cancelamento. Tente novamente mais tarde. Persistindo o erro, entrar em contato com a loja virtual'
  },
  22: {
    msg: 'Não foi possível processar a transação. Número de parcelas inválidas. Se o erro persistir, entre em contato com a Cielo',
    msg_to_client: 'Não foi possível processar a transação. Valor inválido. Refazer a transação confirmando os dados informados. Persistindo o erro, entrar em contato com a loja virtual'
  },
  24: {
    msg: 'Não foi possível processar a transação. Quantidade de parcelas inválido. Se o erro persistir, entre em contato com a Cielo',
    msg_to_client: 'Não foi possível processar a transação. Quantidade de parcelas inválido. Refazer a transação confirmando os dados informados. Persistindo o erro, entrar em contato com a loja virtual'
  },
  60: {
    msg: 'Transação não autorizada. Tente novamente. Se o erro persistir o portador deve entrar em contato com o banco emissor',
    msg_to_client: 'Não foi possível processar a transação. Tente novamente mais tarde. Se o erro persistir, entre em contato com seu banco emissor'
  },
  67: {
    msg: 'Transação não autorizada. Cartão bloqueado para compras hoje. Bloqueio pode ter ocorrido por excesso de tentativas inválidas. O cartão será desbloqueado automaticamente à meia noite',
    msg_to_client: 'Transação não autorizada. Cartão bloqueado temporariamente. Entre em contato com seu banco emissor.'
  },
  70: {
    msg: 'Transação não autorizada. Limite excedido/sem saldo',
    msg_to_client: 'Transação não autorizada. Entre em contato com seu banco emissor'
  },
  72: {
    msg: 'Cancelamento não efetuado. Saldo disponível para cancelamento insuficiente. Se o erro persistir, entre em contato com a Cielo',
    msg_to_client: 'Cancelamento não efetuado. Tente novamente mais tarde. Se o erro persistir, entre em contato com a loja virtual'
  },
  79: {
    msg: 'Transação não autorizada. Não é possível processar a transação devido a erro relacionado ao cartão do portador. Solicite ao portador que entre em contato com o banco emissor',
    msg_to_client: 'Entre em contato com o seu banco'
  },
  80: {
    msg: 'Transação não autorizada. Data da transação ou data do primeiro pagamento inválida',
    msg_to_client: 'Transação não autorizada. Refazer a transação confirmando os dados'
  },
  82: {
    msg: 'Transação não autorizada devido a regras do emissor. Oriente o portador a entrar em contato com o banco emissor',
    msg_to_client: 'Entre em contato com o seu banco'
  },
  83: {
    msg: 'Transação não autorizada. Suspeita de fraude pelo banco emissor',
    msg_to_client: 'Entre em contato com o seu banco'
  },
  85: {
    msg: 'Transação não permitida. Houve um erro no processamento.Solicite ao portador que digite novamente os dados do cartão, se o erro persistir pode haver um problema no terminal do lojista, nesse caso o lojista deve entrar em contato com a Cielo',
    msg_to_client: 'Transação não permitida. Informe os dados do cartão novamente. Se o erro persistir, entre em contato com a loja virtual'
  },
  89: {
    msg: 'Transação não autorizada. Erro na transação. O portador deve tentar novamente e se o erro persistir, entrar em contato com o banco emissor',
    msg_to_client: 'Transação não autorizada. Erro na transação. Tente novamente e se o erro persistir, entre em contato com seu banco emissor'
  },
  90: {
    msg: 'Transação não permitida. Houve um erro no processamento.Solicite ao portador que digite novamente os dados do cartão, se o erro persistir pode haver um problema no terminal do lojista, nesse caso o lojista deve entrar em contato com a Cielo',
    msg_to_client: 'Transação não permitida. Informe os dados do cartão novamente. Se o erro persistir, entre em contato com a loja virtual'
  },
  97: {
    msg: 'Transação não autorizada. Valor não permitido para essa transação',
    msg_to_client: 'Transação não autorizada. Valor não permitido para essa transação'
  },
  98: {
    msg: 'Transação não autorizada. Sistema do emissor sem comunicação. Se for geral, verificar SITEF, GATEWAY e/ou Conectividade',
    msg_to_client: 'Sua Transação não pode ser processada, Tente novamente mais tarde. Se o erro persistir, entre em contato com a loja virtual'
  },
  475: {
    msg: 'A aplicação não respondeu dentro do tempo esperado',
    msg_to_client: 'Realizar uma nova tentativa após alguns segundos. Persistindo, entrar em contato com o Suporte'
  },
  999: {
    msg: 'Transação não autorizada. Sistema do emissor sem comunicação. Tente mais tarde. Pode ser erro no SITEF, favor verificar',
    msg_to_client: 'Sua Transação não pode ser processada, Tente novamente mais tarde. Se o erro persistir, entre em contato com a loja virtual'
  },
  AA: {
    msg: 'Tempo excedido na comunicação com o banco emissor. Oriente o portador a tentar novamente, se o erro persistir será necessário que o portador contate seu banco emissor',
    msg_to_client: 'Tempo excedido na sua comunicação com o banco emissor, tente novamente mais tarde. Se o erro persistir, entre em contato com seu banco'
  },
  AF: {
    msg: 'Transação não permitida. Houve um erro no processamento.Solicite ao portador que digite novamente os dados do cartão, se o erro persistir pode haver um problema no terminal do lojista, nesse caso o lojista deve entrar em contato com a Cielo',
    msg_to_client: 'Transação não permitida. Informe os dados do cartão novamente. Se o erro persistir, entre em contato com a loja virtual'
  },
  AG: {
    msg: 'Transação não permitida. Houve um erro no processamento.Solicite ao portador que digite novamente os dados do cartão, se o erro persistir pode haver um problema no terminal do lojista, nesse caso o lojista deve entrar em contato com a Cielo',
    msg_to_client: 'Transação não permitida. Informe os dados do cartão novamente. Se o erro persistir, entre em contato com a loja virtual'
  },
  AH: {
    msg: 'Transação não permitida. Cartão de crédito sendo usado com débito. Solicite ao portador que selecione a opção de pagamento Cartão de Crédito',
    msg_to_client: 'Transação não autorizada. Tente novamente selecionando a opção de pagamento cartão de crédito'
  },
  AI: {
    msg: 'Transação não autorizada. Autenticação não foi realizada. O portador não concluiu a autenticação. Solicite ao portador que reveja os dados e tente novamente. Se o erro persistir, entre em contato com a Cielo informando o BIN (6 primeiros dígitos do cartão)',
    msg_to_client: 'Transação não autorizada. Autenticação não foi realizada com sucesso. Tente novamente e informe corretamente os dados solicitado. Se o erro persistir, entre em contato com o lojista'
  },
  AJ: {
    msg: 'Transação não permitida. Transação de crédito ou débito em uma operação que permite apenas Private Label. Solicite ao portador que tente novamente selecionando a opção Private Label. Caso não disponibilize a opção Private Label verifique na Cielo se o seu estabelecimento permite essa operação',
    msg_to_client: 'Transação não permitida. Transação de crédito ou débito em uma operação que permite apenas Private Label. Tente novamente e selecione a opção Private Label. Em caso de um novo erro entre em contato com a loja virtual'
  },
  AV: {
    msg: 'Falha na validação dos dados da transação. Oriente o portador a rever os dados e tentar novamente',
    msg_to_client: 'Falha na validação dos dados. Reveja os dados informados e tente novamente'
  },
  BD: {
    msg: 'Transação não permitida. Houve um erro no processamento.Solicite ao portador que digite novamente os dados do cartão, se o erro persistir pode haver um problema no terminal do lojista, nesse caso o lojista deve entrar em contato com a Cielo',
    msg_to_client: 'Transação não permitida. Informe os dados do cartão novamente. Se o erro persistir, entre em contato com a loja virtual'
  },
  BL: {
    msg: 'Transação não autorizada. Limite diário excedido. Solicite ao portador que entre em contato com seu banco emissor',
    msg_to_client: 'Transação não autorizada. Limite diário excedido. Entre em contato com seu banco emissor'
  },
  BM: {
    msg: 'Transação não autorizada. Cartão inválido. Pode ser bloqueio do cartão no banco emissor ou dados incorretos. Tente usar o Algoritmo de Lhum (Mod 10) para evitar transações não autorizadas por esse motivo',
    msg_to_client: 'Transação não autorizada. Cartão inválido. Refaça a transação confirmando os dados informados'
  },
  BN: {
    msg: 'Transação não autorizada. O cartão ou a conta do portador está bloqueada. Solicite ao portador que entre em contato com seu banco emissor',
    msg_to_client: 'Transação não autorizada. O cartão ou a conta do portador está bloqueada. Entre em contato com seu banco emissor'
  },
  BO: {
    msg: 'Transação não permitida. Houve um erro no processamento. Solicite ao portador que digite novamente os dados do cartão, se o erro persistir, entre em contato com o banco emissor',
    msg_to_client: 'Transação não permitida. Houve um erro no processamento. Digite novamente os dados do cartão, se o erro persistir, entre em contato com o banco emissor'
  },
  BP: {
    msg: 'Transação não autorizada. Não possível processar a transação por um erro relacionado ao cartão ou conta do portador. Solicite ao portador que entre em contato com o banco emissor',
    msg_to_client: 'Transação não autorizada. Não possível processar a transação por um erro relacionado ao cartão ou conta do portador. Entre em contato com o banco emissor'
  },
  BP176: {
    msg: 'Parceiro deve checar se o processo de integração foi concluído com sucesso',
    msg_to_client: 'Parceiro deve checar se o processo de integração foi concluído com sucesso'
  },
  BR: {
    msg: 'A conta do portador está encerrada. Solicite ao portador que entre em contato com seu banco emissor',
    msg_to_client: 'A conta do portador está encerrada. Solicite ao portador que entre em contato com seu banco emissor'
  },
  C1: {
    msg: 'Troque a modalidade de pagamento ou o cartão utilizado',
    msg_to_client: 'Troque a modalidade de pagamento ou o cartão utilizado'
  },
  C2: {
    msg: 'Dados incorretos. Favor rever os dados preenchidos na tela de pagamento',
    msg_to_client: 'Dados incorretos. Favor rever os dados preenchidos na tela de pagamento'
  },
  C3: {
    msg: 'Período inválido para este tipo de transação',
    msg_to_client: 'Período inválido para este tipo de transação'
  },
  CF: {
    msg: 'Transação não autorizada. Falha na validação dos dados. Solicite ao portador que entre em contato com o banco emissor',
    msg_to_client: 'Transação não autorizada. Falha na validação dos dados. Entre em contato com o banco emissor'
  },
  CG: {
    msg: 'Transação não autorizada. Falha na validação dos dados. Solicite ao portador que entre em contato com o banco emissor',
    msg_to_client: 'Transação não autorizada. Falha na validação dos dados. Entre em contato com o banco emissor'
  },
  DF: {
    msg: 'Transação não permitida. Falha no cartão ou cartão inválido. Solicite ao portador que digite novamente os dados do cartão, se o erro persistir, entre em contato com o banco',
    msg_to_client: 'Transação não permitida. Falha no cartão ou cartão inválido. Digite novamente os dados do cartão, se o erro persistir, entre em contato com o banco'
  },
  DM: {
    msg: 'Transação não autorizada. Limite excedido/sem saldo',
    msg_to_client: 'Transação não autorizada. Entre em contato com seu banco emissor'
  },
  DQ: {
    msg: 'Transação não autorizada. Falha na validação dos dados. Solicite ao portador que entre em contato com o banco emissor',
    msg_to_client: 'Transação não autorizada. Falha na validação dos dados. Entre em contato com o banco emissor'
  },
  DS: {
    msg: 'Transação não autorizada. Transação não permitida para o cartão',
    msg_to_client: 'Transação não autorizada. Entre em contato com seu banco emissor'
  },
  EB: {
    msg: 'Transação não autorizada. Entre em contato com a Cielo e verifique se o cadastro possui parcelamento liberado',
    msg_to_client: 'Transação não autorizada. Entre em contato com a Cielo e verifique se o cadastro possui parcelamento liberado'
  },
  EE: {
    msg: 'Transação não permitida. Valor da parcela inferior ao mínimo permitido. Não é permitido parcelas inferiores a R$ 5,00. Necessário rever calculo para parcelas',
    msg_to_client: 'Transação não permitida. O valor da parcela está abaixo do mínimo permitido. Entre em contato com a loja virtual'
  },
  EK: {
    msg: 'Transação não autorizada. Transação não permitida para o cartão',
    msg_to_client: 'Transação não autorizada. Entre em contato com seu banco emissor'
  },
  FC: {
    msg: 'Transação não autorizada. Oriente o portador a entrar em contato com o banco emissor',
    msg_to_client: 'Transação não autorizada. Entre em contato com seu banco emissor'
  },
  FE: {
    msg: 'Transação não autorizada. Data da transação ou data do primeiro pagamento inválida',
    msg_to_client: 'Transação não autorizada. Refazer a transação confirmando os dados'
  },
  FF: {
    msg: 'Transação de cancelamento autorizada com sucesso. ATENÇÂO: Esse retorno é para casos de cancelamentos e não para casos de autorizações',
    msg_to_client: 'Transação de cancelamento autorizada com sucesso'
  },
  FG: {
    msg: 'Transação não autorizada. Oriente o portador a entrar em contato com a Central de Atendimento AmEx',
    msg_to_client: 'Transação não autorizada. Entre em contato com a Central de Atendimento AmEx no telefone 08007285090'
  },
  GA: {
    msg: 'Transação não autorizada. Referida pelo Lynx Online de forma preventiva',
    msg_to_client: 'Transação não autorizada. lojista deve aguardar contato por parte da Cielo'
  },
  GF: {
    msg: 'Transação não autorizada, verifique se o IP informado está liberado para processar a transação',
    msg_to_client: 'Transação não permitida. Entre em contato com a Cielo'
  },
  GD: {
    msg: 'Transação não permitida. Entre em contato com a Cielo',
    msg_to_client: 'Transação não permitida. Entre em contato com a Cielo'
  },
  GT: {
    msg: 'Ataque de força bruta',
    msg_to_client: 'Transação não permitida. Entre em contato com a Cielo'
  },
  GK: {
    msg: 'Bloqueio temporário por ataque de força bruta',
    msg_to_client: 'Transação não permitida. Entre em contato com a Cielo'
  },
  HJ: {
    msg: 'Transação não permitida. Código da operação Coban inválido',
    msg_to_client: 'Transação não permitida. Código da operação Coban inválido. Entre em contato com o lojista'
  },
  IA: {
    msg: 'Transação não permitida. Indicador da operação Coban inválido',
    msg_to_client: 'Transação não permitida. Indicador da operação Coban inválido. Entre em contato com o lojista'
  },
  KA: {
    msg: 'Transação não permitida. Houve uma falha na validação dos dados. Solicite ao portador que reveja os dados e tente novamente. Se o erro persistir verifique a comunicação entre loja virtual e Cielo',
    msg_to_client: 'Transação não permitida. Houve uma falha na validação dos dados. reveja os dados informados e tente novamente. Se o erro persistir entre em contato com a Loja Virtual'
  },
  KB: {
    msg: 'Transação não permitida. Selecionado a opção incorreta. Solicite ao portador que reveja os dados e tente novamente. Se o erro persistir deve ser verificado a comunicação entre loja virtual e Cielo',
    msg_to_client: 'Transação não permitida. Selecionado a opção incorreta. Tente novamente. Se o erro persistir entre em contato com a Loja Virtual'
  },
  KE: {
    msg: 'Transação não autorizada. Falha na validação dos dados. Opção selecionada não está habilitada. Verifique as opções disponíveis para o portador',
    msg_to_client: 'Transação não autorizada. Falha na validação dos dados. Opção selecionada não está habilitada. Entre em contato com a loja virtual'
  },
  NR: {
    msg: 'Transação não permitida',
    msg_to_client: 'Transação não permitida. Retentar a transação após 30 dias'
  },
  RP: {
    msg: 'Transação não permitida',
    msg_to_client: 'Transação não permitida. Retentar a transação após 72h'
  },
  SC: {
    msg: 'Transação não permitida. Pagamento recorrente, serviço cancelado. Não retentar',
    msg_to_client: 'Transação não permitida. Pagamento recorrente, serviço cancelado. Não retentar'
  },
  U3: {
    msg: 'Transação não permitida. Houve uma falha na validação dos dados. Solicite ao portador que reveja os dados e tente novamente. Se o erro persistir verifique a comunicação entre loja virtual e Cielo',
    msg_to_client: 'Transação não permitida. Houve uma falha na validação dos dados. reveja os dados informados e tente novamente. Se o erro persistir entre em contato com a Loja Virtual'
  },
  '6P': {
    msg: 'Falha na validação dos dados da transação. Oriente o portador a rever os dados e tentar novamente',
    msg_to_client: 'Falha na validação dos dados. Reveja os dados informados e tente novamente'
  },
  BP171: {
    msg: 'Rejeitado por risco de fraude (Velocity)'
  },
  BP335: {
    msg: 'Cancelamento abortado por erro transacional Split'
  },
  BP900: {
    msg: 'Falha na operação'
  },
  BP901: {
    msg: 'Falha na operação'
  },
  BP902: {
    msg: 'Aguarde a resposta da operação anterior'
  },
  BP903: {
    msg: 'Falha no Cancelamento'
  }
}
