import { deepGetOrElse } from 'deep-getter'

export const SubordinateModel = {
  signup (payload = {}) {
    const { account = {}, accountable = {}, attachments = [] } = payload
    const { bank, accountType, documentType, document, numberAccount, verifierNumberAccount, numberAgency, verifierNumberAgency, companyCategory } = account
    const { name, telephone, mail, birthday } = accountable
    const birthdayISO = birthday?.toISOString()?.split('T')?.[0] || ''
    const isCpf = documentType === 'Cpf'

    return {
      gateway: 'Braspag',
      subordinate_params: {
        business_activity_id: isCpf ? parseInt(companyCategory) : undefined,
        contact_name: name,
        contact_phone: telephone.replace(/\D/gi, ''),
        mail_address: mail,
        birthday_date: isCpf ? birthdayISO : undefined,
        bank_account: {
          bank,
          bank_account_type: accountType,
          number: numberAccount,
          verifier_digit: verifierNumberAccount.replace(/\D/gi, ''),
          agency_number: numberAgency.replace(/\D/gi, ''),
          agency_digit: verifierNumberAgency || 'X',
          document_number: document.replace(/\D/gi, ''),
          document_type: documentType
        },
        attachments: attachments.map(attachment => {
          return {
            attachment_type: attachment.type,
            attachment: deepGetOrElse(attachment, 'attachment.0.file', null)
          }
        })
      }
    }
  }
}
