import { head, tail } from '@bonitour/common-functions'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { useQuery } from './useQuery'
import moment from 'moment'

export const useParamsFilter = () => {
  const history = useHistory()
  const query = useQuery()

  const params = useMemo(() => {
    const { changeQueryParam: _, ...params } = query
    return params
  }, [query])

  const pushQueryParams = useCallback((filters, history) => {
    const queryParams = Object.entries(filters).reduce((acc, item) => {
      const key = head(item)
      const value = tail(item)

      if (!value) {
        return acc
      }

      if (key === 'initialDate' || key === 'finalDate') {
        return acc.concat(`${key}=${moment(value).format('YYYY-MM-DD')}&`)
      }

      return acc.concat(`${key}=${value}&`)
    }, '')

    history.push({ search: `?${queryParams.slice(0, -1)}` })
  }, [])

  const changeParams = (filters) => pushQueryParams(filters, history)

  return [
    params,
    changeParams
  ]
}
