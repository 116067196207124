/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { formatMoney, formatDate } from '@bonitour/common-functions'
import { getPercentage } from '../../../Utils/percentage'
import {
  H4,
  colors,
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableBodyRow,
  TableBodyCell
} from '@bonitour/components'

const headerTitleStyle = css`
  color: ${colors.gray2};
  margin-top: 50px;
`

const RefundListingTableHeader = ({ columns }) => (
  <TableHeader>
    <TableHeaderRow>
      {columns.map((item) => <TableHeaderCell key={item}>{item}</TableHeaderCell>)}
    </TableHeaderRow>
  </TableHeader>
)

const RefundListingTableBody = ({ data, loading }) => {
  return (
    <TableBody loading={loading}>
      {data.map(({ aut, doc, reservationCode, paymentDate, refundDate, channel, paymentValue, paymentFee, refundValue }) => (
        <TableBodyRow key={`${aut} + ${reservationCode}`}>
          <TableBodyCell>{aut || '-'}</TableBodyCell>
          <TableBodyCell>{doc || '-'}</TableBodyCell>
          <TableBodyCell>{reservationCode || '-'}</TableBodyCell>
          <TableBodyCell>{paymentDate ? formatDate(paymentDate) : '-'}</TableBodyCell>
          <TableBodyCell>{formatDate(refundDate)}</TableBodyCell>
          <TableBodyCell>{channel || '-'}</TableBodyCell>
          <TableBodyCell>{formatMoney(paymentValue)}</TableBodyCell>
          <TableBodyCell>{getPercentage(paymentFee) || '-'}</TableBodyCell>
          <TableBodyCell>{formatMoney(refundValue)}</TableBodyCell>
        </TableBodyRow>
      ))}
    </TableBody>
  )
}

export const RefundList = ({ titleColumnList = [], refundList = [], loading }) => {
  return (
    <>
      <H4 css={headerTitleStyle}> Resultado de pesquisa</H4>
      <TableContainer>
        <RefundListingTableHeader columns={titleColumnList} />
        <RefundListingTableBody data={refundList} loading={loading} />
      </TableContainer>
    </>
  )
}
