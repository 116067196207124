import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { TransactionService } from 'Core/Service/Transaction'
import { useParamsFilter } from 'Shared/hooks/useParamsFilter'
import { usePagination } from '@bonitour/app-functions'

export const useListTransaction = () => {
  const { add: addToast } = useToast()
  const [filters, setFilters] = useParamsFilter()
  const [loading, setLoading] = useState(false)
  const [transactionData, setTransactionData] = useState({
    transactions: undefined,
    meta: {
      total: 0
    }
  })

  const {
    pagination,
    setPagination,
    handleOnPageChange,
    handleOnPerPageChange
  } = usePagination()

  const fetchTransactions = useCallback((
    {
      newFilters,
      newPage,
      newPerPage,
      finallyCallback
    }
  ) => {
    setLoading(true)
    return TransactionService.list({
      filters: newFilters || filters,
      pagination: {
        currentPage: newPage || pagination.page,
        perPage: newPerPage || pagination.perPage
      }
    }).then((data) => {
      setTransactionData(data)
      setPagination({
        page: newPage || pagination.page,
        perPage: newPerPage || pagination.perPage,
        totalItems: data?.meta?.totalEntries || 0,
        totalPages: data?.meta?.totalPages || 0
      })
    }).catch((error) => {
      console.error(error)
      addToast('Erro inesperado')
    }).finally(() => {
      setLoading(false)
      finallyCallback && finallyCallback()
    })
  }, [
    addToast,
    filters,
    pagination.page,
    pagination.perPage,
    setPagination
  ])

  const handleFiltersChange = useCallback((newFilters) => {
    fetchTransactions({
      newFilters,
      newPage: 1,
      finallyCallback: () => setFilters(newFilters)
    })
  }, [fetchTransactions, setFilters])

  useEffect(() => {
    if (pagination.isActive || (!filters?.initialDate && !filters?.finalDate)) return

    fetchTransactions({
      newPage: 1,
      newPerPage: pagination.perPage
    })
  }, [
    fetchTransactions,
    filters,
    pagination.isActive,
    pagination.perPage
  ])

  return {
    filters,
    setFilters: handleFiltersChange,
    handleOnPageChange: handleOnPageChange(fetchTransactions),
    handleOnPerPageChange: handleOnPerPageChange(fetchTransactions),
    pagination,
    transactions: transactionData?.transactions,
    meta: transactionData?.meta,
    loading
  }
}
