import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const subtitleStyle = css`
  color: ${colors.primary};
  margin-left: 5px;
`

export const containerTitle = css`
  display: flex;
  margin: 25px 0px;
`

export const headerTitle = css`
  font-weight: 500;
  color: ${colors.gray1};
`
