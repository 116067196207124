import { useState } from 'react'
import { DigitalAccountsService } from 'Core/Service/DigitalAccounts'
import { useToast } from '@bonitour/components'

export const useRegisterKey = (keyId, returnToMyKeys) => {
  const [loading, setLoading] = useState(false)
  const { add: addToast } = useToast()

  const editRegisteredKey = async (data) => {
    setLoading(true)
    return DigitalAccountsService.editKey({ ...data }, keyId)
      .finally(() => {
        setLoading(false)
        addToast('Configuração salva!', 'success')
        returnToMyKeys()
      })
  }

  const deleteRegisteredKey = async (data) => {
    setLoading(true)
    return DigitalAccountsService.deleteKey({ ...data }, keyId)
      .finally(() => {
        setLoading(false)
        addToast('Configuração salva!', 'success')
      })
  }

  return {
    onRegisterKey: editRegisteredKey,
    onDeleteKey: deleteRegisteredKey,
    loading
  }
}
