/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useHistory } from 'react-router'
import { jsx, css } from '@emotion/core'
import { H1, Card, PaginationContainer, GhostButton, Row } from '@bonitour/components'
import { RefundFilter } from './Filter/RefundFilter'
import { RefundList } from './List/RefundList'
import { useListRefund } from './Hooks/useListRefund'

const containerButton = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const noMargin = css`
  margin: 0;
`

export const Refund = () => {
  const {
    refunds,
    setFilters,
    meta,
    loading,
    handlePagination
  } = useListRefund()

  const { push } = useHistory()

  const goBack = () => push('/dashboard')

  const onFilterSubmit = filters => setFilters(filters)

  const { totalPages = 0, totalEntries } = meta ?? {}

  const titleColumnTransactionListingTable = ['Aut', 'Doc', 'Reserva', 'Compra em', 'Estorno em', 'Canal', 'Valor', 'Taxa', 'Valor estornado']

  return (
    <>
      <Row css={containerButton}>
        <H1 css={noMargin}>Estornos</H1>
        <GhostButton onClick={goBack}>Voltar</GhostButton>
      </Row>
      <Card>
        <RefundFilter onFilterSubmit={onFilterSubmit} />
        <PaginationContainer
          total={totalEntries}
          pagesQuantity={totalPages}
          onPagination={handlePagination}
        >
          <RefundList
            titleColumnList={titleColumnTransactionListingTable}
            refundList={refunds}
            loading={loading}
          />
        </PaginationContainer>
      </Card>
    </>
  )
}
