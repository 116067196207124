/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'
import { Button, Input, Select, InputFormGroup, Card, Row, Column, colors, DatePicker, useToast } from '@bonitour/components'
import { TransactionFilterSchema } from './TransactionFilter.schema'
import { useQuery } from 'Shared/hooks/useQuery'
import moment from 'moment'
import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { statusOptionsMap } from '../utils/utils'

const stylishCardSearch = css`
  border: none;
  background-color: ${colors.gray8};
  padding: 20px;
`
const button = css`
  height: 40px;
  width: 100%;
  margin-top: auto;
`

const width100 = css`
  width: 100%;
`

export const TransactionFilter = ({ onFilterSubmit: emitFilterSubmit = identity }) => {
  const { add: addToast } = useToast()
  const params = useQuery()

  const {
    initialDate: initialDateParam,
    finalDate: finalDateParam,
    reservation: reservationParam,
    aut: autParam,
    status: statusParam
  } = params

  const [initialFilter] = useState({
    initialDate: initialDateParam && moment(initialDateParam, 'YYYY-MM-DD'),
    finalDate: finalDateParam && moment(finalDateParam, 'YYYY-MM-DD'),
    reservation: reservationParam && reservationParam,
    aut: autParam && autParam,
    status: statusParam && statusParam
  })

  const { form, errors, onSubmit, utils: { onInputChange, onInputBlur } } = useForm(initialFilter, TransactionFilterSchema)

  const onError = () => addToast('Preencha o formulário corretamente')

  const emitSubmit = () => {
    if (!reservation && (!initialDate || !finalDate)) {
      return addToast('Informe uma data inicial e final ou um código de reserva')
    }
    onSubmit(emitFilterSubmit(form), onError)
  }

  const onClick = onSubmit(emitSubmit, onError)

  const { initialDate, finalDate, reservation, aut, status } = form

  return (
    <Card css={stylishCardSearch}>
      <Row>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Reserva'>
            <Input
              id='reservation'
              value={reservation}
              onChange={onInputChange('reservation')}
              onBlur={onInputBlur('reservation')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Data inicial' errorMessage={errors.initialDate}>
            <DatePicker
              id='initialDate'
              css={width100}
              value={initialDate}
              placeholder='Data Inicial'
              onChange={onInputChange('initialDate')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Data final' errorMessage={errors.finalDate}>
            <DatePicker
              id='finalDate'
              css={width100}
              value={finalDate}
              placeholder='Data Final'
              onChange={onInputChange('finalDate')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Cod. de autorização'>
            <Input
              placeholder=''
              id='aut'
              value={aut}
              onChange={onInputChange('aut')}
              onBlur={onInputBlur('aut')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Estado'>
            <Select
              id='status'
              value={status}
              onChange={onInputChange('status')}
              options={statusOptionsMap}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          {/* <InputFormGroup label='Pagamento'>
            <Select
              id='payment'
              value=''
              placeholder=''
              // onChange={onInputChange('status')}
              // onBlur={onInputBlur('status')}
              options={
                [{
                  value: 'credit',
                  label: 'Crédito'
                },
                {
                  value: 'debit',
                  label: 'Débito'
                }]
              }
            />
          </InputFormGroup> */}
        </Column>
        <Column phone={12} desktop={3} />
        <Column phone={12} desktop={3}>
          <Button css={button} onClick={onClick}>
            Pesquisar
          </Button>
        </Column>
      </Row>
    </Card>
  )
}
