/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { formatMoney } from '@bonitour/common-functions'
import { Card, DatePicker, InputFormGroup, Input, Row, Column, flexRowCentered } from '@bonitour/components'
import { getStatusLabelGray, getCardBrand, getCardLabel } from '../../utils/utils'
import { advancedInput, width100, cardColor, labelColor, paddingLeft } from './TransactionData.styles'

export const TransactionData = ({ transaction }) => {
  const {
    aut,
    doc,
    status,
    brandCard,
    createdAt,
    paymentMethod,
    installments,
    cardFinalDigits,
    cardFee,
    binamikFee,
    fee,
    mdrFee,
    reservationCode
  } = transaction ?? {}

  const getPaymentMethod = {
    pay_credit: 'Crédito',
    pay_debit: 'Débito'
  }

  const RenderInput = ({ label = '', value = '', advanced = false, brand = '', status = '' }) => {
    if (advanced) {
      return (
        <Column desktop={3}>
          <InputFormGroup label={label}>
            <div css={advancedInput}>
              {brand && <div css={[paddingLeft(20), flexRowCentered]}>{getCardBrand(brand)}<div>{getCardLabel(brand)}</div></div>}
              {status && <div css={paddingLeft(20)}>{getStatusLabelGray(status)}</div>}
              {(!status && !brand) && <small css={[paddingLeft(20), labelColor]}>{value}</small>}
            </div>
          </InputFormGroup>
        </Column>
      )
    } else {
      return (
        <Column desktop={3}>
          <InputFormGroup label={label}>
            <Input
              readOnly
              placeholder=''
              id={label}
              value={value}
            />
          </InputFormGroup>
        </Column>
      )
    }
  }

  return (
    <Card css={cardColor}>
      <Row>
        <div css={width100}>
          <Row>
            <RenderInput label='Reserva' value={reservationCode || '-'} />
            <Column desktop={3}>
              <InputFormGroup label='Compra em'>
                <DatePicker css={width100} value={createdAt} disabled />
              </InputFormGroup>
            </Column>
            <RenderInput label='Aut' value={aut} />
            <RenderInput label='Doc' value={doc} />
          </Row>
        </div>
        <div css={width100}>
          <Row>
            <RenderInput label='Bandeira' value={brandCard} brand={brandCard} advanced />
            <RenderInput label='Forma de pagamento' value={getPaymentMethod[paymentMethod]} />
            <RenderInput label='Número de parcelas' value={installments} />
            <RenderInput label='Número de cartão' value={`**** **** **** ${cardFinalDigits || '****'}`} />
          </Row>
        </div>
        <div css={width100}>
          <Row>
            <RenderInput label='Canal' value='Binamik Pay' />
            <RenderInput label='Taxa do cartão' value={cardFee && `${(cardFee * 100).toFixed(2)}%  |  ${formatMoney(mdrFee)}`} />
            <RenderInput label='Taxa Binamik' value={binamikFee && `${(binamikFee * 100).toFixed(2)}%  |  ${formatMoney(fee)}`} />
            <RenderInput label='Estado' status={status} advanced />
          </Row>
        </div>
      </Row>
    </Card>
  )
}
