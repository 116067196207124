import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const containerDialog = css`
  margin-top: 1em;
`

export const label = css`
  font-size: 13px;
  color: ${colors.gray4};
  margin-bottom: 6px;
`

export const registerButton = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em 0 1em 0;
  cursor: pointer;
  width: fit-content;
`

export const labelRegisterButton = css`
  font-size: 13px;
  color: ${colors.primary};
  margin-left: 10px;
  font-weight: 500;
`

export const divider = css`
  height: 1px;
  width: 100%;
  background-color: ${colors.gray12};
  margin: 10px 0 10px 0;
`

export const numberKeys = css`
  font-size: 11px;
  color: ${colors.gray6};
`

export const ellipsisIconStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: ${colors.gray4};
  margin-top: 30px;
  cursor: pointer;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  transition: 0.2s;
  &:hover {
    background-color: ${colors.gray12};
  }
`

export const containerKeyDescription = css`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

export const titleKey = css`
  font-size: 15px;
  color: ${colors.gray1};
  font-weight: 500;
  margin-bottom: 18px;
`
