import { useEffect, useState } from 'react'
import { companyConfigService } from 'Core/Service/CompanyConfig'

export const useGetCompanyConfig = (id) => {
  const [loading, setLoading] = useState(false)
  const [currentCompanyConfig, setCurrentCompanyConfig] = useState()

  useEffect(() => {
    setLoading(true)
    companyConfigService.getById(id).then(setCurrentCompanyConfig)
      .finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    loading,
    currentCompanyConfig
  }
}
