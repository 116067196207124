import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { BankAccountMovementsApi } from '../../../Core/Api/BankAccountMovements'

export const useListMovements = (accountId) => {
  const { add: addToast } = useToast()
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [movementsData, setMovementsData] = useState([])
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10
  })

  const handlePagination = (currentPage, perPage) => {
    if (currentPage === pagination.currentPage && perPage === pagination.perPage) {
      return
    }

    setPagination({
      currentPage,
      perPage
    })
    // eslint-disable-next-line
  }

  const fetchDigitalAccountMovements = useCallback(({
    filters,
    pagination
  }) => {
    setLoading(true)

    return BankAccountMovementsApi.list({
      filters,
      pagination
    }).then((data) => {
      setMovementsData(data)
    }).catch(() => {
      addToast('Erro inesperado')
    }).finally(() => {
      setLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    accountId && startDate && endDate && fetchDigitalAccountMovements({
      pagination,
      filters: {
        start_date: startDate,
        end_date: endDate,
        bank_account_id: accountId,
        order: 'desc'
      }
    })
  }, [pagination, startDate, endDate, fetchDigitalAccountMovements, accountId])

  return {
    handlePagination,
    movementsData,
    meta: movementsData?.meta,
    loading,
    setStartDate,
    startDate,
    setEndDate,
    endDate
  }
}
