import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const containerTooltip = css`
  padding: 10px 20px;
`

export const containerOption = css`
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
`

export const labelTooltip = (color) => css`
  font-size: 13px;
  color: ${color};
  margin: 0 2em 0 8px;
`

export const iconTooltip = (color) => css`
  font-size: 19px;
  color: ${color};
`

export const divider = css`
  height: 1px;
  width: 100%;
  background-color: ${colors.gray12};
  margin: 10px 0 10px 0;
`
