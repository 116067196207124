// This object was assembled using the links
// https://braspag.github.io//manual/braspag-pagador#c%C3%B3digos-de-erros-da-api
// Using the "Lista de Status da Transação"

export const paymentStatus = {
  0: {
    msg: 'Falha ao processar o pagamento'
  },
  1: {
    msg: 'Meio de pagamento apto a ser capturado ou pago'
  },
  2: {
    msg: 'Pagamento confirmado e finalizado'
  },
  3: {
    msg: 'Pagamento negado por autorizador'
  },
  10: {
    msg: 'Pagamento cancelado'
  },
  11: {
    msg: 'Pagamento cancelado/estornado'
  },
  12: {
    msg: 'Esperando retorno da instituição financeira'
  },
  13: {
    msg: 'Pagamento cancelado por falha no processamento'
  },
  20: {
    msg: 'Recorrência agendada'
  }
}
