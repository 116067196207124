/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { head } from '@bonitour/common-functions'
import { Button, CheckIcon, CloseIcon, colors, Dialog } from '@bonitour/components'
import {
  containerStyle,
  containerIcon,
  iconStyle,
  titleStyle,
  messageStyle,
  secondMessageStyle,
  closeButtonStyle
} from './DialogTransferStatus.styles'

const containerIconStyleSuccess = containerIcon(colors.green2)
const containerIconStyleError = containerIcon(colors.red2)
const iconStyleSuccess = iconStyle(colors.green2)
const iconStyleError = iconStyle(colors.red2)

export const DialogTransferStatus = ({ isVisible, toggleHidden, setTransferStatusModalData, transferStatusModalData }) => {
  const { status } = transferStatusModalData ?? ''
  const { message } = transferStatusModalData ?? []
  const { message: returnRequestMessage } = head(message) ?? ''

  const closeDialog = () => {
    setTransferStatusModalData(undefined)
    toggleHidden()
  }

  const defineReturnModalData = {
    error: {
      icon: <div css={containerIconStyleError}><CloseIcon css={iconStyleError} /></div>,
      title: 'Não foi possível realizar a transação',
      message: returnRequestMessage || '',
      secondMessage: ''
    },
    success: {
      icon: <div css={containerIconStyleSuccess}><CheckIcon css={iconStyleSuccess} /></div>,
      title: 'Transação realizada com sucesso',
      message: 'A transação realizada irá aparecer no histórico em até 10 minutos.',
      secondMessage: 'Enviamos um e-mail confirmando a transação realizada.'
    }
  }

  return (
    <Dialog isVisible={isVisible} onClose={closeDialog}>
      <div css={containerStyle}>
        {defineReturnModalData[status]?.icon}
        <h2 css={titleStyle}>{defineReturnModalData[status]?.title}</h2>
        <small css={messageStyle}>{defineReturnModalData[status]?.message}</small>
        <small css={secondMessageStyle}>{defineReturnModalData[status]?.secondMessage}</small>
        <Button onClick={closeDialog} css={closeButtonStyle}>Fechar</Button>
      </div>
    </Dialog>
  )
}
