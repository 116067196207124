/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Button, GhostButton, colors } from '@bonitour/components'
import { jsx, css } from '@emotion/core'

const titleButton = css`
  color: ${colors.primary};
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
`

const marginRight = css`
  margin-right: 20px;
`

export const WaitingStatusButton = ({ navigateToDashboard }) => {
  return (
    <Button onClick={navigateToDashboard}>Voltar ao início</Button>
  )
}

export const BlockedStatusButton = ({ navigateToDashboard, navigateToCreateDigitalAccount }) => {
  return (
    <>
      <GhostButton onClick={navigateToDashboard} css={marginRight}>Voltar ao início</GhostButton>
      <span onClick={navigateToCreateDigitalAccount} css={titleButton}>Criar uma nova conta digital</span>
    </>
  )
}

export const WaitingDocumentsStatusButton = ({ navigateToDashboard, navigateToCreateDigitalAccount }) => {
  return (
    <>
      <GhostButton onClick={navigateToDashboard} css={marginRight}>Voltar ao início</GhostButton>
      <Button onClick={navigateToCreateDigitalAccount}>Verificar o cadastro</Button>
    </>
  )
}

export const RefusedStatusButton = ({ navigateToDashboard, navigateToCreateDigitalAccount }) => {
  return (
    <>
      <GhostButton onClick={navigateToDashboard} css={marginRight}>Voltar ao início</GhostButton>
      <Button onClick={navigateToCreateDigitalAccount}>Tentar novamente</Button>
    </>
  )
}

export const InactiveStatusButton = ({ navigateToDashboard, navigateToCreateDigitalAccount }) => {
  return (
    <>
      <GhostButton onClick={navigateToDashboard} css={marginRight}>Voltar ao início</GhostButton>
      <span onClick={navigateToCreateDigitalAccount} css={titleButton}>Criar uma nova conta digital</span>
    </>
  )
}
