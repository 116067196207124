/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Link } from 'react-router-dom'
import { Header as H, Hamburguer, Logo, flexRowCentered, colors } from '@bonitour/components'
import { User } from '../User'
import { APP_ENVIRONMENT } from 'Shared/config/env'
import BinamikLogo from 'Shared/svgs/logoBinamikPay.svg'
import { ProductsSection } from '../ProductsSection'
import { HelpSection } from './Help'

const envStyle = css`
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
  color: ${colors.white};
  background: ${colors.warning};
  text-transform: capitalize;
`

export const Header = ({ onOpenSideBar }) => {
  return (
    <H>
      <div css={flexRowCentered}>
        <Hamburguer onClick={onOpenSideBar} />
        <Link to='/'>
          <Logo src={BinamikLogo} />
        </Link>

        {APP_ENVIRONMENT !== 'production' && <span css={envStyle}>{APP_ENVIRONMENT}</span>}
      </div>

      <div css={flexRowCentered}>
        <HelpSection />
        <ProductsSection />
        <User />
      </div>
    </H>
  )
}
