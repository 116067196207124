// source: https://braspag.github.io/manual/manual-api-de-cadastro-de-sellers#lista-de-ramos-de-atividades

export const companyCategoryOptions = [
  { value: 1, label: 'Não encontrado' },
  { value: 4, label: 'Lanchonete' },
  { value: 6, label: 'Táxi e Limusine' },
  { value: 9, label: 'Bares, Discotecas e Casas Noturnas' },
  { value: 16, label: 'Comida Rápida (Doces e Salgados)' },
  { value: 17, label: 'Diversão e Recreação' },
  { value: 24, label: 'Restaurantes' },
  { value: 27, label: 'Fornecedores de Alimentos ou Mantimentos' },
  { value: 29, label: 'Presentes, Cartões, Souvenirs' },
  { value: 37, label: 'Padarias' },
  { value: 38, label: 'Lojas de Bebidas' },
  { value: 45, label: 'Campo de Esportes Clubes' },
  { value: 46, label: 'Estúdio Fotográfico' },
  { value: 48, label: 'Transportes Urbanos' },
  { value: 50, label: 'Docerias e Confeitarias' },
  { value: 54, label: 'Dança, Academias' },
  { value: 56, label: 'Brinquedos e Jogos' },
  { value: 57, label: 'Hotéis, Motéis' },
  { value: 84, label: 'Clubes de Atletismo - Sócios' },
  { value: 85, label: 'Bicicletas - Vendas e Serviços' },
  { value: 86, label: 'Estacionamento de Automóveis' },
  { value: 93, label: 'Aluguel de Equipamentos e Mobílias' },
  { value: 97, label: 'Galerias de Arte, Lleilão de Arte' }
]

export const oldCompanyCategoryOptions = [
  {
    value: '4722',
    label: 'Agências de viagens e operadores turísticos'
  },
  {
    value: '7011',
    label: 'Hospedagem - hotéis, motéis e resorts'
  },
  {
    value: '5499',
    label: 'Lojas de alimentos diversos - lojas de varejo especializadas e de conveniência'
  },
  {
    value: '5814',
    label: 'Restaurantes Fast Food'
  },
  {
    value: '7991',
    label: 'Atrações turísticas e exposições'
  },
  {
    value: '5812',
    label: 'Restaurantes e lugares para comer'
  }
]
