export const DigitalAccountMovementsParser = {
  single: (accountMovement) => {
    const {
      id,
      date,
      operation_type: operationType,
      value,
      info
    } = accountMovement ?? {}
    return {
      id,
      date,
      value,
      operationType,
      descricao: info.descricao
    }
  },
  meta ({ page, count: totalMoves, last: totalPages }) {
    return {
      page,
      totalMoves,
      totalPages
    }
  },
  list: (accountMovements) => {
    return {
      accountMovements: accountMovements?.data.account_movements.map(DigitalAccountMovementsParser.single),
      meta: DigitalAccountMovementsParser.meta(accountMovements?.data.meta)
    }
  }
}
