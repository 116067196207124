/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  Modal
} from '@bonitour/components'
import { transactionModalContainer } from './TransactionModal.styles'

const RenderDetailsContent = ({ data }) => {
  return (
    <div className='transacation_details_content'>
      {data.map(({ key, label, value }, idx) => (
        <div className={`transacation_details_wrapper_${key}`} key={idx}>
          <p className={`item_label_${key}`}>{label}:</p>
          {value && typeof value === 'object'
            ? (
              value.map((entry, idxEntry) => typeof entry === 'object'
                ? (
                  entry?.msg && entry?.msg_to_client
                    ? (
                      <div className='wrapper_card' key={idxEntry}>
                        {entry?.code && <p className={`item_label_${key}`}>{entry?.code}:</p>}
                        {entry?.msg && (
                          <>
                            <p className={`item_label_${key}`}>Mensagem:</p>
                            <p className={`item_${key}`}>{entry?.msg}</p>
                          </>
                        )}
                        {entry?.msg_to_client && (
                          <>
                            <p className={`item_label_${key}`}>Mensagem para o cliente:</p>
                            <p className={`item_${key}`}>{entry?.msg_to_client}</p>
                          </>
                        )}
                      </div>
                    )
                    : (
                      <div className='wrapper_code_msg wrapper_card' key={idxEntry}>
                        <p className={`item_label_${key}`}>{entry?.code}:</p>
                        <p className={`item_${key}`}>{entry?.msg}</p>
                      </div>
                    )
                )
                : (
                  <p className={`item_${key}`} key={idxEntry}>{entry}</p>
                ))
            )
            : (
              <p className={`item_${key}`}>{value}</p>
            )}
        </div>
      ))}
    </div>
  )
}

export const TransactionModal = ({ isVisible, toggleModal, modalData }) => {
  return (
    <Modal
      title='Detalhes da transação'
      isVisible={isVisible}
      onCloseClick={toggleModal}
      customCss={[transactionModalContainer]}
    >
      <>
        <h3>Análise do pagamento:</h3>
        {modalData?.detailsTransaction?.payment?.length
          ? (
            <RenderDetailsContent
              data={modalData?.detailsTransaction?.payment}
            />
          )
          : <p className='empty__message'>Não há informações</p>}
      </>

      <>
        <h3>Análise do antifraude:</h3>
        {modalData?.detailsTransaction?.antiFraud?.length
          ? (
            <RenderDetailsContent
              data={modalData?.detailsTransaction?.antiFraud}
            />
          )
          : <p className='empty__message'>Não há informações</p>}
      </>
    </Modal>
  )
}
