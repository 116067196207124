/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  cardStyle,
  fullWidth
} from './DigitalAccountFilter.styles'
import {
  Card,
  Row,
  Column,
  DatePicker,
  InputFormGroup
} from '@bonitour/components'

export const DigitalAccountFilter = ({ setStartDate, startDate, setEndDate, endDate }) => {
  return (
    <Card css={cardStyle}>
      <Row>
        <Column desktop={3} phone={4}>
          <InputFormGroup label='Data Inicial'>
            <DatePicker
              css={fullWidth}
              value={startDate}
              onChange={setStartDate}
              placeholder='Selecione uma data'
            />
          </InputFormGroup>
        </Column>
        <Column desktop={3} phone={4}>
          <InputFormGroup label='Data Final'>
            <DatePicker
              css={fullWidth}
              value={endDate}
              onChange={setEndDate}
              placeholder='Selecione uma data'
              maxDate={Date.now()}
            />
          </InputFormGroup>
        </Column>
      </Row>
    </Card>
  )
}
