export const CompanyConfigModel = {
  create (payload) {
    const {
      maxInstallments
    } = payload ?? {}

    return {
      max_installments: maxInstallments
    }
  },
  edit (payload) {
    const {
      maxInstallments
    } = payload ?? {}

    return {
      max_installments: maxInstallments
    }
  }
}
