import { DigitalAccountsCore } from '../Service/DigitalAccountsCore'

const digitalAccountsResource = '/digital-accounts'

export const DigitalAccountsApi = {
  search: filters => {
    return DigitalAccountsCore.get(`${digitalAccountsResource}/search`, { params: { ...filters } })
  },
  create: payload => {
    return DigitalAccountsCore.post(`${digitalAccountsResource}/link`, { ...payload })
  },
  pixWithdrawal: (digitalAccountId, payload) => {
    return DigitalAccountsCore.post(`${digitalAccountsResource}/${digitalAccountId}/pix-withdrawal`, { ...payload })
  },
  getBalance: (digitalAccountId) => {
    return DigitalAccountsCore.get(`${digitalAccountsResource}/${digitalAccountId}/balance`)
  },
  editKey: (payload, keyId) => {
    return DigitalAccountsCore.patch(`${digitalAccountsResource}/${keyId}`, { ...payload })
  }
}
