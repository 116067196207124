/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { H4, Card, Row, Column, Label, GhostButton, flexCenteredSpaceBetweenRow, H1 } from '@bonitour/components'
import { formatMoney } from '@bonitour/common-functions'
import { TransactionData } from './TransactionData/TransactionData'
import { TransactionHistory } from './TransactionHistory'
import { useHistory } from 'react-router-dom'
import { colorOrange3, colorPrimary, width100, marginValue, marginBottom, marginTop10 } from './TransactionEntry.styles'
import { useGetTransaction } from '../Hooks/useGetTransaction'

const marginBottom0 = marginBottom(0)
const marginBottom20 = marginBottom(20)

export const TransactionEntry = () => {
  const history = useHistory()
  const { transaction } = useGetTransaction()
  const { goBack } = history
  const { amount, netValue, history: transactionHistory } = transaction ?? {}

  const ValueCard = ({ label = '', value, valueColor = 'primary' }) => {
    const defineValueColor = {
      primary: colorPrimary,
      orange: colorOrange3
    }

    return (
      <Column desktop={6}>
        <Card css={width100}>
          <Label>{label}</Label>
          <H4 css={[marginValue, defineValueColor[valueColor]]}>{formatMoney(value)}</H4>
        </Card>
      </Column>
    )
  }

  return (
    <>
      <div css={[flexCenteredSpaceBetweenRow, marginBottom20, marginTop10]}>
        <H1 css={marginBottom0}>Transação</H1>
        <GhostButton onClick={goBack}>Voltar</GhostButton>
      </div>
      <Row>
        <ValueCard label='Valor bruto' value={amount} valueColor='orange' />
        <ValueCard label='Valor líquido' value={netValue} valueColor='primary' />
      </Row>
      <Column desktop={12}>
        <Card>
          <TransactionData transaction={transaction} />
          <TransactionHistory history={transactionHistory} />
        </Card>
      </Column>
    </>
  )
}
