
import { CompanyParser } from 'Core/Parser/Company'
import { OrbCore } from './Orb'

const { companiesDomain } = OrbCore

export const CompanyService = {
  listUserCompanies (active) {
    return companiesDomain.listUserCompanies(active).then(CompanyParser.listUserCompanies)
  }
}
