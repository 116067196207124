import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const containerTooltip = css`
  padding: 14px 10px;
`

export const containerOption = css`
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
`

export const divider = css`
  height: 1px;
  width: 100%;
  background-color: ${colors.gray12};
  margin: 10px 0 10px 0;
`

export const labelTooltipGray = css`
  font-size: 14px;
  color: ${colors.gray3};
  margin: 0 2em 0 8px;
`

export const labelKeys = css`
  font-size: 12px;
  color: ${colors.gray5};
`
