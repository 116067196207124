/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback, useRef } from 'react'
import { jsx } from '@emotion/core'
import { head, maskCnpj, maskCpf } from '@bonitour/common-functions'
import { useClickOutside } from '@bonitour/app-functions'
import { copyText } from 'Utils/copyText'
import { PixKeysTooltipOptions } from './PixKeysTooltipOptions/PixKeysTooltipOptions'
import { defineBankAccountType } from 'Domains/DigitalAccount/Constants/DigitalAccounts'
import { useListDigitalAccount } from 'Domains/DigitalAccount/Hooks/useListDigitalAccount'
import { useRegisterKey } from 'Domains/DigitalAccount/Hooks/useRegisterKey'
import {
  CircleButton,
  PlusIcon,
  flexRowCentered,
  EllipsisIcon,
  flexCenteredSpaceBetweenRow,
  Manager,
  Reference,
  useToast,
  ConfirmDialog
} from '@bonitour/components'
import {
  label,
  containerDialog,
  registerButton,
  labelRegisterButton,
  divider,
  numberKeys,
  titleKey,
  containerKeyDescription,
  ellipsisIconStyle
} from './MyKeys.styles'

const RegisteredKey = ({ title, digitalAccounts }) => {
  const reference = useRef()
  const { add: addToast } = useToast()
  const { onDeleteKey } = useRegisterKey(head(digitalAccounts)?.id, () => {})

  const [isVisibleKeyOptions, setVisibilityKeyOptions] = useClickOutside(reference)
  const [isVisibleDeleteKey, setVisibilityDeleteKey] = useClickOutside(false)

  const toggleVisibilityOptions = () => setVisibilityKeyOptions(visibility => !visibility)

  const toggleVisibilityDeleteKey = () => {
    setVisibilityKeyOptions(false)
    setVisibilityDeleteKey(visibility => !visibility)
  }

  const successDeleteKey = () => onDeleteKey()

  const {
    responsibleName,
    responsibleDocument,
    bankNumber,
    bankAgencyNumber,
    bankAccountNumber,
    bankAccountType
  } = head(digitalAccounts) ?? {}

  const defineDocumentMask = (document) => {
    if (document?.length === 11) return maskCpf(document)
    else if (document?.length === 14) return maskCnpj(document)
  }

  const handleCopyText = useCallback(() => {
    const copiedText =
      `Nome do responsável: ${responsibleName || ''}\nDocumento do responsável: ${defineDocumentMask(responsibleDocument)}\nNúmero do Banco: ${bankNumber || ''}\nAgência: ${bankAgencyNumber || ''}\nConta: ${bankAccountNumber || ''}\nTipo da conta: ${defineBankAccountType[bankAccountType] || ''}`
    copyText(copiedText, addToast)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [digitalAccounts])

  return (
    <>
      <ConfirmDialog
        title='Excluir chave'
        message='Tem certeza que deseja excluir sua chave Pix?'
        isVisible={isVisibleDeleteKey}
        successCallback={successDeleteKey}
        cancelCallback={toggleVisibilityDeleteKey}
      />
      <div ref={reference} css={flexCenteredSpaceBetweenRow}>
        <div css={containerKeyDescription}>
          <span css={titleKey}>{title}</span>
          <small id='teste' css={label}>{`Nome do responsável: ${responsibleName || ''}`}</small>
          <small css={label}>{`Documento do responsável: ${defineDocumentMask(responsibleDocument)}`}</small>
          <small css={label}>{`Número do banco: ${bankNumber || ''}`}</small>
          <small css={label}>{`Agência: ${bankAgencyNumber || ''}`}</small>
          <small css={label}>{`Conta: ${bankAccountNumber || ''}`}</small>
          <small css={label}>{`Tipo da conta: ${defineBankAccountType[bankAccountType] || ''}`}</small>
        </div>
        <Manager>
          <Reference>
            {({ ref }) => (
              <div ref={ref} onClick={toggleVisibilityOptions} css={ellipsisIconStyle}>
                <EllipsisIcon />
              </div>
            )}
          </Reference>
          {isVisibleKeyOptions && <PixKeysTooltipOptions copyText={handleCopyText} openModalDeletePix={toggleVisibilityDeleteKey} />}
        </Manager>
      </div>
    </>
  )
}

export const MyKeys = ({ toggleOnRegisterKey, company, hasBankAccount }) => {
  const { digitalAccounts } = useListDigitalAccount(company?.id)

  return (
    <>
      <div css={containerDialog}>
        <small css={label}>Gerencie suas chaves para receber transferências pelo Pix.</small>
        <div onClick={toggleOnRegisterKey} css={registerButton}>
          <CircleButton css={flexRowCentered} size={30}>
            <PlusIcon />
          </CircleButton>
          <span css={labelRegisterButton}>{hasBankAccount ? 'Editar chave' : 'Criar chave'}</span>
        </div>
      </div>
      {hasBankAccount &&
        <>
          <div css={divider} />
          <small css={numberKeys}>{digitalAccounts?.length === 1 ? `${digitalAccounts.length} chave` : `${digitalAccounts?.length} chaves`}</small>
          <RegisteredKey
            title={digitalAccounts?.length === 1 ? 'Minha chave' : 'Minhas chaves'}
            digitalAccounts={digitalAccounts}
          />
        </>}
    </>
  )
}
