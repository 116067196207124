/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { H2, flexRow, GhostButton, flexCenteredSpaceBetweenRow } from '@bonitour/components'
import { containerTitle, headerTitle, subtitleStyle } from './PageTitle.styles'

export const PageTitle = ({ title = '', subtitle = '', onClickReturn, ghostButtonTitle = 'Voltar' }) => {
  return (
    <div css={[flexCenteredSpaceBetweenRow, containerTitle]}>
      <div css={flexRow}>
        <H2 css={headerTitle}>{title}</H2>
        {subtitle && <H2 css={subtitleStyle}>{subtitle}</H2>}
      </div>
      {onClickReturn && <GhostButton onClick={onClickReturn}>{ghostButtonTitle}</GhostButton>}
    </div>
  )
}
