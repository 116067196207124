/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { flexColumn, flexHorizontallyCentered, LightTooltip, TrashIcon, colors, CopyIcon } from '@bonitour/components'
import { containerOption, containerTooltip, labelTooltip, iconTooltip, divider, rotate } from './PixKeysTooltipOptions.styles'

export const PixKeysTooltipOptions = ({ copyText, openModalDeletePix }) => {
  const labelTooltipGray = labelTooltip(colors.gray4)
  const labelTooltipRed = labelTooltip(colors.red1)

  const iconTooltipGray = iconTooltip(colors.gray4)
  const iconTooltipRed = iconTooltip(colors.red1)

  return (
    <LightTooltip position='bottom'>
      <div css={[flexColumn, containerTooltip]}>
        <div onClick={copyText} css={[flexHorizontallyCentered, containerOption]}>
          <CopyIcon css={[iconTooltipGray, rotate]} />
          <small css={labelTooltipGray}>Copiar chave</small>
        </div>
        <div css={divider} />
        <div onClick={openModalDeletePix} css={[flexHorizontallyCentered, containerOption]}>
          <TrashIcon css={iconTooltipRed} />
          <small css={labelTooltipRed}>Excluir chave</small>
        </div>
      </div>
    </LightTooltip>
  )
}
