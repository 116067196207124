import { SubordinateModel } from 'Core/Model/Subordinate'
import { SubordinateParser } from 'Core/Parser/Subordinate'
import { OrbCore } from './Orb'

export const SubordinateService = {
  getSubordinate ({ partnerCompanyId }) {
    return OrbCore.subordinateDomain.getSubordinate({ partnerCompanyId }).then(SubordinateParser.signup)
  },
  createSubordinate (form = {}) {
    return OrbCore.subordinateDomain.createSubordinate(SubordinateModel.signup(form))
  }
}
