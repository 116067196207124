// This object was assembled using the links
// https://braspag.github.io//manual/api-ecommerce-cielo-af#tabela-15-payment.fraudanalysis.cart.items[n].giftcategory
// Using the table fourteen

export const fraudAnalysisStatus = {
  0: {
    msg: 'Desconhecido'
  },
  1: {
    msg: 'Transação aceita após análise de fraude.'
  },
  2: {
    msg: 'Transação rejeitada após análise de fraude'
  },
  3: {
    msg: 'Transação em revisão após análise de fraude'
  },
  4: {
    msg: 'Abortado'
  },
  5: {
    msg: 'Transação não finalizada por algum motivo de validação de contrato ou erro interno'
  }
}
