import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const headerTitleStyle = css`
  color: ${colors.gray2};
  margin: 20px 0;
  
`

export const logoCardSize = css`
  width: 30px;
  margin-right: 10px;
`

export const alignCenter = css`
  display: flex;
  align-items: center;
`

export const padding = css`
  padding: 26px;
`

export const eyeIcon = css`
  margin-left: 8px;
  font-size: 20px;
  cursor: pointer;
`

export const wrapperHeaderTotal = css`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  * {
    box-sizing: border-box;
  }

  .wrapper__total {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 0.625rem;
    background-color: ${colors.white};
    padding: 1.25rem 1rem;
    border: 1px solid ${colors.gray8};
    height: 100%;
    white-space: nowrap;
    min-width: 236px;

    .total__label {
      color: ${colors.gray3};
      font-size: 16px;
    }
    .total__number {
      color: ${colors.gray1};
      font-weight: 700;
      font-size: 19px;
    }
  }
  @media(max-width: 450px) {
    .wrapper__header_total {
      flex-direction: column;
    }

    .wrapper__total {
      width: 100%;
    }
  }
`
