export const CompanyConfigParser = {
  single: (companyConfig) => {
    const {
      id,
      max_installments_allowed: maxInstallments
    } = companyConfig.pay_company_configuration ?? {}

    return {
      id,
      maxInstallments
    }
  }
}
