import { css } from '@emotion/core'

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  align-items: center;
`
export const containerIcon = (color) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${color};
  width: 44px;
  height: 44px;
  padding: 6px;
`

export const iconStyle = (color) => css`
  font-size: 38px;
  color: ${color};
  padding: 6px;
`

export const titleStyle = css`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin: 20px 0 20px 0;
`

export const messageStyle = css`
  text-align: justify;
  line-height: 1.2;
`

export const secondMessageStyle = css`
  margin-top: 10px;
`

export const closeButtonStyle = css`
  width: 40%;
  margin-top: 2em;
`
