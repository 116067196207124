/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { jsx } from '@emotion/core'
import { Card, PaginationContainer, useToast, H4 } from '@bonitour/components'
import { DigitalAccountFilter } from './Filter/DigitalAccountFilter'
import { DigitalAccountHeader } from './Header/DigitalAccountHeader'
import { HistoryList } from './List/HistoryList'
import { DialogPixKeys } from './PixArea/PixKeys/DialogPixKeys'
import { DialogTransferPix } from './PixArea/TransferPix/DialogTransferPix'
import { useListMovements } from './Hooks/useListMovements'
import { useListDigitalAccount } from './Hooks/useListDigitalAccount'
import { useCompany } from 'Shared/contexts/Company'
import { head } from '@bonitour/common-functions'
import { BankAccountBalanceProvider } from './Hooks/useGetBankAccountBalance'
import { DigitalAccountProvider } from './Hooks/useDigitalAccount'
import { DialogTransferStatus } from './PixArea/TransferPix/DialogTransferStatus/DialogTransferStatus'

export const DigitalAccount = () => {
  const { push } = useHistory()
  const { company } = useCompany()
  const { add: addToast } = useToast()
  const [isKeysModalVisible, setKeysModalVisible] = useState(false)
  const [sendPixModalVisible, setSendPixModalVisible] = useState(false)
  const [transferStatusModalVisible, setTransferStatusModalVisible] = useState(false)
  const [transferStatusModalData, setTransferStatusModalData] = useState()

  const toggleKeyModalVisible = useCallback(() => setKeysModalVisible(!isKeysModalVisible), [isKeysModalVisible])
  const toggleSendPixModalVisible = useCallback(() => setSendPixModalVisible(!sendPixModalVisible), [sendPixModalVisible])
  const toggleTransferStatusModalVisible = useCallback(() => setTransferStatusModalVisible(!transferStatusModalVisible), [transferStatusModalVisible])

  const titleColumnHistoryListingTable = ['Data da transação', 'Nome do responsável', 'Valor']

  const { digitalAccounts, error } = useListDigitalAccount(company?.id)

  const haveDigitalAccount = !!digitalAccounts
  const hasBankAccount = digitalAccounts?.length > 0 && head(digitalAccounts)?.bankAccountNumber !== ''

  useEffect(() => {
    if (error && !haveDigitalAccount) {
      addToast('Você ainda não possui uma Conta Digital')
      push('/dashboard')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, digitalAccounts])

  const {
    meta,
    handlePagination,
    movementsData,
    setStartDate,
    startDate,
    setEndDate,
    endDate
  } = useListMovements(digitalAccounts && head(digitalAccounts)?.id)

  const { totalMoves, totalPages } = meta ?? {}

  useEffect(() => {
    if (transferStatusModalData) setTransferStatusModalVisible(true)
  }, [transferStatusModalData])

  return (
    <DigitalAccountProvider digitalAccounts={digitalAccounts}>
      <BankAccountBalanceProvider>
        <DialogPixKeys
          isVisible={isKeysModalVisible}
          toggleHidden={toggleKeyModalVisible}
          company={company}
          hasBankAccount={hasBankAccount}
        />

        <DialogTransferPix
          isVisible={sendPixModalVisible}
          toggleHidden={toggleSendPixModalVisible}
          setTransferStatusModalData={setTransferStatusModalData}
        />

        <DialogTransferStatus
          isVisible={transferStatusModalVisible}
          toggleHidden={toggleTransferStatusModalVisible}
          setTransferStatusModalData={setTransferStatusModalData}
          transferStatusModalData={transferStatusModalData}
        />

        <DigitalAccountHeader
          toggleKeyModalVisible={toggleKeyModalVisible}
          toggleSendPixModalVisible={toggleSendPixModalVisible}
          hasBankAccount={hasBankAccount}
        />

        <H4>Histórico</H4>
        <Card>
          <H4>Selecione um período:</H4>
          <DigitalAccountFilter
            setStartDate={setStartDate}
            startDate={startDate}
            setEndDate={setEndDate}
            endDate={endDate}
          />
          <PaginationContainer total={totalMoves} pagesQuantity={totalPages} onPagination={handlePagination}>
            <HistoryList
              titleColumnList={titleColumnHistoryListingTable}
              accountMovements={movementsData.accountMovements}
            />
          </PaginationContainer>
        </Card>
      </BankAccountBalanceProvider>
    </DigitalAccountProvider>
  )
}
