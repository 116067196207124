import { string, ref, number } from 'yup'
import { validateCnpj, validateCpf } from '@bonitour/common-functions'

export const RegisterKeySchema = {
  responsibleName: string().required('Digite o nome do responsável'),
  responsibleDocumentType: string().required('Adicione o tipo do documento do responsável'),
  responsibleDocument: string().required('Preencha o documento do responsável')
    .test('test-validateDoc', 'O documento deve ser válido', function (value) {
      switch (this.resolve(ref('responsibleDocumentType'))) {
      case 'Cpf':
        return validateCpf(value)
      case 'Cnpj':
        return validateCnpj(value)
      default:
        return true
      }
    }),
  bankNumber: number().typeError('O campo deve ser um número').required('Digite o número do banco'),
  bankAgencyNumber: number().typeError('O campo deve ser um número').required('Digite o número da agência'),
  bankAccountNumber: number().typeError('O campo deve ser um número').required('Digite o número da conta'),
  bankAccountType: string().required('Escolha um tipo de conta')
}
