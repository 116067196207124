// This object was assembled using the links
// https://braspag.github.io//manual/api-ecommerce-cielo-af#tabela-15-payment.fraudanalysis.cart.items[n].giftcategory
// Using the table eighteen

// https://braspag.github.io//manual/antifraude#tabela-27-provideranalysisresult.afsreply.internetinfocode
// Using the table twenty-seven

// It was identified that this object includes both tables.

export const internetInfocode = {
  'FREE-EM': {
    msg: 'O endereço de e-mail do comprador é de um provedor de e-mail gratuito'
  },
  'INTL-IPCO': {
    msg: 'O país do endereço de e-mail do comprador está fora dos EUA'
  },
  'INV-EM': {
    msg: 'O endereço de e-mail do comprador é inválido'
  },
  'INTL-SA': {
    msg: 'O país do endereço de entrega está fora dos EUA'
  },
  'MM-EMBCO': {
    msg: 'O domínio do endereço de e-mail do comprador não é consistente com o país do endereço de cobrança'
  },
  'MM-IPBC': {
    msg: 'O endereço de e-mail do comprador não é consistente com a cidade do endereço de cobrança'
  },
  'MM-IPBCO': {
    msg: 'O endereço de e-mail do comprador não é consistente com o país do endereço de cobrança'
  },
  'MM-IPBST': {
    msg: 'O endereço IP do comprador não é consistente com o estado no endereço de cobrança. No entanto, este código de informação não pode ser devolvido quando a inconsistência é entre estados imediatamente adjacentes'
  },
  'MM-IPEM': {
    msg: 'O endereço de e-mail do comprador não é consistente com o endereço IP'
  },
  'RISK-EM': {
    msg: 'O domínio do e-mail do comprador (por exemplo, mail.example.com) está associado com alto risco'
  },
  'UNV-NID': {
    msg: 'O endereço IP do comprador é de um proxy anônimo. Estas entidades escondem completamente informações sobre o endereço de IP'
  },
  'UNV-RISK': {
    msg: 'O endereço IP é de origem de risco'
  },
  'UNV-EMBCO': {
    msg: 'O país do endereço de e-mail não corresponde ao país do endereço de cobrança'
  }
}
