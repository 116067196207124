/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useState } from 'react'
import { Dialog } from '@bonitour/components'
import { MyKeys } from './MyKeys/MyKeys'
import { RegisterKey } from './RegisterKey/RegisterKey'

export const DialogPixKeys = ({ isVisible, toggleHidden, company, hasBankAccount }) => {
  const [onRegisterKey, setOnRegisterKey] = useState(false)

  const toggleOnRegisterKey = () => setOnRegisterKey(!onRegisterKey)

  const onClose = useCallback(() => {
    setOnRegisterKey(false)
    toggleHidden()
  }, [toggleHidden])

  return (
    <Dialog
      title={hasBankAccount && onRegisterKey ? 'Editar chave' : !onRegisterKey ? 'Minhas chaves' : 'Criar chave'}
      isVisible={isVisible}
      onClose={onClose}
    >
      {onRegisterKey ? <RegisterKey toggleOnRegisterKey={toggleOnRegisterKey} company={company} /> : <MyKeys toggleOnRegisterKey={toggleOnRegisterKey} company={company} hasBankAccount={hasBankAccount} />}
    </Dialog>
  )
}
