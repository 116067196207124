/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */

import { css, jsx } from '@emotion/core'
import { Button, Card, CircleThumbnail, colors, ConfirmationDialog, flexCenteredSpaceBetweenRow, GhostButton, H1, H3, Row, useToast } from '@bonitour/components'
import { AccountConfiguration } from './ConfigurationForm/AccountConfiguration'
import { AccountableConfiguration } from './ConfigurationForm/AccountableConfiguration'
import { configurationForm } from './ConfigurationForm/ConfigurationForm.schema'
import { useForm } from '@bonitour/app-functions'
import { useSubordinateConfiguration } from './hooks/useConfiguration'
import { identity } from '@bonitour/common-functions'
import { useHistory } from 'react-router-dom'
import { useMemo } from 'react'

const circleColor = color => css`
  display: inline-flex;
  margin-right: 10px;
  background-color: ${color};
`

const cardContrast = css`
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  background-color: ${colors.gray13};
  border: none;

  h3 {
    margin-bottom: 0;
  }
`

const marginBottom0 = css`
  margin-bottom: 0;
`

const marginBottom20 = css`
  margin-bottom: 20px;
`

export const Configuration = () => {
  const { formPreset, signupSubordinate, isBlocked, isDisabledButton } = useSubordinateConfiguration()
  const { add: addToast } = useToast()
  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(formPreset, configurationForm)
  const { push } = useHistory()

  const backToDashboard = () => push('/dashboard')

  const onClick = onSubmit((form) => {
    if (form?.account?.documentType === 'Cpf' && !form?.accountable?.birthday) {
      return addToast('Preencha a data de nascimento')
    }
    return ConfirmationDialog('Você deseja enviar as suas informações para cadastro? Após o envio elas não poderão ser reenviadas pelo Pay, somente através da Braspag', signupSubordinate, identity)(form)
  }, () => addToast('Preencha as informações corretamente')
  )

  const displayBirthday = useMemo(() => form?.account?.documentType === 'Cpf', [form?.account?.documentType])

  return (
    <>
      <div css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <H1 css={marginBottom0}>Cadastro</H1>
        <GhostButton onClick={backToDashboard}>Voltar</GhostButton>
      </div>
      <Card>
        {isBlocked && (
          <Row>
            <Card css={cardContrast}>
              <H3>
                Status de aprovação: {!form.isBlocked ? <span> <CircleThumbnail size={10} css={circleColor(colors.green1)} />  Aprovado</span> : <span><CircleThumbnail size={10} css={circleColor(colors.red1)} /> Desaprovado</span>}
              </H3>
            </Card>
          </Row>
        )}
        <AccountConfiguration
          data={form.account}
          errors={errors.account}
          isBlocked={isBlocked}
          onChange={onInputChange}
          onBlur={onInputBlur}
        />
        <AccountableConfiguration
          data={form.accountable}
          errors={errors.accountable}
          isBlocked={isBlocked}
          onChange={onInputChange}
          onBlur={onInputBlur}
          displayBirthday={displayBirthday}
        />
        {!isBlocked && (
          <Row>
            <Button disabled={isDisabledButton} onClick={onClick}>Salvar</Button>
          </Row>
        )}
      </Card>
    </>
  )
}
