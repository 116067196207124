export const SubordinateParser = {
  signup (payload = {}) {
    const bankAccount = payload.bank_account
    return {
      isBlocked: payload.blocked,
      account: {
        bank: bankAccount.bank,
        accountType: bankAccount.bank_account_type,
        documentType: bankAccount.document_type === 1 ? 'Cnpj' : 'Cpf',
        document: bankAccount.document_number,
        numberAccount: bankAccount.number,
        verifierNumberAccount: (bankAccount.verifier_digit || '').replace(/\D/gi, ''),
        numberAgency: bankAccount.agency_number,
        verifierNumberAgency: (bankAccount.agency_digit || '').replace(/\D/gi, ''),
        companyCategory: payload.business_activity_id || payload.merchant_category_code
      },
      accountable: {
        mail: payload.contact_email,
        name: payload.contact_name,
        telephone: payload.contact_phone,
        birthday: payload.birthday_date
      }
    }
  }
}
