/** @jsxRuntime classic */
/** @jsx jsx */
/* eslint-disable */
import { jsx } from '@emotion/core'

export const GoogleTagManagerHeader = (googleTagManagerId) => {  
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!=='dataLayer'?'&l='+l:''
    j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f)
  })
  (window, document, 'script', 'dataLayer', googleTagManagerId)
}

export const GoogleTagManagerBody = (googleTagManagerId) => {
  return (
    googleTagManagerId &&
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`}
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
  )
}
