/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors, H4, LogoMenuItem } from '@bonitour/components'
import logoAccounts from 'Shared/svgs/logoBinamik.svg'
import logoOrb from 'Shared/svgs/logoOrb.svg'
import logoXpert from 'Shared/svgs/logoXpert.svg'
import logoBilling from 'Shared/svgs/logoBilling.svg'
import logoMoov from 'Shared/svgs/logoMoov.svg'

const { REACT_APP_ACCOUNTS_URL, REACT_APP_ORB_URL, REACT_APP_XPERT_URL, REACT_APP_BILLING_URL, REACT_APP_MOOV_URL } = process.env

const productsLabel = css`
  margin: 10px 0 5px 0;
  font-size: 12px;
  font-weight: 500;
  color: ${colors.gray5};
  align-self: flex-start;
`

const logoItem = css`
  margin: 10px 0 0 0;
  text-decoration: none;
  width: 140px;
  cursor: pointer;
  justify-content: flex-end;
  padding: 10px 20px 10px 10px;
  &.binamik-logo {
    img {
      width: 100px;
    }
  }
`

const alignLeft = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const redirectOnClick = link => () => {
  window.location = link
}

export const Products = () => {
  return (
    <div css={alignLeft}>
      <H4 css={productsLabel}>Produtos</H4>
      <LogoMenuItem css={logoItem} onClick={redirectOnClick(`${REACT_APP_ACCOUNTS_URL}/app`)} logoSrc={logoAccounts} className='binamik-logo' />
      <LogoMenuItem css={logoItem} onClick={redirectOnClick(REACT_APP_ORB_URL)} logoSrc={logoOrb} style={{ paddingRight: '18px' }} />
      <LogoMenuItem css={logoItem} onClick={redirectOnClick(REACT_APP_XPERT_URL)} logoSrc={logoXpert} style={{ paddingRight: '21px' }} />
      <LogoMenuItem css={logoItem} onClick={redirectOnClick(REACT_APP_BILLING_URL)} logoSrc={logoBilling} style={{ paddingRight: '21px' }} />
      <LogoMenuItem css={logoItem} onClick={redirectOnClick(REACT_APP_MOOV_URL)} logoSrc={logoMoov} />
    </div>
  )
}
