import { factorCode } from 'Shared/braspagMetaTables/factorCode'
import { addressInfoCode } from 'Shared/braspagMetaTables/addressInfoCode'
import { reasonCodeAndProviderCode } from 'Shared/braspagMetaTables/fraudAnalysisReasonCodeAndAnalysisResultProviderCode'
import { identityInfoCode } from 'Shared/braspagMetaTables/identityInfoCode'
import { internetInfocode } from 'Shared/braspagMetaTables/internetInfoCode'
import { hotListInfoCode } from 'Shared/braspagMetaTables/hotListInfoCode'
import { velocityInfoCode } from 'Shared/braspagMetaTables/velocityInfoCode'
import { fraudAnalysisStatus } from 'Shared/braspagMetaTables/fraudAnalysisStatus'
import { paymentStatus } from 'Shared/braspagMetaTables/paymentStatus'
import { paymentReturnCode, paymentReturnCodeOthersCodes } from 'Shared/braspagMetaTables/paymentReturnCode'

const mappingReturnValues = ({ values, dictValues = {} }) => {
  if (Array.isArray(values)) {
    if (!values?.length) return undefined

    return values.map(value => {
      return {
        code: value,
        msg: dictValues?.[value]?.msg || value,
        possibleAction: dictValues?.[value]?.possibleAction
      }
    })
  }

  if (!values) return undefined

  return [{
    code: values,
    msg: dictValues?.[values]?.msg || values,
    possibleAction: dictValues?.[values]?.possibleAction
  }]
}

const payment = {
  payment_id: {
    label: 'ID interno'
  },
  payment_client_info: {
    label: 'Cliente'
  },
  payment_status: {
    label: 'Status',
    mapping: (values) => mappingReturnValues({ values, dictValues: paymentStatus })
  },
  payment_return_message: {
    label: 'Mensagem (return_menssage)'
  },
  payment_return_code: {
    label: 'Código retornado (return_code)',
    mapping: (value = '', brandCard = '') => {
      if (!brandCard || !value) return undefined

      const parseValue = !isNaN(value) && typeof value === 'string' ? parseInt(value, 10) : value // Removal of leading zeros
      const valueString = parseValue.toString()

      const brandCardLowerCase = brandCard.toLowerCase()

      if (
        (!paymentReturnCode?.[brandCardLowerCase]?.[valueString]?.msg ||
        !paymentReturnCode?.[brandCardLowerCase]?.[valueString]?.msg_to_client) &&
        (!paymentReturnCodeOthersCodes?.[valueString]?.msg ||
        !paymentReturnCodeOthersCodes?.[valueString]?.msg_to_client)
      ) {
        return [valueString]
      }

      return [{
        code: valueString,
        msg: paymentReturnCode?.[brandCardLowerCase]?.[valueString]?.msg || paymentReturnCodeOthersCodes?.[valueString]?.msg,
        msg_to_client: paymentReturnCode?.[brandCardLowerCase]?.[valueString]?.msg_to_client || paymentReturnCodeOthersCodes?.[valueString]?.msg_to_client
      }]
    }
  },
  payment_split_errors: {
    label: 'Erros divididos (split_errors)',
    mapping: (values) => {
      if (typeof values === 'object') {
        if (Array.isArray(values)) {
          return values.map(entry => {
            if (typeof entry === 'object') {
              return Object.values(entry).join(' | ')
            }
            return entry
          }).join(', ')
        }
        return Object.values(values).join(', ')
      }
      return values
    }
  }
}

const antiFraud = {
  fraud_analysis_status: {
    label: 'Status',
    mapping: (status) => mappingReturnValues({ values: status, dictValues: fraudAnalysisStatus })
  },
  fraud_analysis_score: {
    label: 'Pontuação (score)'
  },
  fraud_analysis_reason_code: {
    label: 'Código da razão (reason_code)',
    mapping: (values) => mappingReturnValues({ values, dictValues: reasonCodeAndProviderCode })
  },
  fraud_analysis_factor_code: {
    label: 'Código do fator (factor_code)',
    mapping: (values) => mappingReturnValues({ values, dictValues: factorCode })
  },
  fraud_analysis_address_info_code: {
    label: 'Código de informação de endereço (address_info_code)',
    mapping: (values) => mappingReturnValues({ values, dictValues: addressInfoCode })
  },
  fraud_analysis_identity_info_code: {
    label: 'Código de informação de identidade (identity_info_code)',
    mapping: (values) => mappingReturnValues({ values, dictValues: identityInfoCode })
  },
  fraud_analysis_velocity_info_code: {
    label: 'Código de informação de velocidade (velocity_info_code)',
    mapping: (values) => mappingReturnValues({ values, dictValues: velocityInfoCode })
  },
  fraud_analysis_hot_list_info_code: {
    label: 'Código de informação da lista de favoritos (hot_list_info_code)',
    mapping: (values) => mappingReturnValues({ values, dictValues: hotListInfoCode })
  },
  fraud_analysis_internet_info_code: {
    label: 'Código de informação da internet (intenert_info_code)',
    mapping: (values) => mappingReturnValues({ values, dictValues: internetInfocode })
  },
  fraud_return_message: {
    label: 'Menssagem do antifraude'
  }
}

export const DetailsTransactionParser = {
  translationReturnDetailsTransaction: (
    {
      braspagMeta,
      externalPaymentId,
      clientInfo,
      brandCard,
      returnMessageFraud,
      returnCode
    }
  ) => {
    const newFraudDetails = {
      ...braspagMeta
    }

    const newPaymentDetails = {
      ...braspagMeta,
      payment_client_info: clientInfo,
      payment_id: externalPaymentId
    }

    if (!braspagMeta) {
      newPaymentDetails.payment_return_code = returnCode
      newFraudDetails.fraud_return_message = returnMessageFraud
    }

    const fraudDetails = Object.keys(newFraudDetails)
      .filter(key => key.startsWith('fraud'))
      .sort((a, b) => {
        const fields = [
          'fraud_analysis_address_info_code', 'fraud_analysis_identity_info_code',
          'fraud_analysis_velocity_info_code', 'fraud_analysis_hot_list_info_code',
          'fraud_analysis_internet_info_code', 'fraud_analysis_factor_code'
        ]

        if (fields.includes(a)) return 1
        if (fields.includes(b)) return -1
        return 0
      })
      .filter(key => {
        return ![
          'fraud_analysis_status_description'
        ].includes(key)
      })
      .map((key) => {
        const value = newFraudDetails[key]
        return {
          key,
          label: antiFraud[key]?.label,
          value: (antiFraud[key]?.mapping && antiFraud[key]?.mapping(value)) ?? value
        }
      })
      .filter(obj => !!obj.value)

    const paymentDetails = Object.keys(newPaymentDetails)
      .filter(key => key.startsWith('payment'))
      .filter(key => {
        return ![
          'payment_authorization_code',
          'payment_proof_of_sale'
        ].includes(key)
      })
      .sort((a, b) => {
        const fieldsAlignedToEnd = [
          'payment_split_errors', 'payment_return_code'
        ]
        if (fieldsAlignedToEnd.includes(a)) return 1
        if (fieldsAlignedToEnd.includes(b)) return -1

        const fieldsAlignedToStart = [
          'payment_id',
          'payment_client_info'
        ]
        if (fieldsAlignedToStart.includes(a) && fieldsAlignedToStart.includes(b)) {
          return a === fieldsAlignedToStart ? -1 : 1
        }
        if (fieldsAlignedToStart.includes(a)) return -1
        if (fieldsAlignedToStart.includes(b)) return 1

        return 0
      })
      .map((key) => {
        const value = newPaymentDetails[key]

        const res = {
          key,
          label: payment[key]?.label
        }

        if (key === 'payment_return_code') {
          res.value = (payment[key]?.mapping && payment[key]?.mapping(value, brandCard)) ?? value
        } else {
          res.value = (payment[key]?.mapping && payment[key]?.mapping(value)) ?? value
        }
        return res
      })
      .filter(obj => !!obj.value)

    return {
      payment: paymentDetails,
      antiFraud: fraudDetails
    }
  }
}
