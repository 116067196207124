import { validateCnpj, validateCpf, validatePhone } from '@bonitour/common-functions'
import { object, ref, string, date, number } from 'yup'

export const configurationForm = {
  account: object().shape(
    {
      bank: string().required('O banco é obrigatório'),
      accountType: string().required('Adicione o tipo de conta'),
      documentType: string().required('Adicione o documento'),
      document: string().required('Adicione o documento')
        .test('test-validateDoc', 'Documento deve ser válido', function (value) {
          switch (this.resolve(ref('documentType'))) {
          case 'Cpf':
            return validateCpf(value)
          case 'Cnpj':
            return validateCnpj(value)
          default:
            return true
          }
        }),
      companyCategory: number().optional().typeError('Deve ser um número')
        .test('test-validateCategory', 'Categoria deve ser válida', function (value) {
          switch (this.resolve(ref('documentType'))) {
          case 'Cpf':
            return Number.isInteger(value) && value > 0 && value < 100
          case 'Cnpj':
          default:
            return true
          }
        }),
      numberAccount: number().typeError('Deve ser um número').min(0).max(9999999999).required('Adicione o número da conta'),
      verifierNumberAccount: number().typeError('Deve ser um número').min(0).max(9).required('Adicione o dígito da conta'),
      numberAgency: number().typeError('Deve ser um número').min(0).max(9999).required('Adicione o número da agência'),
      verifierNumberAgency: number().typeError('Deve ser um número').min(0).max(9)
    }
  ).required(),
  accountable: object().shape(
    {
      name: string().required('Nome do responsável é obrigatório'),
      telephone: string().required('Adicione o telefone').test('test-phone', 'Telefone deve ser válido', validatePhone),
      mail: string().email('O e-mail deve ser válido').required('Adicione o e-mail'),
      birthday: date().optional()
    }
  ).required()
}
