import { useState } from 'react'
import { companyConfigService } from 'Core/Service/CompanyConfig'
import { useToast } from '@bonitour/components'

export const useCreateCompanyConfig = () => {
  const [loading, setLoading] = useState(false)
  const { add: addToast } = useToast()

  const createCompanyConfig = async (data) => {
    setLoading(true)
    return companyConfigService.create(data)
      .then(() => {
        setLoading(false)
        addToast('Configuração salva!', 'success')
      })
      .catch(() => addToast('Erro ao salvar a configuração', 'error'))
  }
  return {
    onCreateCompanyConfig: createCompanyConfig,
    loading
  }
}
