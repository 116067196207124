/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useMemo } from 'react'
import { maskMoney } from '@bonitour/common-functions'
import { Button, flexColumn, colors } from '@bonitour/components'
import { labelData, editButton, pixValueStyle, containerPixValue, marginTop, marginBottom } from './ConfirmTransfer.styles'

const labelDataGray = labelData(colors.gray5)
const labelDataDarkGray = labelData(colors.gray3)

const marginTop5 = marginTop(5)
const marginTop20 = marginTop(20)
const marginTop40 = marginTop(40)

const AccountData = ({ title, data }) => {
  return (
    <div css={[flexColumn, marginBottom]}>
      <span css={labelDataDarkGray}>{title}</span>
      <small css={[labelDataGray, marginTop5]}>{data}</small>
    </div>
  )
}

export const ConfirmTransfer = ({
  value,
  digitalAccounts,
  digitalAccountId,
  onEdit: emitEdit,
  onSubmit: emitSubmit
}) => {
  const digitalAccountSelected = useMemo(() => digitalAccounts.find(({ id }) => id === digitalAccountId), [digitalAccounts, digitalAccountId])
  const { bankNumber, bankAgencyNumber, bankAccountNumber, bankAccountType } = digitalAccountSelected || {}

  return (
    <div css={flexColumn}>
      <div css={containerPixValue}>
        <span css={pixValueStyle}>{maskMoney(value)}</span>
        <span css={editButton} onClick={emitEdit}>Editar</span>
      </div>
      <div css={marginTop20}>
        <AccountData title='Quando' data='Agora' />
      </div>
      <div css={marginTop20}>
        <AccountData title='Número do banco' data={bankNumber} />
        <AccountData title='Agência' data={bankAgencyNumber} />
        <AccountData title='Conta' data={bankAccountNumber} />
        <AccountData title='Tipo de conta' data={bankAccountType === 'CHECKING' ? 'Conta corrente' : 'Poupança'} />
      </div>
      <div css={marginTop40}>
        <Button onClick={emitSubmit}>Transferir</Button>
      </div>
    </div>
  )
}
