export const MetaParser = {
  meta (
    {
      current_page: page,
      total_entries: totalEntries,
      total_pages: totalPages,
      total
    }
  ) {
    return {
      page: page || 0,
      totalEntries: totalEntries || 0,
      totalPages: totalPages || 0,
      total: total || 0
    }
  }
}
