import { DigitalAccountModel } from 'Core/Model/DigitalAccount'
import { DigitalAccountsApi } from 'Core/Api/DigitalAccount'
import { DigitalAccountParser } from 'Core/Parser/DigitalAccount'
import { RegisterKeyModel } from 'Core/Model/RegisterKey'
import { extractData } from './Pay'

export const DigitalAccountsService = {
  search (filters) {
    return DigitalAccountsApi.search(DigitalAccountModel.search(filters)).then(extractData).then(DigitalAccountParser.list)
  },
  create (payload) {
    return DigitalAccountsApi.create(DigitalAccountModel.create(payload)).then(extractData).then(DigitalAccountParser.create)
  },
  pixWithdrawal (digitalAccountId, payload) {
    return DigitalAccountsApi.pixWithdrawal(digitalAccountId, DigitalAccountModel.pixWithdrawal(payload))
  },
  getBalance (digitalAccountId) {
    return DigitalAccountsApi.getBalance(digitalAccountId).then(extractData).then(DigitalAccountParser.getBalance)
  },
  editKey (payload, keyId) {
    return DigitalAccountsApi.editKey(RegisterKeyModel.edit(payload), keyId)
  },
  deleteKey (payload, keyId) {
    return DigitalAccountsApi.editKey(RegisterKeyModel.delete(payload), keyId)
  }
}
