/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useRef } from 'react'
import { jsx } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import { formatMoney } from '@bonitour/common-functions'
import { useClickOutside } from '@bonitour/app-functions'
import { CardButton } from 'Domains/Dashboard/TitleMenus/CardButton'
import { PixAreaTooltipOptions } from './Tooltips/PixAreaTooltipOptions/PixAreaTooltipOptions'
import { DigitalAccountTooltipOptions } from './Tooltips/DigitalAccountTooltipOptions/DigitalAccountTooltipOptions'
import {
  BankCardIcon,
  flexRowCentered,
  flexRow,
  Label,
  flexCenteredSpaceBetweenRow,
  Card,
  Manager,
  Reference,
  flexColumn,
  EllipsisIcon
} from '@bonitour/components'
import {
  cardIcon,
  labelCardButton,
  cardButtonStyle,
  bankData,
  cardPriceStyle,
  textCardPrice,
  cardTransferableBalance,
  cardWithheldBalance,
  ellipsisIconStyle,
  containerEllipsisIconStyle,
  balanceBox
} from './DigitalAccountHeader.styles'
import { useBankAccountBalance } from '../Hooks/useGetBankAccountBalance'
import { useDigitalAccount } from '../Hooks/useDigitalAccount'

export const DigitalAccountHeader = ({
  toggleKeyModalVisible,
  toggleSendPixModalVisible,
  hasBankAccount
}) => {
  const { digitalAccount } = useDigitalAccount()
  const { push } = useHistory()
  const referencePix = useRef()
  const referenceTooltip = useRef()
  const [isVisiblePixArea, setVisibilityPixArea] = useClickOutside(referencePix)
  const [isVisibleTooltip, setVisibilityTooltip] = useClickOutside(referenceTooltip)

  const { bankAccountBalance } = useBankAccountBalance()

  const toggleVisibilityTooltip = () => setVisibilityTooltip(!isVisibleTooltip)

  const toggleVisibilityPixArea = () => setVisibilityPixArea(!isVisiblePixArea)

  const navigateToDashboard = () => {
    setVisibilityTooltip(false)
    push('/dashboard')
  }

  return (
    <>
      <div css={flexCenteredSpaceBetweenRow}>
        <div ref={referencePix} css={flexRow}>
          <Manager>
            <Reference>
              {({ ref }) => (
                <div ref={ref} onClick={toggleVisibilityPixArea}>
                  <CardButton css={cardButtonStyle}>
                    <BankCardIcon css={cardIcon} />
                    <Label css={labelCardButton}>Área Pix</Label>
                  </CardButton>
                </div>
              )}
            </Reference>
            {isVisiblePixArea &&
              <PixAreaTooltipOptions
                bankAccountBalance={bankAccountBalance}
                hasBankAccount={hasBankAccount}
                toggleSendPixModalVisible={toggleSendPixModalVisible}
                toggleKeyModalVisible={toggleKeyModalVisible}
              />}
          </Manager>
        </div>
        <div ref={referenceTooltip} css={flexRowCentered}>
          <small css={bankData}>Agência: {digitalAccount?.bankAgencyNumber}</small>
          <small css={bankData}>Conta: {digitalAccount?.bankAccountNumber}</small>
          <small css={bankData}>Banco: {digitalAccount?.bankNumber}</small>
          <Manager>
            <Reference>
              {({ ref }) => (
                <div ref={ref}>
                  <div onClick={toggleVisibilityTooltip} css={containerEllipsisIconStyle}>
                    <EllipsisIcon css={ellipsisIconStyle} />
                  </div>
                </div>
              )}
            </Reference>
            {isVisibleTooltip && <DigitalAccountTooltipOptions onClickExit={navigateToDashboard} />}
          </Manager>
        </div>
      </div>
      <div css={[flexRow, balanceBox]}>
        <Card css={[flexColumn, cardPriceStyle]}>
          <Label
            css={textCardPrice}
            htmlFor='text'
          >
            Saldo disponível
          </Label>
          <Label
            css={cardTransferableBalance}
            htmlFor='value'
          >
            {bankAccountBalance ? formatMoney(bankAccountBalance?.transferableBalance) : '-'}
          </Label>
        </Card>
        <Card css={[flexColumn, cardPriceStyle]}>
          <Label
            css={textCardPrice}
            htmlFor='text'
          >
            Lançamentos futuros
          </Label>
          <Label
            css={cardWithheldBalance}
            htmlFor='value'
          >
            {bankAccountBalance ? formatMoney(bankAccountBalance?.withheldBalance) : '-'}
          </Label>
        </Card>
      </div>
    </>
  )
}
