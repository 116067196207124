/* eslint-disable react/jsx-closing-tag-location */

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useEffect } from 'react'
import { jsx, css } from '@emotion/core'
import { useCreateDigitalAccount } from '../Hooks/useCreateDigitalAccount'
import { LoadingAnimation } from '@bonitour/components'
import { useListDigitalAccount } from '../Hooks/useListDigitalAccount'
import { useCompany } from 'Shared/contexts/Company'
import { useHistory } from 'react-router-dom'

const loadingStyle = css`
  display: flex;
  align-self: center;
  margin-top: 10vh;
`

export const NewCreateDigitalAccount = () => {
  const { push } = useHistory()
  const { id: companyId } = useCompany()
  const { digitalAccounts } = useListDigitalAccount(companyId, true)
  const haveDigitalAccount = !!digitalAccounts
  const { linkData } = useCreateDigitalAccount()

  useEffect(() => {
    if (haveDigitalAccount) push('/digital-account')
    else linkData?.url && window.location.replace(linkData.url)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [haveDigitalAccount, linkData?.url])

  return !linkData?.url && <LoadingAnimation css={loadingStyle} />
}
