/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useEffect, useCallback, useContext, createContext } from 'react'
import { useToast } from '@bonitour/components'
import { DigitalAccountsService } from 'Core/Service/DigitalAccounts'
import { useDigitalAccount } from './useDigitalAccount'

const BankAccountBalanceContext = createContext()

export const BankAccountBalanceProvider = ({ children }) => {
  const { digitalAccount } = useDigitalAccount()
  const { add: addToast } = useToast()
  const [bankAccountBalance, setBankAccountBalance] = useState()

  const fetchBankAccountBalanceData = useCallback((digitalAccountId) => {
    return DigitalAccountsService.getBalance(digitalAccountId)
      .then((data) => {
        setBankAccountBalance(data)
      }).catch(() => {
        addToast('Erro ao carregar as contas')
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    digitalAccount?.id && fetchBankAccountBalanceData(digitalAccount?.id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBankAccountBalanceData, digitalAccount?.id])

  const update = useCallback(
    () => fetchBankAccountBalanceData(digitalAccount?.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [digitalAccount?.id, fetchBankAccountBalanceData]
  )

  return (
    <BankAccountBalanceContext.Provider
      value={{ bankAccountBalance, update }}
    >
      {children}
    </BankAccountBalanceContext.Provider>
  )
}

export const useBankAccountBalance = () => {
  const context = useContext(BankAccountBalanceContext)

  if (!context) {
    throw new Error('useBankAccountBalance must be used within an BankAccountBalanceProvider')
  }

  return context
}
