// This object was assembled using the links
// https://braspag.github.io//manual/api-ecommerce-cielo-af#tabela-15-payment.fraudanalysis.cart.items[n].giftcategory
// Using the table seventeen

// https://braspag.github.io//manual/antifraude#tabela-27-provideranalysisresult.afsreply.internetinfocode
// Using the table twenty three

// It was identified that this object includes both tables.

export const factorCode = {
  A: {
    msg: 'Mudança de endereço excessiva. O comprador mudou o endereço de cobrança duas ou mais vezes nos últimos seis meses'
  },
  B: {
    msg: 'BIN do cartão ou autorização de risco. Os fatores de risco estão relacionados com BIN de cartão de crédito e/ou verificações de autorização do cartão'
  },
  C: {
    msg: 'Elevado números de cartões de créditos. O comprador tem usado mais de seis números de cartões de créditos nos últimos seis meses'
  },
  D: {
    msg: 'Impacto do endereço de e-mail. O comprador usa um provedor de e-mail gratuito ou o endereço de email é arriscado'
  },
  E: {
    msg: 'Lista positiva. O comprador está na sua lista positiva'
  },
  F: {
    msg: 'Lista negativa. O número da conta, endereço, endereço de e-mail ou endereço IP para este fim aparece sua lista negativa'
  },
  G: {
    msg: 'Inconsistências de geolocalização. O domínio do comprador de e-mail, número de telefone, endereço de cobrança, endereço de envio ou endereço IP é suspeito'
  },
  H: {
    msg: 'Excessivas mudanças de nome. O comprador mudou o nome duas ou mais vezes nos últimos seis meses'
  },
  I: {
    msg: 'Inconsistências de internet. O endereço IP e de domínio de e-mail não são consistentes com o endereço de cobrança'
  },
  N: {
    msg: 'Entrada sem sentido. O nome do comprador e os campos de endereço contém palavras sem sentido ou idioma'
  },
  O: {
    msg: 'Obscenidades. Dados do comprador contém palavras obscenas'
  },
  P: {
    msg: 'Identidade morphing. Vários valores de um elemento de identidade estão ligados a um valor de um elemento de identidade diferentes. Por exemplo, vários números de telefones estão ligados a um número de conta única'
  },
  Q: {
    msg: 'Inconsistências do telefone. O número de telefone do comprador é suspeito'
  },
  R: {
    msg: 'Ordem arriscada. A transação, o comprador e o lojista mostram informações correlacionadas de alto risco'
  },
  T: {
    msg: 'Cobertura Time. O comprador está a tentar uma compra fora do horário esperado'
  },
  U: {
    msg: 'Endereço não verificável. O endereço de cobrança ou de entrega não pode ser verificado'
  },
  V: {
    msg: 'O cartão foi usado muitas vezes nos últimos 15 minutos'
  },
  W: {
    msg: 'Marcado como suspeito. O endereço de cobrança ou de entrega é semelhante a um endereço previamente marcado como suspeito'
  },
  Y: {
    msg: 'O endereço, cidade, estado ou país dos endereços de cobrança e entrega não se correlacionam'
  },
  Z: {
    msg: 'Valor inválido. Como a solicitação contém um valor inesperado, um valor padrão foi substituído. Embora a transação ainda possa ser processada, examinar o pedido com cuidado para detectar anomalias'
  }
}
