import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const cardPriceStyle = css`
  background-color: ${colors.white};
  width: 50%;
  padding: 16px;
  margin-right: 20px;
`

export const textCardPrice = css`
  font-size: 15px;
  color: ${colors.gray4};
`

export const balanceBox = css`
  margin: 20px 0;
  width: 50%;
`

export const cardTransferableBalance = css`
  font-size: 22px;
  color: ${colors.primary};
  font-weight: 500;
`

export const cardWithheldBalance = css`
  font-size: 22px;
  color: ${colors.gray3};
  font-weight: 500;
`

export const cardButtonStyle = css`
  padding: 12px 0;
`

export const labelCardButton = css`
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
`
export const cardIcon = css`
  font-size: 24px;
  border-radius: 50%;
`

export const bankData = css`
  font-size: 14px;
  color: ${colors.gray4};
  font-weight: normal;
  margin-right: 1vw;
`

export const ellipsisIconStyle = css`
  font-size: 24px;
  border-radius: 50%;
  color: ${colors.gray4};
`

export const containerEllipsisIconStyle = css`
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  
  &:hover {
    background-color: ${colors.white};
    opacity: 0.7;
    transition: 0.4s;
  }
`
