import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const valueStyle = (color) => css`
  color: ${color};
`

export const cellIconStyle = css`
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
`

export const containerArrowIconStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    color: ${colors.white};
    background-color: ${colors.primary};
  }
`
