/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Button, Select } from '@bonitour/components'

const selectStyle = css`
  margin: 1em 0 2em 0;
  width: 92%;
`

export const TransferTo = ({ digitalAccounts = [], digitalAccountId, setDigitalAccountId, nextStep }) => {
  const actionNextStep = () => nextStep()
  const isNextButtonDisabled = !!digitalAccountId

  const options = digitalAccounts && digitalAccounts.map(({ id, bankNumber, bankAgencyNumber, bankAccountNumber, bankAccountType }) =>
    ({ value: id, label: `Número do banco: ${bankNumber} - Agência: ${bankAgencyNumber} - Conta: ${bankAccountNumber} - Tipo de conta: ${bankAccountType === 'CHECKING' ? 'Conta corrente' : 'Poupança'}` }))

  return (
    <div>
      <Select css={selectStyle} value={digitalAccountId} placeholder='Selecione uma conta' options={options} onChange={setDigitalAccountId} />
      <Button onClick={actionNextStep} disabled={isNextButtonDisabled}>Avançar</Button>
    </div>
  )
}
