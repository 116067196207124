export const DigitalAccountParser = {
  single: (digitalAccount) => {
    const {
      id,
      bank_account: bankAccount
    } = digitalAccount ?? {}

    return {
      id,
      responsibleName: bankAccount ? bankAccount.name : '',
      responsibleDocument: bankAccount ? bankAccount?.document : '',
      bankNumber: bankAccount ? bankAccount?.bank_number : '',
      bankAgencyNumber: bankAccount ? bankAccount?.agency_number : '',
      bankAccountNumber: bankAccount ? bankAccount?.account_number : '',
      bankAccountType: bankAccount ? bankAccount?.account_type : ''
    }
  },
  list: (digitalAccounts) => {
    return {
      digitalAccounts: digitalAccounts.map(DigitalAccountParser.single)
    }
  },
  create: (payload) => {
    return payload
  },
  getBalance: (bankAccountBalance) => {
    const {
      balance,
      transferableBalance,
      withheldBalance
    } = bankAccountBalance ?? {}

    return {
      balance,
      transferableBalance,
      withheldBalance
    }
  }
}
