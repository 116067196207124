import { date, ref, string } from 'yup'

export const TransactionFilterSchema = {
  initialDate: date()
    .max(ref('finalDate'), 'A data inicial deve ser menor ou igual a data final'),
  finalDate: date()
    .min(ref('initialDate'), 'A data final deve ser maior ou igual a data inicial'),
  reservation: string(),
  aut: string(),
  status: string()
}
