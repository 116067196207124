import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const cardColor = css`
  background-color: ${colors.gray12};
`

export const width100 = css`
  width: 100%;
`

export const advancedInput = css`
  width: 100%;
  border-radius: 40px;
  height: 40px;
  background-color: ${colors.gray11};
  border: 1px solid ${colors.gray8};
  display: flex;
  align-items: center;
`

export const paddingLeft = (left) => css`
  padding-left: ${left}px;
`

export const labelColor = css`
  color: ${colors.gray6};
`
