/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useContext, createContext } from 'react'
import { head } from '@bonitour/common-functions'

const DigitalAccountContext = createContext()

export const DigitalAccountProvider = ({ digitalAccounts, children }) => {
  const digitalAccount = digitalAccounts && head(digitalAccounts)

  return (
    <DigitalAccountContext.Provider
      value={{ digitalAccounts, digitalAccount }}
    >
      {children}
    </DigitalAccountContext.Provider>
  )
}

export const useDigitalAccount = () => {
  const context = useContext(DigitalAccountContext)

  if (!context) {
    throw new Error('useDigitalAccount must be used within an DigitalAccountProvider')
  }

  return context
}
