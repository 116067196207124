import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TransactionService } from 'Core/Service/Transaction'

export const useGetTransaction = () => {
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const [transaction, setTransaction] = useState()

  useEffect(() => {
    setLoading(true)
    TransactionService.getById(id).then(setTransaction).finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    loading,
    transaction
  }
}
