/** @jsxRuntime classic */
/** @jsx jsx */
import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const cardIcon = css`
  font-size: 20px;
  padding: 4px 5px;
  border-radius: 50%;
`

export const labelCardButton = css`
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
`

export const angleDownStyle = css`
  font-size: 14px;
`

export const linkColor = css`
  color: ${colors.gray1};
  padding: 5px;
  margin: 5px 0;
`

export const cardItems = css`
  min-width: 120px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
`

export const cardItemIcon = css`
  min-height: 28px;
`

export const resetLink = css`
  text-decoration: none;
`
export const margin = css`
  margin: 0px;
`

export const marginBottom = margin => css`
  margin-bottom: ${margin}px;
`

export const plusIconBackground = css`
  background-color: ${colors.primary};
  color: ${colors.white};
  border-radius: 50%;
  width: 14px;
  font-size: 16px;
  margin-top: 4px;
`
