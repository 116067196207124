/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback } from 'react'
import { jsx } from '@emotion/core'
import { useHistory } from 'react-router'
import { formatMoney, formatDate } from '@bonitour/common-functions'
import { getCardBrand, getStatus, returnPayment } from '../utils/utils'
import { headerTitleStyle, alignCenter, padding, eyeIcon, wrapperHeaderTotal } from './TransactionList.styles'
import {
  H4,
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  flexRow,
  EyeIcon
} from '@bonitour/components'

const TransactionListingTableHeader = ({ columns }) => (
  <TableHeader>
    <TableHeaderRow>
      {columns.map((item) => <TableHeaderCell key={item}>{item}</TableHeaderCell>)}
    </TableHeaderRow>
  </TableHeader>
)

const TransactionListingTableBody = ({ data, loading, toggleModal, setModalData }) => {
  const history = useHistory()

  const defineInstallments = (installments) => installments > 0 ? ` (${installments})` : ''

  const openModal = useCallback((data) => {
    setModalData(data)
    toggleModal()
  }, [setModalData, toggleModal])

  const navigateToEntry = (id) => history.push(`transaction/entry/${id}`)

  return (
    <TableBody loading={loading}>
      {data.map((item, idx) => (
        <TableBodyRow key={idx}>
          <TableBodyCell>{formatDate(item.createdAt) || '-'}</TableBodyCell>
          <TableBodyCell>{item.aut || '-'}</TableBodyCell>
          <TableBodyCell>{item.doc || '-'}</TableBodyCell>
          <TableBodyCell>{item.reservationCode || '-'}</TableBodyCell>
          <TableBodyCell css={[flexRow, alignCenter, padding]}>
            {getCardBrand(item.brandCard)}
            {returnPayment[item.paymentMethod]}
            {defineInstallments(item.installments)}
          </TableBodyCell>
          <TableBodyCell>{formatMoney(item.amount) || '-'}</TableBodyCell>
          <TableBodyCell>{item.isMultiponto ? 'Sim' : 'Não'}</TableBodyCell>
          <TableBodyCell>
            {(item?.status && getStatus(item.status)) ? getStatus(item.status, () => openModal(item)) : '-'}
          </TableBodyCell>
          <TableBodyCell>
            <EyeIcon onClick={() => navigateToEntry(item.externalPaymentId)} css={eyeIcon} />
          </TableBodyCell>
        </TableBodyRow>
      ))}
    </TableBody>
  )
}

export const TransactionList = (
  {
    titleColumnList = [],
    transactions = [],
    loading,
    toggleModal,
    setModalData,
    meta
  }
) => {
  return (
    <>
      <H4 css={headerTitleStyle}>Resultado de pesquisa</H4>
      <div css={wrapperHeaderTotal}>
        <div className='wrapper__total'>
          <p className='total__label'>Total</p>
          <p className='total__number'>
            {formatMoney(meta?.total)}
          </p>
        </div>
      </div>
      <TableContainer>
        <col style={{ width: '10%' }}/>
        <col style={{ width: '8%' }}/>
        <col style={{ width: '8%' }}/>
        <col style={{ width: '20%' }}/>
        <col style={{ width: '15%' }}/>
        <col style={{ width: '15%' }}/>
        <col style={{ width: '8%' }}/>
        <col style={{ width: '10%' }}/>
        <col style={{ width: '6%' }}/>
        <TransactionListingTableHeader columns={titleColumnList} />
        <TransactionListingTableBody
          data={transactions}
          loading={loading}
          toggleModal={toggleModal}
          setModalData={setModalData}
        />
      </TableContainer>
    </>
  )
}
