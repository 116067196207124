/** @jsxRuntime classic */
/** @jsx jsx */
import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const select = css`
  size: 5px;
  margin: 0 10px;
  width: 105px;
  margin-top: 10px;
`

export const icon = css`
  font-size: 14px;
  padding: 7px;
  border-radius: 50%;
  margin-right: 9px;
  background-color: ${colors.gray6};
  color: ${colors.gray14};
`

export const firstRow = css`
  font-size: 13px;
  color: ${colors.gray4};
`

export const secondRow = css`
  font-size: 13px;
  color: ${colors.gray4};
  border: 1px solid ${colors.gray7};
  border-radius: 10px;
  padding: 12px;
  margin-top: 23px;
  width: 100%;
  line-height: 21px;
`

export const marginTop40 = css`
  margin-top: 40px;
`

export const marginBottom0 = css`
  margin-bottom: 0;
`

export const marginBottom20 = css`
  margin-bottom: 20px;
`
