import { payCore } from './Pay'
import { TransactionModel } from 'Core/Model/Transaction'
import { TransactionParser } from 'Core/Parser/Transaction'

const { paymentDomain } = payCore

export const TransactionService = {
  list ({ filters, pagination }) {
    return paymentDomain.list(TransactionModel.list({ filters, pagination })).then(TransactionParser.list)
  },
  getById (id) {
    return paymentDomain.getById(id, 'Braspag').then(TransactionParser.single)
  }
}
