/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { formatMoney } from '@bonitour/common-functions'
import { valueStyle } from './HistoryList.styles'
import moment from 'moment'
import {
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  colors
} from '@bonitour/components'

const valueStyleRed = valueStyle(colors.red1)
const valueStyleGreen = valueStyle(colors.green2)

const HistoryListingTableHeader = ({ columns }) => (
  <TableHeader>
    <TableHeaderRow>
      {columns.map((item) => <TableHeaderCell key={item}>{item}</TableHeaderCell>)}
    </TableHeaderRow>
  </TableHeader>
)

const HistoryListingTableBody = ({ accountMovements, loading }) => {
  return (
    <TableBody loading={loading}>
      {accountMovements.map(accountMovement =>
        <TableBodyRow key={accountMovement.id}>
          <TableBodyCell>{moment(accountMovement.date).format('DD/MM/YYYY')}</TableBodyCell>
          <TableBodyCell>{accountMovement.descricao}</TableBodyCell>
          <TableBodyCell css={accountMovement.operationType === 'credit' ? valueStyleGreen : valueStyleRed}>{formatMoney(`${accountMovement.value}`)}</TableBodyCell>
        </TableBodyRow>)}
    </TableBody>
  )
}

export const HistoryList = ({ accountMovements = [], titleColumnList = [], loading }) => {
  return (
    <TableContainer>
      <HistoryListingTableHeader columns={titleColumnList} />
      <HistoryListingTableBody
        accountMovements={accountMovements}
        loading={loading}
      />
    </TableContainer>
  )
}
