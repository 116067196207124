import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const labelStyle = css`
  font-size: 18px;
  font-weight: 500;
`

export const marginTop10 = css`
  margin-top: 10px;
`

export const textArea = css`
  height: 5em;
`

export const textLimit = css`
  font-size: 11px;
  color: ${colors.gray6};
  display: flex;
  align-self: flex-end;
  margin-top: 6px;
`

export const width100 = css`
  width: 100%;
`

export const marginRight = css`
  margin-right: 16px;
`

export const marginTopButton = css`
  margin-top: 3em;
`
