import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const stylishCardSearch = css`
  border: none;
  background-color: ${colors.gray8};
  padding: 20px;
`

export const button = css`
  height: 40px;
  width: 100%;
  margin-top: 23px;
`

export const width100 = css`
  width: 100%;
`

export const marginTop = margin => css`
  margin-top: ${margin}px;
`
