// This object was assembled using the links
// https://braspag.github.io//manual/antifraude#tabela-27-provideranalysisresult.afsreply.hotListInfoCode
// Using the table twenty-five

export const hotListInfoCode = {
  'CON-POSNEG': {
    msg: 'A ordem disparada bate tanto com a lista positiva e negativa. O resultado da lista positiva sobrescreve a lista negativa'
  },
  'NEG-BA': {
    msg: 'O endereço de cobrança está na lista negativa'
  },
  'NEG-BCO': {
    msg: 'O país do endereço de cobrança está na lista negativa'
  },
  'NEG-BIN': {
    msg: 'O BIN do cartão de crédito (os seis primeiros dígitos do número do cartão) está na lista negativa'
  },
  'NEG-BINCO': {
    msg: 'O país em que o cartão de crédito foi emitido está na lista negativa'
  },
  'NEG-BZC': {
    msg: 'O código postal do endereço de cobrança está na lista negativa'
  },
  'NEG-CC': {
    msg: 'O número de cartão de crédito está na lista negativa'
  },
  'NEG-EM': {
    msg: 'O endereço de e-mail está na lista negativa'
  },
  'NEG-EMCO': {
    msg: 'O país em que o endereço de e-mail está localizado na lista negativa'
  },
  'NEG-EMDOM': {
    msg: 'O domínio de e-mail (por exemplo, mail.example.com) está na lista negativa'
  },
  'NEG-FP': {
    msg: 'O device fingerprint está na lista negativa'
  },
  'NEG-HIST': {
    msg: 'A transação foi encontrada na lista negativa'
  },
  'NEG-ID': {
    msg: 'Identificador do comprador (CPF ou CNPJ) está na lista negativa'
  },
  'NEG-IP': {
    msg: 'O endereço IP (por exemplo, 10.1.27.63) está na lista negativa'
  },
  'NEG-IP3': {
    msg: 'O endereço IP de rede (por exemplo, 10.1.27.63) está na lista negativa. Um endereço de IP da rede inclui até 256 endereços IP'
  },
  'NEG-IPCO': {
    msg: 'O país em que o endereço IP está localizado está na lista negativa'
  },
  'NEG-PEM': {
    msg: 'O endereço de e-mail do passageiro está na lista negativa'
  },
  'NEG-PH': {
    msg: 'O número do telefone está na lista negativa'
  },
  'NEG-PID': {
    msg: 'Identificador do passageiro está na lista negativa'
  },
  'NEG-PPH': {
    msg: 'O número do telefone do passageiro está na lista negativa'
  },
  'NEG-SA': {
    msg: 'O endereço de entrega está na lista negativa'
  },
  'NEG-SCO': {
    msg: 'O país do endereço de entrega está na lista negativa'
  },
  'NEG-SZC': {
    msg: 'O código postal do endereço de entrega está na lista negativa'
  },
  'POS-TEMP': {
    msg: 'O comprador está temporariamente na lista positiva'
  },
  'POS-PERM': {
    msg: 'O comprador está permanente na lista positiva'
  },
  'REV-BA': {
    msg: 'O endereço de cobrança esta na lista de revisão'
  },
  'REV-BCO': {
    msg: 'O país do endereço de cobrança está na lista de revisão'
  },
  'REV-BIN': {
    msg: 'O BIN do cartão de crédito (os seis primeiros dígitos do número do cartão) está na lista de revisão'
  },
  'REV-BINCO': {
    msg: 'O país em que o cartão de crédito foi emitido está na lista de revisão'
  },
  'REV-BZC': {
    msg: 'O código postal do endereço de cobrança está na lista de revisão'
  },
  'REV-CC': {
    msg: 'O número do cartão de crédito está na lista de revisão'
  },
  'REV-EM': {
    msg: 'O endereço de e-mail está na lista de revisão'
  },
  'REV-EMCO': {
    msg: 'O país em que o endereço de e-mail está localizado está na lista de revisão'
  },
  'REV-EMDOM': {
    msg: 'O domínio de e-mail (por exemplo, mail.example.com) está na lista de revisão'
  },
  'REV-FP': {
    msg: 'O device fingerprint está na lista de revisão'
  },
  'REV-ID': {
    msg: 'Identificador do comprador (CPF ou CNPJ) está na lista de revisão'
  },
  'REV-IP': {
    msg: 'O endereço IP (por exemplo, 10.1.27.63) está na lista de revisão'
  },
  'REV-IP3': {
    msg: 'O endereço IP de rede (por exemplo, 10.1.27.63) está na lista de revisão. Um endereço de IP da rede inclui até 256 endereços IP'
  },
  'REV-IPCO': {
    msg: 'O país em que o endereço IP está localizado está na lista de revisão'
  },
  'REV-PEM': {
    msg: 'O endereço de e-mail do passageiro está na lista de revisão'
  },
  'REV-PH': {
    msg: 'O número do telefone está na lista de revisão'
  },
  'REV-PID': {
    msg: 'Identificador do passageiro está na lista de revisão'
  },
  'REV-PPH': {
    msg: 'O número do telefone do passageiro está na lista de revisão'
  },
  'REV-SA': {
    msg: 'O endereço de entrega está na lista de revisão'
  },
  'REV-SCO': {
    msg: 'O país do endereço de entrega está na lista de revisão'
  },
  'REV-SZC': {
    msg: 'O código postal do endereço de entrega está na lista de revisão'
  }
}
