import { useState, useEffect, useCallback } from 'react'
import { useToast } from '@bonitour/components'
import { DigitalAccountsService } from 'Core/Service/DigitalAccounts'

export const useListDigitalAccount = (id, disableToast = false) => {
  const { add: addToast } = useToast()
  const [digitalAccountData, setDigitalAccountData] = useState()
  const [error, setError] = useState()
  const [filters] = useState({ originId: id })

  const fetchDigitalAccountData = useCallback((filters) => {
    return DigitalAccountsService.search({
      filters
    }).then((data) => {
      setDigitalAccountData(data)
    }).catch((error) => {
      setError(error)
      !disableToast && addToast('Erro ao carregar a conta')
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchDigitalAccountData(filters)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDigitalAccountData])

  return {
    digitalAccounts: digitalAccountData?.digitalAccounts,
    error
  }
}
