/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { flexColumn, flexHorizontallyCentered, LightTooltip, colors, LogoutIcon } from '@bonitour/components'
import { containerOption, containerTooltip, labelTooltip, iconTooltip } from './DigitalAccountTooltipOptions.styles'

export const DigitalAccountTooltipOptions = ({ onClickExit }) => {
  const labelTooltipGray = labelTooltip(colors.gray4)
  const iconTooltipGray = iconTooltip(colors.gray4)

  return (
    <LightTooltip position='bottom'>
      <div css={[flexColumn, containerTooltip]}>
        <div onClick={onClickExit} css={[flexHorizontallyCentered, containerOption]}>
          <LogoutIcon css={iconTooltipGray} />
          <small css={labelTooltipGray}>Sair da conta</small>
        </div>
      </div>
    </LightTooltip>
  )
}
