/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import { Card, colors, flexRow, Logo } from '@bonitour/components'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import WaitingLogo from 'Shared/svgs/waiting.svg'
import BlockedLogo from 'Shared/svgs/blocked.svg'
import RefusedLogo from 'Shared/svgs/refused.svg'
import InactiveLogo from 'Shared/svgs/inactive.svg'
import AwaitingDocumentsLogo from 'Shared/svgs/awaitingDocuments.svg'
import { logo, width40, title, content, containerContent, containerButton, marginTop } from './DigitalAccountStatus.styles'
import {
  textWaitingForApproval,
  textContactByEmail,
  textBlockedStatus,
  textContactSupport,
  textRefusedStatus,
  textTryAgain,
  textInactiveStatus,
  textCreateNewAccount,
  textAwaitingDocumentsStatus
} from '../../Constants/DigitalAccounts'
import {
  WaitingStatusButton,
  BlockedStatusButton,
  WaitingDocumentsStatusButton,
  RefusedStatusButton,
  InactiveStatusButton
} from './DigitalAccountStatusButton'

const textStyleGray = content(colors.gray4)
const textStylePrimary = content(colors.primary)

export const DigitalAccountStatus = () => {
  const status = 'waiting'
  const { push } = useHistory()

  const navigateToDashboard = () => push('/dashboard')
  const navigateToCreateDigitalAccount = () => push('/digital-account/create')

  const digitalAccountStatus = {
    blocked: { title: 'Conta bloqueada!', text: textBlockedStatus, secondaryText: textContactSupport, logo: BlockedLogo },
    refused: { title: 'Conta recusada!', text: textRefusedStatus, secondaryText: textTryAgain, logo: RefusedLogo },
    inactive: { title: 'Conta inativa!', text: textInactiveStatus, secondaryText: textCreateNewAccount, logo: InactiveLogo },
    waiting: { title: 'Cadastro finalizado!', text: textWaitingForApproval, secondaryText: textContactByEmail, logo: WaitingLogo },
    awaiting_documents: { title: 'Quase lá!', text: textAwaitingDocumentsStatus, secondaryText: textContactByEmail, logo: AwaitingDocumentsLogo }
  }

  const digitalAccountStatusButton = {
    blocked: <BlockedStatusButton navigateToDashboard={navigateToDashboard} navigateToCreateDigitalAccount={navigateToCreateDigitalAccount} />,
    refused: <RefusedStatusButton navigateToDashboard={navigateToDashboard} navigateToCreateDigitalAccount={navigateToCreateDigitalAccount} />,
    inactive: <InactiveStatusButton navigateToDashboard={navigateToDashboard} navigateToCreateDigitalAccount={navigateToCreateDigitalAccount} />,
    waiting: <WaitingStatusButton navigateToDashboard={navigateToDashboard} />,
    awaiting_documents: <WaitingDocumentsStatusButton navigateToDashboard={navigateToDashboard} navigateToCreateDigitalAccount={navigateToCreateDigitalAccount} />
  }

  return (
    <>
      <PageTitle title='Criar conta digital' />
      <Card>
        <div css={flexRow}>
          <div css={width40}>
            <Logo css={logo} src={digitalAccountStatus[status]?.logo} />
          </div>
          <div css={[width40, containerContent]}>
            <h1 css={title}>{digitalAccountStatus[status]?.title}</h1>
            <small css={textStyleGray}>{digitalAccountStatus[status]?.text}</small>
            <small css={[textStyleGray, marginTop]}>{digitalAccountStatus[status]?.secondaryText}</small>
            <small css={textStylePrimary}>socorro@binamik.com.br</small>
          </div>
        </div>
      </Card>
      <div css={containerButton}>
        {digitalAccountStatusButton[status]}
      </div>
    </>
  )
}
