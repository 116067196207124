
import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const width100 = css`
  width: 100%;
`

export const marginValue = css`
  margin: 20px 0 0 5px;
`

export const colorOrange3 = css`
  color: ${colors.orange3};
`

export const colorPrimary = css`
  color: ${colors.primary};
`

export const marginBottom = margin => css`
  margin-bottom: ${margin}px;
`

export const marginTop10 = css`
  margin-top: 10px;
`
