/* eslint-disable */

export const Beamer = (beamerId) => {  
  window.beamer_config = {
    product_id : beamerId,
    button : false,
  }
  const beamerScript = document.createElement('script')
  beamerScript.src = 'https://app.getbeamer.com/js/beamer-embed.js'
  beamerScript.defer = true
  document.body.appendChild(beamerScript)
}
