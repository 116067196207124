import { metaModel } from './Meta'
import moment from 'moment'

export const TransactionModel = {
  list: ({ filters = {}, pagination = {} }) => {
    const {
      initialDate,
      finalDate,
      reservation,
      aut,
      status
    } = filters

    const meta = metaModel(pagination)

    const searchParams = {
      initial_date: initialDate ? moment(initialDate).format('YYYY-MM-DD') : undefined,
      final_date: finalDate ? moment(finalDate).format('YYYY-MM-DD') : undefined,
      gateway: 'Braspag',
      reservations_codes: reservation && [reservation],
      aut: aut || undefined,
      status: status || undefined
    }

    return { searchParams, pagination: meta }
  }
}
