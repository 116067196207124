/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core'
import { CircleThumbnail, Column, DatePicker, H3, Input, InputFormGroup, MaskedInputWithPrefix, Row } from '@bonitour/components'
import { identity, maskPhone } from '@bonitour/common-functions'
import flag from 'Shared/images/brazilian-flag.png'
import { width100 } from 'Domains/DigitalAccount/Create/Steps/Identification/Identification.styles'

const fullWidth = css`
  width: 100%;
`

const marginBottom30 = css`
  margin-bottom: 30px;
`

const overwriteSizes = css`
  width: 45px;
  background-size: 40px;
`

export const AccountableConfiguration = ({
  isBlocked = false,
  data: { telephone = '', name = '', mail = '', birthday = '' } = {},
  errors = {},
  onChange: onInputChange = identity,
  onBlur: onInputBlur = identity,
  displayBirthday = false
}) => (
  <Row css={marginBottom30}>
    <Column>
      <H3>
        Responsável pela conta
      </H3>
    </Column>
    <Column desktop={4} phone={12}>
      <InputFormGroup label='Nome' errorMessage={errors.name}>
        <Input
          css={fullWidth}
          value={name}
          disabled={isBlocked}
          onChange={onInputChange('accountable.name')}
          onBlur={onInputBlur('accountable.name')}
        />
      </InputFormGroup>
    </Column>
    <Column desktop={4} phone={12}>
      <InputFormGroup label='Telefone' errorMessage={errors.telephone}>
        <MaskedInputWithPrefix
          formatterFunction={maskPhone}
          disabled={isBlocked}
          placeholder='(00) 0 0000-0000'
          value={telephone}
          onChange={onInputChange('accountable.telephone')}
          onBlur={onInputBlur('accountable.telephone')}
        >
          <CircleThumbnail image={flag} css={overwriteSizes} />
        </MaskedInputWithPrefix>
      </InputFormGroup>
    </Column>
    <Column desktop={4} phone={12}>
      <InputFormGroup label='E-mail' errorMessage={errors.mail}>
        <Input
          css={fullWidth}
          disabled={isBlocked}
          value={mail}
          onChange={onInputChange('accountable.mail')}
          onBlur={onInputBlur('accountable.mail')}
        />
      </InputFormGroup>
    </Column>
    {displayBirthday && (
      <Column desktop={3} phone={12}>
        <InputFormGroup label='Data de nascimento' errorMessage={errors.birthday}>
          <DatePicker
            id='birthdate'
            value={birthday}
            disabled={isBlocked}
            css={width100}
            placeholder='Data de nascimento'
            onChange={onInputChange('accountable.birthday')}
          />
        </InputFormGroup>
      </Column>
    )}
  </Row>
)
