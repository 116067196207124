/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback, useState } from 'react'
import { jsx } from '@emotion/core'
import { Dialog } from '@bonitour/components'
import { PixValue } from './PixValue/PixValue'
import { TransferTo } from './TransferTo/TransferTo'
import { head, maskMoney } from '@bonitour/common-functions'
import { ConfirmTransfer } from './ConfirmTransfer/ConfirmTransfer'
import { useTransferPix } from './hooks/useTransferPix'
import { useDigitalAccount } from 'Domains/DigitalAccount/Hooks/useDigitalAccount'

export const DialogTransferPix = ({ isVisible, toggleHidden, setTransferStatusModalData }) => {
  const { digitalAccounts } = useDigitalAccount()
  const [step, setStep] = useState(1)
  const [value, setValue] = useState('')
  const [digitalAccountId, setDigitalAccountId] = useState('')
  const { createTransfer } = useTransferPix(setTransferStatusModalData)

  const nextStep = useCallback(() => {
    if (step === 1 && digitalAccounts?.length === 1) {
      const { id } = head(digitalAccounts)

      setDigitalAccountId(id)
      setStep(3)
    } else {
      setStep(step + 1)
    }
  }, [step, digitalAccounts])

  const onClose = useCallback(() => {
    setStep(1)
    setValue('')
    setDigitalAccountId('')
    toggleHidden()
  }, [toggleHidden])

  const onEdit = () => {
    setStep(1)
    setDigitalAccountId('')
  }

  const onSubmit = () => {
    const transferData = { digitalAccountId, value }
    createTransfer(transferData)
    onClose()
  }

  const renderStepActive = {
    1: <PixValue nextStep={nextStep} value={value} setValue={setValue} />,
    2: <TransferTo nextStep={nextStep} digitalAccounts={digitalAccounts} digitalAccountId={digitalAccountId} setDigitalAccountId={setDigitalAccountId} />,
    3: <ConfirmTransfer digitalAccounts={digitalAccounts} value={value} digitalAccountId={digitalAccountId} onEdit={onEdit} onSubmit={onSubmit} />
  }

  const defineTitleByStep = {
    1: 'Qual o valor da transferência?',
    2: `Selecione a conta para transferir ${maskMoney(value)}`,
    3: 'Transferindo Pix'
  }

  return (
    <Dialog title={defineTitleByStep[step]} isVisible={isVisible} onClose={onClose}>
      {renderStepActive[step]}
    </Dialog>
  )
}
