/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { identity } from '@bonitour/common-functions'
import { Column, H3, InputFormGroup, Row, Select, DocumentInput, MaskedInput } from '@bonitour/components'
import { css, jsx } from '@emotion/core'
import { banksOptions } from 'Shared/constants/banksOptions'
import { accountTypeOptions } from '../constants/accountTypeOptions'
import { companyCategoryOptions, oldCompanyCategoryOptions } from '../constants/companyCategoryOptions'
import { useMemo } from 'react'

const fullWidth = css`
  width: 100%;
`

const marginBottom30 = css`
  margin-bottom: 30px;
`

const numberOnlyMask = (value = '') => value.replace(/\D/g, '')

export const AccountConfiguration = ({
  isBlocked = false,
  data: { bank = '', accountType = '', document = '', verifierNumberAccount = '', verifierNumberAgency = '', documentType = '', numberAccount = '', numberAgency = '', companyCategory = '' } = {},
  errors = {},
  onChange: onInputChange = identity,
  onBlur: onInputBlur = identity
}) => {
  const categoryOptions = useMemo(
    () => isBlocked
      ? [...companyCategoryOptions, ...oldCompanyCategoryOptions]
      : companyCategoryOptions,
    [isBlocked]
  )
  const displayCategory = useMemo(
    () => isBlocked ? Boolean(companyCategory) : documentType === 'Cpf',
    [isBlocked, companyCategory, documentType]
  )

  return (
    <Row css={marginBottom30}>
      <Column>
        <H3>
          Cadastro de conta
        </H3>
      </Column>
      <Column desktop={4} phone={12}>
        <InputFormGroup label='Banco' errorMessage={errors.bank}>
          <Select
            css={fullWidth}
            value={bank}
            disabled={isBlocked}
            options={banksOptions}
            onChange={onInputChange('account.bank')}
            onBlur={onInputBlur('account.bank')}
          />
        </InputFormGroup>
      </Column>
      <Column desktop={4} phone={12}>
        <InputFormGroup label='Tipo da conta' errorMessage={errors.accountType}>
          <Select
            css={fullWidth}
            value={accountType}
            disabled={isBlocked}
            options={accountTypeOptions}
            onChange={onInputChange('account.accountType')}
            onBlur={onInputBlur('account.accountType')}
          />
        </InputFormGroup>
      </Column>
      <Column desktop={4} phone={12}>
        <InputFormGroup label='Documento' errorMessage={errors.document}>
          <DocumentInput
            documentType={documentType}
            documentValue={document}
            disabled={isBlocked}
            onDocumentChange={onInputChange('account.documentType')}
            onDocumentBlur={onInputBlur('account.documentType')}
            onDocumentValueChange={onInputChange('account.document')}
            onDocumentValueBlur={onInputBlur('account.document')}
          />
        </InputFormGroup>
      </Column>
      <Column desktop={2} phone={12}>
        <InputFormGroup label='Número da conta' errorMessage={errors.numberAccount}>
          <MaskedInput
            css={fullWidth}
            formatterFunction={numberOnlyMask}
            value={numberAccount}
            disabled={isBlocked}
            onChange={onInputChange('account.numberAccount')}
            onBlur={onInputBlur('account.numberAccount')}
            maxLength={10}
          />
        </InputFormGroup>
      </Column>
      <Column desktop={2} phone={12}>
        <InputFormGroup label='Digito da conta' errorMessage={errors.verifierNumberAccount}>
          <MaskedInput
            css={fullWidth}
            formatterFunction={numberOnlyMask}
            disabled={isBlocked}
            value={verifierNumberAccount}
            onChange={onInputChange('account.verifierNumberAccount')}
            onBlur={onInputBlur('account.verifierNumberAccount')}
            maxLength={1}
          />
        </InputFormGroup>
      </Column>
      <Column desktop={2} phone={12}>
        <InputFormGroup label='Número da agência' errorMessage={errors.numberAgency}>
          <MaskedInput
            css={fullWidth}
            formatterFunction={numberOnlyMask}
            value={numberAgency}
            disabled={isBlocked}
            onChange={onInputChange('account.numberAgency')}
            onBlur={onInputBlur('account.numberAgency')}
            maxLength={4}
          />
        </InputFormGroup>
      </Column>
      <Column desktop={2} phone={12}>
        <InputFormGroup label='Digito da agência' errorMessage={errors.verifierNumberAgency}>
          <MaskedInput
            css={fullWidth}
            formatterFunction={numberOnlyMask}
            value={verifierNumberAgency}
            disabled={isBlocked}
            onChange={onInputChange('account.verifierNumberAgency')}
            onBlur={onInputBlur('account.verifierNumberAgency')}
            maxLength={1}
          />
        </InputFormGroup>
      </Column>
      {displayCategory && (
        <Column desktop={4} phone={12}>
          <InputFormGroup label='Categoria da empresa' errorMessage={errors.companyCategory}>
            <Select
              css={fullWidth}
              options={categoryOptions}
              value={companyCategory}
              disabled={isBlocked}
              onChange={onInputChange('account.companyCategory')}
              onBlur={onInputBlur('account.companyCategory')}
            />
          </InputFormGroup>
        </Column>
      )}
    </Row>
  )
}
