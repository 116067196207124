import { GoogleTagManagerHeader, GoogleTagManagerBody } from './GoogleTagManager'
import { Beamer } from './beamer'

const { REACT_APP_BEAMER_ID } = process.env

const googleTagManagerId = 'GTM-N8DKWFL'

export const HeaderIntegrations = () => {
  GoogleTagManagerHeader(googleTagManagerId)
}

export const BodyIntegrations = () => {
  GoogleTagManagerBody(googleTagManagerId)
  Beamer(REACT_APP_BEAMER_ID)
}

export const ExternalIntegrations = () => {
  HeaderIntegrations()
  BodyIntegrations()
}
