import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const width40 = css`
  width: 40%;
`

export const marginTop = css`
  margin-top: 2vh;
`

export const containerContent = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 4vw;
`

export const logo = css`
  width: 100%;
`

export const title = css`
  font-size: 18px;
  color: ${colors.gray1};
  margin-bottom: 14px;
`

export const content = (color) => css`
  font-size: 14px;
  color: ${color};
  text-align: justify;
  line-height: 1.3;
`

export const containerButton = css`
  margin-top: 3em;
`
