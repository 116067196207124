import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const cardStyle = css`
  border: none;
  background-color: ${colors.gray8};
  margin-bottom: 20px;
  padding: 1em;
`

export const fullWidth = css`
  width: 100%;
`
