// This object was assembled using the links
// https://braspag.github.io//manual/api-ecommerce-cielo-af#tabela-15-payment.fraudanalysis.cart.items[n].giftcategory
// Using the table thirty

export const velocityInfoCode = {
  'VEL-ADDR': {
    msg: 'Diferente estados dos endereços de cobrança e/ou entrega (EUA e Canadá apenas) têm sido usadas várias vezes com o número do cartão de crédito e/ou endereço de email'
  },
  'VEL-CC': {
    msg: 'Diferentes números de cartões de créditos foram usados várias vezes com o mesmo nome ou endereço de email'
  },
  'VEL-NAME': {
    msg: 'Diferentes nomes foram usados várias vezes com o mesmo número do cartão de crédito e/ou endereço de email'
  },
  'VELS-CC': {
    msg: 'O número do cartão de crédito tem sido utilizado várias vezes durante um intervalo curto'
  },
  'VELI-CC': {
    msg: 'O número do cartão de crédito tem sido utilizado várias vezes durante um intervalo médio'
  },
  'VELL-CC': {
    msg: 'O número do cartão de crédito tem sido utilizado várias vezes durante um intervalo longo'
  },
  'VELV-CC': {
    msg: 'O número do cartão de crédito tem sido utilizado várias vezes durante um intervalo muito longo'
  },
  'VELS-EM': {
    msg: 'O endereço de e-mail tem sido utilizado várias vezes durante um intervalo curto'
  },
  'VELI-EM': {
    msg: 'O endereço de e-mail tem sido utilizado várias vezes durante um intervalo médio'
  },
  'VELL-EM': {
    msg: 'O endereço de e-mail tem sido utilizado várias vezes durante um intervalo longo'
  },
  'VELV-EM': {
    msg: 'O endereço de e-mail tem sido utilizado várias vezes durante um intervalo muito longo'
  },
  'VELS-FP': {
    msg: 'O device fingerprint tem sido utilizado várias vezes durante um intervalo curto'
  },
  'VELI-FP': {
    msg: 'O device fingerprint tem sido utilizado várias vezes durante um intervalo médio'
  },
  'VELL-FP': {
    msg: 'O device fingerprint tem sido utilizado várias vezes durante um intervalo longo'
  },
  'VELV-FP': {
    msg: 'O device fingerprint tem sido utilizado várias vezes durante um intervalo muito longo'
  },
  'VELS-IP': {
    msg: 'O endereço IP tem sido utilizado várias vezes durante um intervalo curto'
  },
  'VELI-IP': {
    msg: 'O endereço IP tem sido utilizado várias vezes durante um intervalo médio'
  },
  'VELL-IP': {
    msg: 'O endereço IP tem sido utilizado várias vezes durante um intervalo longo'
  },
  'VELV-IP': {
    msg: 'O endereço IP tem sido utilizado várias vezes durante um intervalo muito longo'
  },
  'VELS-SA': {
    msg: 'O endereço de entrega tem sido utilizado várias vezes durante um intervalo curto'
  },
  'VELI-SA': {
    msg: 'O endereço de entrega tem sido utilizado várias vezes durante um intervalo médio'
  },
  'VELL-SA': {
    msg: 'O endereço de entrega tem sido utilizado várias vezes durante um intervalo longo'
  },
  'VELV-SA': {
    msg: 'O endereço de entrega tem sido utilizado várias vezes durante um intervalo muito longo'
  },
  'VELS-TIP': {
    msg: 'O endereço IP verdadeiro tem sido utilizado várias vezes durante um intervalo curto'
  },
  'VELI-TIP': {
    msg: 'O endereço IP verdadeiro tem sido utilizado várias vezes durante um intervalo médio'
  },
  'VELL-TIP': {
    msg: 'O endereço IP verdadeiro tem sido utilizado várias vezes durante um intervalo longo'
  }
}
