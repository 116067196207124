export const companyTypeOptions = [
  {
    value: 'eireli',
    label: 'EIRELI'
  },
  {
    value: 'ltda',
    label: 'LTDA'
  }
]

export const countryTypeOptions = [
  {
    value: 'brazil',
    label: 'Brasil'
  }
]

export const stateTypeOptions = [
  {
    value: 'sc',
    label: 'Santa Catarina'
  }
]

export const cityTypeOptions = [
  {
    value: 'florianopolis',
    label: 'Florianópolis'
  }
]

export const pixKeyTypeOptions = [
  {
    value: 'cpf',
    label: 'CPF'
  },
  {
    value: 'cnpj',
    label: 'CNPJ'
  },
  {
    value: 'phone',
    label: 'Telefone'
  },
  {
    value: 'email',
    label: 'E-mail'
  },
  {
    value: 'randomKey',
    label: 'Chave aleatória'
  }
]

export const textWaitingForApproval = 'Agora é só aguardar um pouquinho enquanto nosso time verifica que todas as informações fornecidas estejam corretas. Em até 24 horas te daremos um retorno.'
export const textContactByEmail = 'Qualquer dúvida é só nos chamar no e-mail: '
export const textBlockedStatus = 'Nosso time identificou informações cadastrais com validade conflitante ou ações que podem ser fraudulentas, para sua segurança a conta foi bloqueada temporariamente.'
export const textContactSupport = 'Mas calma, você ainda pode buscar ajuda com o suporte para identificarmos o problema. Qualquer dúvida é só nos chamar no e-mail.'
export const textRefusedStatus = 'Seu cadastro passou por análise do nosso time e identificamos conflitos entre as informações fornecidas e os requisitos para a criação da conta digital.'
export const textTryAgain = 'Mas calma, você ainda pode fazer uma nova tentativa. Qualquer dúvida é só nos chamar no e-mail.'
export const textInactiveStatus = 'Verificamos que a conta que você está tentando acessar está desativada.'
export const textCreateNewAccount = 'Mas calma, você ainda pode criar uma nova conta digital. Qualquer dúvida é só nos chamar no e-mail.'
export const textAwaitingDocumentsStatus = 'Nosso time fez a análise da validade dos documentos e identificamos algum problema neles, verifique seu cadastro e envie novamente para concluir a criação da sua conta digital'

export const pixKeyTypeLabel = {
  phone: 'Celular',
  email: 'E-mail',
  random: 'Chave aleatória'
}

export const bankAccountTypeOptions = [
  {
    value: 'CHECKING',
    label: 'Conta Corrente'
  },
  {
    value: 'SAVING',
    label: 'Poupança'
  }
]

export const defineTitle = {
  requerimentFile: 'Requerimento de empresario',
  frontFile: 'Frente do RG ou CNH',
  backFile: 'Verso do RG ou CNH',
  selfieFile: 'Selfie'
}

export const definePixTypeTitle = {
  cpf: 'CPF',
  cnpj: 'CNPJ',
  phone: 'Telefone',
  email: 'E-mail',
  randomKey: 'Chave aleatória'
}

export const defineBankAccountType = {
  CHECKING: 'Corrente',
  SAVING: 'Poupança'
}
