/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { flexHorizontallyCentered, flexColumn, Popover } from '@bonitour/components'
import { divider, labelTooltipGray, containerOption, containerTooltip, labelKeys } from './PixAreaTooltipOptions.styles'
import { head } from '@bonitour/common-functions'
import { useDigitalAccount } from 'Domains/DigitalAccount/Hooks/useDigitalAccount'

export const PixAreaTooltipOptions = ({ bankAccountBalance, hasBankAccount, toggleKeyModalVisible, toggleSendPixModalVisible }) => {
  const { digitalAccounts } = useDigitalAccount()
  const hasBankAccountAndBalance = digitalAccounts.length > 0 && head(digitalAccounts)?.bankAccountNumber && bankAccountBalance?.transferableBalance > 0

  return (
    <Popover position='bottom'>
      <div css={[flexColumn, containerTooltip]}>
        <div onClick={toggleSendPixModalVisible} css={[flexHorizontallyCentered, containerOption]}>
          {hasBankAccountAndBalance && <small css={labelTooltipGray}>Fazer um Pix</small>}
        </div>
        <div css={divider} />
        <div onClick={toggleKeyModalVisible} css={[flexHorizontallyCentered, containerOption]}>
          <small css={labelTooltipGray}>Minhas chaves</small>
          <small css={labelKeys}>{hasBankAccount ? `${digitalAccounts.length} chave` : '0 chaves'}</small>
        </div>
      </div>
    </Popover>
  )
}
