import { getImageUrl } from 'Core/Store/Company'

export const CompanyParser = {
  listUserCompanies ({ companies = [] }) {
    return companies.map(company => ({
      id: company.id,
      name: company.name,
      image: getImageUrl(company.image)
    }))
  }
}
