export const RegisterKeyModel = {
  edit (payload) {
    const {
      responsibleName,
      responsibleDocument,
      bankNumber,
      bankAgencyNumber,
      bankAccountNumber,
      bankAccountType
    } = payload ?? {}

    return {
      bank_account: {
        name: responsibleName,
        document: responsibleDocument?.replace(/\D/gi, ''),
        bank_number: bankNumber,
        agency_number: bankAgencyNumber,
        account_number: bankAccountNumber,
        account_type: bankAccountType
      }
    }
  },
  delete () {
    return {
      bank_account: null
    }
  }
}
