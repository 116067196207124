import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import pkg from '../../../package.json'
import { UserStore } from 'Core/Store/User'
import { APP_ENVIRONMENT } from 'Shared/config/env'

const { REACT_APP_SENTRY_DSN } = process.env
const { version } = pkg

export function SentrySetup () {
  const getSentryUserScope = () => {
    const { id, email } = UserStore.user
    return { id, email }
  }

  if (APP_ENVIRONMENT === 'development') {
    return
  }
  if (REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: REACT_APP_SENTRY_DSN,
      release: version,
      environment: APP_ENVIRONMENT,
      integrations: [
        new Integrations.BrowserTracing()
      ],
      tracesSampleRate: 1.0
    })

    Sentry.configureScope(function (scope) {
      scope.setUser(getSentryUserScope())
    })
  }
}
