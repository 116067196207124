import americanLogo from '../Shared/svgs/american-express.svg'
import boletoLogo from '../Shared/svgs/boleto.svg'
import eloLogo from '../Shared/svgs/elo.svg'
import hipercardLogo from '../Shared/svgs/hipercard.svg'
import mastercardLogo from '../Shared/svgs/mastercard.svg'
import visaLogo from '../Shared/svgs/visa.svg'
import dinersLogo from '../Shared/svgs/diners.svg'
import discoverLogo from '../Shared/svgs/discover.svg'

export const brandOptions = {
  amex: {
    name: 'American Express',
    image: americanLogo
  },
  boleto: {
    name: 'Boleto',
    image: boletoLogo
  },
  elo: {
    name: 'Elo',
    image: eloLogo
  },
  hipercard: {
    name: 'Hipercard',
    image: hipercardLogo
  },
  mastercard: {
    name: 'MasterCard',
    image: mastercardLogo
  },
  visa: {
    name: 'Visa',
    image: visaLogo
  },
  diners: {
    name: 'Diners',
    image: dinersLogo
  },
  discover: {
    name: 'Discover',
    image: discoverLogo
  }
}
