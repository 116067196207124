/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useEffect, useMemo, useState } from 'react'
import { jsx } from '@emotion/core'
import { useForm } from '@bonitour/app-functions'
import { head } from '@bonitour/common-functions'
import { useRegisterKey } from 'Domains/DigitalAccount/Hooks/useRegisterKey'
import { bankAccountTypeOptions } from 'Domains/DigitalAccount/Constants/DigitalAccounts'
import { useListDigitalAccount } from 'Domains/DigitalAccount/Hooks/useListDigitalAccount'
import { RegisterKeySchema } from './RegisterKeySchema'
import { inputStyle, marginRight, marginTop } from './RegisterKey.styles'
import {
  Button,
  GhostButton,
  InputFormGroup,
  Input,
  Select,
  DocumentInput
} from '@bonitour/components'

export const RegisterKey = ({ toggleOnRegisterKey: returnToMyKeys, company }) => {
  const { digitalAccounts } = useListDigitalAccount(company?.id)
  const {
    responsibleName,
    responsibleDocument,
    bankNumber,
    bankAgencyNumber,
    bankAccountNumber,
    bankAccountType
  } = head(digitalAccounts) ?? {}

  const [formatPreset, setFormatPreset] = useState({
    responsibleName: '',
    responsibleDocumentType: '',
    responsibleDocument: '',
    bankNumber: '',
    bankAgencyNumber: '',
    bankAccountNumber: '',
    bankAccountType: ''
  })

  useEffect(() => {
    const definedDocumentType =
      responsibleDocument?.length === 11
        ? 'Cpf'
        : responsibleDocument?.length === 14
          ? 'Cnpj'
          : 'Cpf'

    setFormatPreset({
      responsibleName,
      responsibleDocumentType: definedDocumentType,
      responsibleDocument,
      bankNumber,
      bankAgencyNumber,
      bankAccountNumber,
      bankAccountType
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [digitalAccounts])

  const {
    onRegisterKey
  } = useRegisterKey(head(digitalAccounts)?.id, returnToMyKeys)

  const {
    form,
    onSubmit,
    errors,
    utils: { onInputChange }
  } = useForm(formatPreset, RegisterKeySchema)

  const {
    responsibleName: responsibleNameForm,
    responsibleDocumentType: responsibleDocumentTypeForm,
    responsibleDocument: responsibleDocumentForm,
    bankNumber: bankNumberForm,
    bankAgencyNumber: bankAgencyNumberForm,
    bankAccountNumber: bankAccountNumberForm,
    bankAccountType: bankAccountTypeForm
  } = form

  const handleSubmitCreate = useMemo(() => onSubmit(onRegisterKey), [onSubmit, onRegisterKey])

  return (
    <div>
      <InputFormGroup label='Nome do Responsável' css={marginTop} errorMessage={errors.responsibleName}>
        <Input
          id='responsibleName'
          placeholder='João'
          value={responsibleNameForm}
          onChange={onInputChange('responsibleName')}
          css={inputStyle}
        />
      </InputFormGroup>
      <InputFormGroup label='Documento do Responsável' errorMessage={errors.responsibleDocument}>
        <DocumentInput
          documentType={responsibleDocumentTypeForm}
          documentValue={responsibleDocumentForm}
          onDocumentChange={onInputChange('responsibleDocumentType')}
          onDocumentValueChange={onInputChange('responsibleDocument')}
        />
      </InputFormGroup>
      <InputFormGroup label='Número do Banco' css={marginTop} errorMessage={errors.bankNumber}>
        <Input
          id='bankNumber'
          placeholder='341'
          value={bankNumberForm}
          onChange={onInputChange('bankNumber')}
          css={inputStyle}
        />
      </InputFormGroup>
      <InputFormGroup label='Agência' css={marginTop} errorMessage={errors.bankAgencyNumber}>
        <Input
          id='bankAgencyNumber'
          placeholder='3333'
          value={bankAgencyNumberForm}
          onChange={onInputChange('bankAgencyNumber')}
          css={inputStyle}
        />
      </InputFormGroup>
      <InputFormGroup label='Conta' css={marginTop} errorMessage={errors.bankAccountNumber}>
        <Input
          id='bankAccountNumber'
          placeholder='122764'
          value={bankAccountNumberForm}
          onChange={onInputChange('bankAccountNumber')}
          css={inputStyle}
        />
      </InputFormGroup>
      <InputFormGroup label='Tipo da Conta' css={marginTop} errorMessage={errors.bankAccountType}>
        <Select
          id='bankAccountType'
          value={bankAccountTypeForm}
          options={bankAccountTypeOptions}
          onChange={onInputChange('bankAccountType')}
        />
      </InputFormGroup>
      <GhostButton css={marginRight} onClick={returnToMyKeys}>Voltar</GhostButton>
      <Button onClick={handleSubmitCreate}>
        Salvar
      </Button>
    </div>
  )
}
