/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors, flexRow, Logo, flexHorizontallyCentered, AngleRightIcon } from '@bonitour/components'
import { brandOptions } from '../../../Utils/paymentBrands'

const containerIconButton = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const iconStatusUnconfirmed = css`
  height: 8px;
  width: 8px;
  background-color: ${colors.red2};
  border-radius: 4px;
  margin-right: 8px;
`

const iconStatusConfirmed = css`
  height: 8px;
  width: 8px;
  background-color: ${colors.green2};
  border-radius: 4px;
  margin-right: 8px;
`

const labelColor = css`
  color: ${colors.gray6};
`

const labelGetStatus = css`
  color: ${colors.gray6};
  font-size: 11px;
  margin: 0 0 1px 16px;
`

const iconGetStatus = css`
  color: ${colors.gray6};
  margin-left: 4px;
  font-size: 12px;
`

const containerSeeMore = css`
  cursor: pointer;
`

const marginBottom = css`
  margin-bottom: 6px;
`

const logoCardSize = css`
  width: 30px;
  margin-right: 10px;
`

export const getStatus = (status, onClick) => {
  const defineStatus = {
    confirmed: { css: iconStatusConfirmed, label: 'Confirmado' },
    approved: { css: iconStatusConfirmed, label: 'Aprovado' },
    canceled: { css: iconStatusUnconfirmed, label: 'Cancelado' },
    cancelled: { css: iconStatusUnconfirmed, label: 'Cancelado' },
    denied: { css: iconStatusUnconfirmed, label: 'Negado' },
    failed: { css: iconStatusUnconfirmed, label: 'Falhou' },
    refunded: { css: iconStatusUnconfirmed, label: 'Estornado' },
    not_completed: { css: iconStatusUnconfirmed, label: 'Não finalizado' },
    authorized: { css: iconStatusConfirmed, label: 'Autorizado' },
    pending: { css: iconStatusUnconfirmed, label: 'Pendente' }
  }
  return (
    <div>
      <div css={[flexRow, flexHorizontallyCentered, marginBottom]}>
        <div css={defineStatus[status].css} />
        <span>{defineStatus[status].label}</span>
      </div>
      {(status === 'denied' || status === 'failed') &&
        <div onClick={onClick} css={[flexHorizontallyCentered, containerSeeMore]}>
          <small css={labelGetStatus}>Ver mais</small>
          <AngleRightIcon css={iconGetStatus} />
        </div>}
    </div>
  )
}

export const getStatusLabelGray = (status) => {
  const defineStatusLabelGray = {
    confirmed: { css: iconStatusConfirmed, label: 'Confirmado' },
    approved: { css: iconStatusConfirmed, label: 'Aprovado' },
    canceled: { css: iconStatusUnconfirmed, label: 'Cancelado' },
    cancelled: { css: iconStatusUnconfirmed, label: 'Cancelado' },
    denied: { css: iconStatusUnconfirmed, label: 'Negado' },
    failed: { css: iconStatusUnconfirmed, label: 'Falhou' },
    refunded: { css: iconStatusUnconfirmed, label: 'Estornado' },
    not_completed: { css: iconStatusUnconfirmed, label: 'Não finalizado' },
    authorized: { css: iconStatusConfirmed, label: 'Autorizado' },
    pending: { css: iconStatusUnconfirmed, label: 'Pendente' }
  }
  return <div css={containerIconButton}> <div css={defineStatusLabelGray[status].css} /><span css={labelColor}>{defineStatusLabelGray[status].label}</span> </div>
}

export const getCardBrand = (brand) => {
  const defineCardBrand = {
    Master: brandOptions.mastercard.image,
    Visa: brandOptions.visa.image,
    Elo: brandOptions.elo.image,
    Hipercard: brandOptions.hipercard.image,
    Amex: brandOptions.amex.image,
    Diners: brandOptions.diners.image,
    Discover: brandOptions.discover.image,
    slip: brandOptions.boleto.image
  }
  return <Logo css={logoCardSize} src={defineCardBrand[brand]} />
}

export const getCardLabel = (brand) => {
  const defineCardLabel = {
    Master: 'Mastercard',
    Visa: 'Visa',
    Elo: 'Elo',
    Hipercard: 'Hipercard',
    Amex: 'American Express',
    Diners: 'Diners',
    Discover: 'Discover',
    Slip: 'Boleto'
  }
  return <small css={labelColor}>{defineCardLabel[brand]}</small>
}

export const statusOptionsMap = [
  { value: '', label: 'Todos' },
  { value: 'confirmed', label: 'Confirmado' },
  { value: 'approved', label: 'Aprovado' },
  { value: 'cancelled', label: 'Cancelado' },
  { value: 'denied', label: 'Negado' },
  { value: 'failed', label: 'Falhou' },
  { value: 'refunded', label: 'Estornado' }
]

export const returnPayment = {
  pay_credit: 'Crédito',
  pay_debit: 'Débito'
}
