import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const containerPixValue = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2px 0 14px 0;
`

export const pixValueStyle = css`
  font-size: 20px;
  color: ${colors.primary};
  font-weight: 500;
  margin-right: 8px;
`

export const editButton = css`
  font-size: 10px;
  color: ${colors.primary};
  text-align: center;
  cursor: pointer;
`

export const labelData = (color) => css`
  font-size: 14px;
  color: ${color};
`

export const marginTop = (margin) => css`
  margin-top: ${margin}px;
`

export const marginBottom = css`
  margin-bottom: 13px;
`
