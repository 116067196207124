import { MetaParser } from './Meta'

export const RefundParser = {
  single: (refund) => {
    const {
      aut,
      doc,
      reservation_code: reservationCode,
      payment_date: paymentDate,
      payment_fee: paymentFee,
      payment_value: paymentValue,
      refund_date: refundDate,
      refund_value: refundValue
    } = refund?.pay_refund ?? refund ?? {}

    return {
      aut,
      doc,
      reservationCode,
      paymentDate,
      paymentFee,
      paymentValue,
      refundDate,
      refundValue,
      channel: 'Binamik Pay'
    }
  },
  list: ({ meta: metadata, pay_refunds = [] }) => {
    return {
      refunds: pay_refunds.map(RefundParser.single),
      meta: MetaParser.meta(metadata)
    }
  }
}
