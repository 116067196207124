
import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const transactionModalContainer = css`
  .transacation_details_content {
    border-radius: 0.625rem;
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .empty__message {
    border: 1px solid ${colors.gray8};
    padding: 10px;
    border-radius: 10px;
    margin: 10px 0;
    font-size: 18px;
    font-weight: 700;
    color: ${colors.gray4};
    text-align: center;
  }

  h3 {
    font-size: 18px;
    color: ${colors.gray1};
    font-weight: 700;
  }
 
  div[class^='transacation_details_wrapper_'] {
    display: flex;
    gap: 10px;
    grid-column: span 1;
    flex-wrap: wrap;
    flex-direction: column;
    box-shadow: 0px 0px 6px #0000001a;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
    

    p[class^='item_header_'] {
      font-size: 16px;
      color: ${colors.gray2};
      font-weight: 700;
    }

    p[class^='item_'] {
      font-size: 16px;
      color: ${colors.gray3};
    }

    p[class^='item_label'] {
      color: ${colors.gray2};
      font-weight: 600;
      white-space: nowrap;
    }

    .wrapper_card {
      display: flex;
      gap: 10px;
      flex-direction: column;
      border: 1px solid ${colors.gray8};
      border-radius: 10px;
      padding: 10px;
    }

    .wrapper_code_msg {
      flex-wrap: nowrap;
      flex-direction: row;

      p[class^='item_label_'] {
        color: ${colors.gray2};
        font-weight: 600;
        white-space: nowrap;
      }
    }
  }
`
