// This object was assembled using the links
// https://braspag.github.io//manual/api-ecommerce-cielo-af#tabela-15-payment.fraudanalysis.cart.items[n].giftcategory
// Using the table sixteen

// https://braspag.github.io//manual/antifraude#tabela-27-provideranalysisresult.afsreply.internetinfocode
// Using the table twenty-two

// It was identified that this object includes both tables.

export const addressInfoCode = {
  'COR-BA': {
    msg: 'O endereço de cobrança pode ser normalizado'
  },
  'COR-SA': {
    msg: 'O endereço de entrega pode ser normalizado'
  },
  'INTL-BA': {
    msg: 'O país do endereço de cobrança está fora dos EUA'
  },
  'INTL-SA': {
    msg: 'O país do endereço de entrega está fora dos EUA'
  },
  'MIL-USA': {
    msg: 'Endereço militar nos EUA'
  },
  'MM-A': {
    msg: 'Os endereços de cobrança e entrega usam nomes de ruas diferentes'
  },
  'MM-BIN': {
    msg: 'O BIN do cartão (os seis primeiros dígitos do número do cartão) não corresponde ao país'
  },
  'MM-C': {
    msg: 'Os endereços de cobrança e entrega usam cidades diferentes'
  },
  'MM-CO': {
    msg: 'Os endereços de cobrança e entrega usam países diferentes'
  },
  'MM-ST': {
    msg: 'Os endereços de cobrança e entrega usam estados diferentes'
  },
  'MM-Z': {
    msg: 'Os endereços de cobrança e entrega usam códidos postais diferentes'
  },
  'UNV-ADDR': {
    msg: 'O endereço é inverificável'
  }
}
