export const accountTypeOptions = [
  {
    value: 'CheckingAccount',
    label: 'Conta corrente'
  },
  {
    value: 'SavingsAccount',
    label: 'Conta poupança'
  }
]
