// This object was assembled using the links
// https://braspag.github.io//manual/api-ecommerce-cielo-af#tabela-15-payment.fraudanalysis.cart.items[n].giftcategory
// Using the table fifteen

// https://braspag.github.io//manual/antifraude#tabela-27-provideranalysisresult.afsreply.internetinfocode
// Using the table twenty-one

// It was identified that this object includes both tables.

export const reasonCodeAndProviderCode = {
  100: { msg: 'Operação realizada com sucesso' },
  101: {
    msg: 'A transação enviada para análise de fraude está faltando um ou mais campos necessários',
    field: 'ProviderAnalysisResult.Missing',
    possibleAction: 'Reenviar a transação com a informação completa'
  },
  102: {
    msg: 'A transação enviada para análise de fraude possui um ou mais campos com valores inválidos',
    field: 'ProviderAnalysisResult.Invalid',
    possibleAction: 'Reenviar a transação com as informações corretas'
  },
  150: {
    msg: 'Erro interno',
    possibleAction: 'Aguarde alguns minutos e tente reenviar a transação'
  },
  151: {
    msg: 'A transação foi recebida, mas ocorreu time-out no servidor. Este erro não inclui time-out entre o cliente e o servidor',
    possibleAction: 'Aguarde alguns minutos e tente reenviar a transação'
  },
  152: {
    msg: 'O pedido foi recebido, mas ocorreu time-out',
    possibleAction: 'Aguarde alguns minutos e tente reenviar a transação'
  },
  202: {
    msg: 'Transação recusada pois o cartão expirou ou a data de validade não coincide com a correta',
    possibleAction: 'Solicite outro cartão ou outra forma de pagamento'
  },
  231: {
    msg: 'Transação recusada pois o cartão é inválido',
    possibleAction: 'Solicite outro cartão ou outra forma de pagamento'
  },
  234: {
    msg: 'Problema com a configuração da loja na Cybersource',
    possibleAction: 'Entre em contato com o suporte para corrigir o problema de configuração'
  },
  400: {
    msg: 'A pontuação de fraude ultrapassa o seu limite',
    possibleAction: 'Reveja a transação do comprador'
  },
  480: {
    msg: 'A transação foi marcada como revisão pelo DM (Decision Manager)'
  },
  481: { msg: 'A transação foi rejeitada pelo DM (Decision Manager)' }
}
