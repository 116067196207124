/** @jsxRuntime classic */
/** @jsx jsx */
import { identity } from '@bonitour/common-functions'
import { css, jsx } from '@emotion/core'
import { SidebarSection, LogoMenuItem, CompanyIcon, UsersIcon, LogoutIcon, MenuItem, CogIcon, CompanyCard, CashbackIcon, CashIcon, DocumentIcon } from '@bonitour/components'
import { NavLink, useLocation } from 'react-router-dom'
import BinamikLogo from 'Shared/svgs/logoBinamik.svg'
import PayLogo from 'Shared/svgs/logoBinamikPay.svg'
import { useCallback } from 'react'
import { useAuthorization } from 'Shared/contexts/Authorization'
import { useCompany } from 'Shared/contexts/Company'
const { REACT_APP_ACCOUNTS_URL } = process.env

const marginTop30 = css`
  margin-top: 30px;
`

const marginBottom10 = css`
  margin-bottom: 10px;
`

const clickable = css`
  cursor: pointer;
`

const redirectOnClick = (link) => () => {
  window.location = link
}

const noDecorationLine = css`
 text-decoration: none;
`

export const Menu = ({
  onNavigation = identity
}) => {
  const { logout } = useAuthorization()
  const { pathname } = useLocation()
  const isLinkActive = useCallback((to) => (pathname.indexOf(to) !== -1), [pathname])
  const { company } = useCompany()

  return (
    <div>
      <SidebarSection>
        <NavLink css={[noDecorationLine]} to='/'>
          <LogoMenuItem onClick={redirectOnClick('/')} logoSrc={PayLogo} css={marginBottom10} />
        </NavLink>

        <CompanyCard name={company.name} image={company.image} />

        <NavLink css={noDecorationLine} to='/transaction'>
          <MenuItem onClick={onNavigation} icon={CashIcon} label='Transações' isActive={isLinkActive('/transaction')} />
        </NavLink>

        <NavLink css={noDecorationLine} to='/refund'>
          <MenuItem onClick={onNavigation} icon={CashbackIcon} label='Estornos' isActive={isLinkActive('/refund')} />
        </NavLink>

        <NavLink css={noDecorationLine} to='/configuration'>
          <MenuItem onClick={onNavigation} icon={DocumentIcon} label='Cadastro' isActive={isLinkActive('/configuration')} />
        </NavLink>

        <NavLink css={noDecorationLine} to='/company-config'>
          <MenuItem onClick={onNavigation} icon={CogIcon} label='Configurações' isActive={isLinkActive('/company-config')} />
        </NavLink>

        <LogoMenuItem onClick={redirectOnClick(`${REACT_APP_ACCOUNTS_URL}/app`)} logoSrc={BinamikLogo} css={[marginTop30, clickable]} />

        <MenuItem onClick={redirectOnClick(`${REACT_APP_ACCOUNTS_URL}/app/company/edit`)} icon={CompanyIcon} label='Empresa' isActive={isLinkActive('/company/edit')} />

        <MenuItem onClick={redirectOnClick(`${REACT_APP_ACCOUNTS_URL}/app/users`)} icon={UsersIcon} label='Usuários' isActive={isLinkActive('/users')} />

        <MenuItem onClick={logout} icon={LogoutIcon} label='Sair' />

      </SidebarSection>
    </div>
  )
}
