import { useToast } from '@bonitour/components'
import { SubordinateService } from 'Core/Service/Subordinate'
import { useCallback, useEffect, useState } from 'react'
import { useCompany } from 'Shared/contexts/Company'

export const useSubordinateConfiguration = () => {
  const { id: companyId } = useCompany()
  const { add: addToast } = useToast()
  const [isBlocked, setBlockState] = useState(false)
  const [isDisabledButton, setDisabledButton] = useState(false)
  const [formPreset, setFormPreset] = useState({})

  const getSubordinateInformation = useCallback(() => {
    SubordinateService.getSubordinate({ partnerCompanyId: companyId }).then(data => {
      setDisabledButton(false)
      setBlockState(true)
      setFormPreset(data)
    })
  }, [companyId])

  const signupSubordinate = useCallback((form) => {
    setDisabledButton(true)
    SubordinateService.createSubordinate(form)
      .then(() => {
        getSubordinateInformation()
        addToast('Configuração realizada', 'success')
      })
      .catch(() => {
        addToast('Erro ao gerar configuração')
        setDisabledButton(false)
      })
  }, [getSubordinateInformation, addToast])

  useEffect(() => {
    getSubordinateInformation()
  }, [getSubordinateInformation])

  return {
    isBlocked,
    isDisabledButton,
    formPreset,
    signupSubordinate
  }
}
