import { useEffect, useState } from 'react'
import { companyConfigService } from 'Core/Service/CompanyConfig'
import { useToast } from '@bonitour/components'

export const useEditCompanyConfig = (companyId) => {
  const [loading, setLoading] = useState(false)
  const [companyConfig, setCompanyConfig] = useState()
  const { add: addToast } = useToast()

  useEffect(() => {
    setLoading(true)
    companyConfigService.getById(companyId).then(setCompanyConfig)
      .finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    id
  } = companyConfig ?? {}

  const editCompanyConfig = async (data) => {
    setLoading(true)
    return companyConfigService.edit({ ...data }, id)
      .then(() => {
        setLoading(false)
        addToast('Configuração salva!', 'success')
      })
      .catch(() => addToast('Erro ao salvar a configuração.', 'error'))
  }

  return {
    loading,
    companyConfig,
    onEditCompanyConfig: editCompanyConfig
  }
}
