import { payCore } from './Pay'
import { CompanyConfigModel } from 'Core/Model/CompanyConfig'
import { CompanyConfigParser } from 'Core/Parser/CompanyConfig'

const { companyConfigDomain } = payCore

export const companyConfigService = {
  create (companyConfig) {
    return companyConfigDomain.create(CompanyConfigModel.create(companyConfig))
  },
  getById (id) {
    return companyConfigDomain.getById(id).then(CompanyConfigParser.single)
  },
  edit (companyConfig, companyConfigId) {
    return companyConfigDomain.update(CompanyConfigModel.edit(companyConfig), companyConfigId)
  }
}
