// This object was assembled using the link
// https://braspag.github.io//manual/antifraude#tabela-27-provideranalysisresult.afsreply.internetinfocode
// Using the table twenty-six

export const identityInfoCode = {
  'MORPH-B': {
    msg: 'O mesmo endereço de cobrança tem sido utilizado várias vezes com identidades de múltiplos compradores'
  },
  'MORPH-C': {
    msg: 'O mesmo cartão de crédito tem sido utilizado várias vezes com identidades de múltiplos compradores'
  },
  'MORPH-E': {
    msg: 'O mesmo endereço de e-mail tem sido utilizado várias vezes com identidades de múltiplos compradores'
  },
  'MORPH-I': {
    msg: 'O mesmo endereço IP tem sido utilizado várias vezes com identidades de múltiplos compradores'
  },
  'MORPH-P': {
    msg: 'O mesmo número de telefone tem sido usado várias vezes com identidades de múltiplos compradores'
  },
  'MORPH-S': {
    msg: 'O mesmo endereço de entrega tem sido utilizado várias vezes com identidades de múltiplos compradores'
  }
}
