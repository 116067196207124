import { DigitalAccountsService } from 'Core/Service/DigitalAccounts'
import { useBankAccountBalance } from 'Domains/DigitalAccount/Hooks/useGetBankAccountBalance'

export const useTransferPix = (setTransferStatusModalData) => {
  const { update } = useBankAccountBalance()

  const createTransfer = transferData => {
    DigitalAccountsService.pixWithdrawal(transferData?.digitalAccountId, transferData)
      .then(() => {
        setTransferStatusModalData({ status: 'success' })
      })
      .catch(({ response: { data } }) => {
        setTransferStatusModalData({ ...data, status: 'error' })
      })
      .finally(() => update())
  }

  return {
    createTransfer
  }
}
