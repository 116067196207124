import { unformatMoney } from '@bonitour/common-functions'

export const DigitalAccountModel = {
  create (payload) {
    const {
      type,
      returnUrl,
      refreshUrl,
      referenceId
    } = payload ?? {}

    return {
      type,
      returnUrl,
      refreshUrl,
      referenceId
    }
  },
  search ({ filters }) {
    const { originId } = filters || {}

    return {
      origin_id: originId
    }
  },
  pixWithdrawal (payload) {
    const { value } = payload ?? {}

    return {
      amount: unformatMoney(value)
    }
  }
}
