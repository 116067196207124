import { metaModel } from './Meta'
import moment from 'moment'

export const RefundModel = {
  list: ({ filters = {}, pagination = {} }) => {
    const {
      refundInitialDate,
      refundEndDate,
      paymentInitialDate,
      paymentEndDate,
      aut,
      reservation
    } = filters

    const meta = metaModel(pagination)

    const searchParams = {
      refund_initial_date: refundInitialDate ? moment(refundInitialDate).format('YYYY-MM-DD') : undefined,
      refund_end_date: refundEndDate ? moment(refundEndDate).format('YYYY-MM-DD') : undefined,
      payment_initial_date: paymentInitialDate ? moment(paymentInitialDate).format('YYYY-MM-DD') : undefined,
      payment_end_date: paymentEndDate ? moment(paymentEndDate).format('YYYY-MM-DD') : undefined,
      aut: aut || undefined,
      reservation_code: reservation || undefined
    }

    return { searchParams, pagination: meta }
  }
}
